import React from 'react';
import styled from "styled-components";
import {Container, Row, Col} from "react-bootstrap";

import {Img} from "../Img";
import reactHtmlParser from "react-html-parser";


const ChairPersonComponent = (data) => {
    const chairPersons = data?.data?.posts?.list;

    return (
        <StyledComponent className={'pt-200 pb-200'}>


            {chairPersons && chairPersons.length > 0 &&
                chairPersons?.map(item => (

                    <Container className="profile">
                        <Row className="profile-row">
                            <Col md={5} className={'profile-left'}>
                                <div className="profile__image ">
                                    <Img src={item?.images?.[0]?.full_path}/>
                                </div>
                            </Col>
                            <Col md={{span: 6, offset: 1}} className="profile__body">
                                <div className={'profile__body__inner'}>
                                    <div className="profile__body__name">
                                        <h5 className={'split-up'}>{item?.data?.title}</h5>
                                        <p className={'split-up'}>{item?.data?.subtitle}</p>
                                    </div>
                                    <p>{reactHtmlParser(item?.data?.description)}</p>
                                </div>
                            </Col>
                        </Row>

                    </Container>

                ))}



            {/*<Container className="profile">*/}
            {/*    <Row  className="profile-row">*/}
            {/*        <Col md={'5'} className={'profile-left'}>*/}
            {/*            <div className="profile__image">*/}
            {/*                <Img src={'images/dynamic/chair2.jpg'}/>*/}
            {/*            </div>*/}
            {/*        </Col>*/}
            {/*        <Col md={'7'} className="profile__body">*/}
            {/*            <div className={'profile__body__inner'}>*/}
            {/*                <div className="profile__body__name">*/}
            {/*                    <h3 className={'split-up'}>{'Jon Doe'}</h3>*/}
            {/*                    <h5 className={'split-up'}>{'Chairperson '}</h5>*/}
            {/*                </div>*/}
            {/*                <p className={'split-up'}>{ReactHtmlParser('Dear stakeholders, <br><br> I am honored to address you as the Chairman of Lanco Group. Our success is a testament to your trust and support. At Lanco, we remain steadfast in our commitment to excellence, sustainability, and social responsibility. Through innovation and collaboration, we strive to make a positive impact on society and improve lives. We embrace the challenges of a rapidly changing world and seize opportunities to shape industries and drive growth. Together, let us build a better future, where integrity, inclusivity, and environmental stewardship are at the core of our actions. Thank you for being a part of our journey.')}</p>*/}
            {/*            </div>*/}
            {/*        </Col>*/}
            {/*    </Row>*/}

            {/*</Container>*/}

        </StyledComponent>
    );
};

const StyledComponent = styled.section`
  background-color: #F7F3F0;
  padding-top: 200px;
  padding-bottom: 200px;

  .profile {
    padding-top: 200px;

    &:nth-child(1) {
      padding-top: 0;
    }

    &:nth-child(even) {
      .profile-row {
        flex-direction: row-reverse;
        justify-content: space-between;
      }
    }

    &__image {
      position: relative;
      padding-top: calc(460 / 470 * 100%);
    }
  
    &__body {
      
      &__name {
        h5 {
          margin: 0 0 10px 0;
        }
        p {
          color: rgba(28, 55, 0, 0.5);
          margin-bottom: 70px;
        }
      }
    }
  }
.profile:nth-child(even) {
  .offset-md-1 {
    margin: 0;
  }
}

  /* Tablet desktop :768px. */
  @media (min-width: 768px) and (max-width: 991px) {
    padding-top: 120px!important;
    padding-bottom: 120px!important;  
    .profile-left {
      padding: 0;
    }

    .profile {
      padding-top: 80px;

      &:nth-child(1) {
        padding-top: 0;
      }

      &__body {
        &__name {

          h3 {
            margin: 0 0 10px 0;
          }

          h5 {
            margin: 0 0 10px 0;
          }
          p {
            margin-bottom: 40px;
          }
        }
      }
    }
  }

  /* small mobile :320px. */
  @media (max-width: 767px) {
    padding-top: 120px!important;
    padding-bottom: 120px!important;
    .profile__body__name {
      padding-top: 20px;
    }
    .profile__body__name p {
      margin-bottom: 40px;
    }
    .profile {
      padding-top: 80px;
    }
  }

`;

export default ChairPersonComponent;
