import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Container, Row, Col, Form } from "react-bootstrap";
import Select, { components } from "react-select";
import Button from "./Button";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { apiEndPoints } from "../api/network/apiEndPoints";
import { postForm } from "../api/redux/contact";

const DropdownIndicator = (props) => (
  <components.DropdownIndicator {...props}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="9.414"
      height="5.414"
      viewBox="0 0 9.414 5.414"
    >
      <g
        id="Group_22309"
        data-name="Group 22309"
        transform="translate(4702.207 -1191.793) rotate(90)"
      >
        <line
          id="Line_12377"
          data-name="Line 12377"
          x2="4"
          y2="4"
          transform="translate(1192.5 4693.5)"
          fill="none"
          stroke="#f7f3f0"
          stroke-linecap="round"
          stroke-width="1"
        />
        <line
          id="Line_12378"
          data-name="Line 12378"
          y1="4"
          x2="4"
          transform="translate(1192.5 4697.5)"
          fill="none"
          stroke="#f7f3f0"
          stroke-linecap="round"
          stroke-width="1"
        />
      </g>
    </svg>
  </components.DropdownIndicator>
);

const GetInTouch = () => {
  const FORM_STATE = {
    reasonObj: "",
  };

  const [formDataObj, setFormDataObj] = useState({ ...FORM_STATE });
  const { reasonObj } = formDataObj;

  const dispatch = useDispatch();
  const responseData = useSelector((state) => state.contact);

  const { register, handleSubmit, formState, reset } = useForm({ mode: "all" });

  const success = (msg) =>
    toast.success(msg, {
      position: "top-right",
      autoClose: 4000,
      closeOnClick: true,
      progress: undefined,
    });

  const error = (msg) =>
    toast.error(msg, {
      position: "top-right",
      autoClose: 4000,
      closeOnClick: true,
      progress: undefined,
    });

  const handleBlur = () => {
    // Perform onBlur logic here
  };

  const handleChange = () => {
    // Perform onChange logic here
  };

  const onSubmit = (e) => {
    e.preventDefault(); // Prevent the default form submission behavior

    let api_services = apiEndPoints.SUBMITFORM;

    var formData = new FormData();
    formData.append("name", e?.name);
    formData.append("email", e?.email);
    formData.append("phone", e?.phone);
    // formData.append('reason', e?.reason?.value);
    formData.append("reason", reasonObj ? reasonObj.label : "");
    formData.append("form_id", "contact-form");

    if (e.email !== "" && e.name !== "" && e.phone !== "" && reasonObj?.label) {
      dispatch(postForm([api_services, formData]));
      // success('Successfully Submitted')
      setFormDataObj({ ...FORM_STATE });
      reset();
    }
  };
  // let count = 0;

  const onError = (errors) => {
    // Initialize error flags
    let hasNameError = false;
    let hasPhoneError = false;
    let hasEmailError = false;
    let hasReasonError = false;

    // Check each field for errors and set the error flags
    if (errors.name) {
      hasNameError = true;
      toast.error(errors.name.message);
    }
    if (errors.phone) {
      hasPhoneError = true;
      toast.error(errors.phone.message);
    }
    if (errors.email) {
      hasEmailError = true;
      toast.error(errors.email.message);
    }
    if (errors.reason) {
      hasReasonError = true;
      toast.error(errors.reason.message);
    }
  };

  useEffect(() => {
    if (responseData && responseData?.error !== "") {
      error(responseData?.error);
    }
    if (responseData && responseData?.success !== "") {
      success(responseData?.success);
    }
  }, [responseData]);

  return (
    <GetInTouchStyle>
      <Container>
        <div className="get-in-touch">
          <Row>
            <Col lg={12}>
              <div className="get-in-touch__title">
                <h3>Get in touch!</h3>
              </div>
            </Col>
          </Row>
          <Form onSubmit={handleSubmit(onSubmit, onError)}>
            <Row>
              <Col lg={3}>
                <Form.Control
                  className={
                    formState?.errors?.name?.message
                      ? "has-error form-control-lg"
                      : "form-control-lg"
                  }
                  {...register("name", {
                    required: "Enter your name",
                    pattern: {
                      value: /^[A-Za-zÀ-ÖØ-öø-ÿ\s'-]{2,}$/,
                      message: "Name must contain only letters",
                    },
                  })}
                  type="text"
                  placeholder="Name*"
                  autoComplete="off"
                />
              </Col>
              <Col lg={3}>
                <Form.Control
                  id="myInput"
                  className={
                    formState?.errors?.phone?.message
                      ? "has-error form-control-lg"
                      : "form-control-lg"
                  }
                  {...register("phone", {
                    required: {
                      value: true,
                      message: "Enter your valid phone number",
                    },
                    pattern: {
                      value: /^01[0-9]{9}$/,
                      message: "Enter a valid 11 digit phone number",
                    },
                  })}
                  type="number"
                  placeholder="Phone Number*"
                  autoComplete="off"
                />
              </Col>
              <Col lg={3}>
                <Form.Control
                  id="myInput"
                  className={
                    formState?.errors?.email?.message
                      ? "has-error form-control-lg"
                      : "form-control-lg"
                  }
                  {...register("email", {
                    required: {
                      value: true,
                      message: "Enter your valid email",
                    },
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                      message: "Enter a valid email address",
                    },
                  })}
                  type="email"
                  placeholder="Email*"
                  autoComplete="off"
                />
              </Col>
              <Col className={"d-flex flex-column-reverse "} lg={3}>
                <Select
                  {...register("reason", {
                    required: {
                      value: false,
                      message: "Select your reason",
                    },
                    pattern: {
                      message: "Select a valid reason",
                    },
                  })}
                  options={[
                    { label: "General Inquiry", value: "General Inquiry" },
                    { label: "Customer Support", value: "Customer Support" },
                    {
                      label: "Career Opportunities",
                      value: "Career Opportunities",
                    },
                    {
                      label: "Product Information",
                      value: "Product Information",
                    },
                    { label: "Others", value: "Others" },
                  ]}
                  value={reasonObj}
                  isSearchable={false}
                  placeholder={"Reason to Contact"}
                  components={{ DropdownIndicator }}
                  onChange={(selectedOption) =>
                    setFormDataObj({
                      ...formDataObj,
                      reasonObj: selectedOption,
                    })
                  }
                />
              </Col>
            </Row>
            <Row>
              <Col className={"submit-btn"} lg={12}>
                <Button
                  onClick={handleSubmit(onSubmit, onError)}
                  width={"180px"}
                  text={"Submit"}
                  hoverBackground={"#FFFFFF"}
                  color={"#FFFFFF"}
                  background={"transparent"}
                  hoverColor={"#000000"}
                  stroke={"#000000"}
                  borderColor={"#FFFFFF"}
                />
              </Col>
            </Row>
          </Form>
        </div>
      </Container>
    </GetInTouchStyle>
  );
};

const GetInTouchStyle = styled.section`
  position: relative;

  &:before {
    position: absolute;
    content: "";
    left: 0;
    right: 0;
    bottom: 0;
    background: #161f13;
    height: 50%;
  }

  .css-hlgwow {
    padding: 2px 0px;
  }

  .get-in-touch {
    background: #000000;
    padding: 40px 70px;
    position: relative;

    &__title {
      margin-bottom: 40px;

      h3 {
        color: #f7f3f0;
      }
    }
  }

  .submit-btn {
    margin-top: 40px;
  }

  //style input field
  input.form-control {
    background: no-repeat;
    border-top: 0;
    border-left: 0;
    border-right: 0;
    padding: 0;
    transition: 0.3s;
    color: rgba(247, 243, 240);
    border-bottom: 1px solid rgba(247, 243, 240, 0.5) !important;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;

    :focus {
      border: transparent;
    }
  }

  input:internal-autofill-selected {
    background: transparent !important;
    color: white;
  }

  input.form-control::placeholder {
    color: rgba(247, 243, 240, 0.5);
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    opacity: 1;
  }

  input.form-control:focus {
    border-color: #f7f3f0;
  }

  input.form-control:focus::placeholder {
    color: rgba(247, 243, 240);
  }

  input.form-control:valid:focus {
    border-color: rgba(247, 243, 240); /* Solid white */
  }

  //  style select field
  .css-13cymwt-control {
    background: no-repeat;
    border-top: 0;
    border-left: 0;
    border-right: 0;
    border-radius: 0;
    border-color: rgba(247, 243, 240, 0.5);
  }

  .css-t3ipsp-control:hover {
    border-color: #fff;
  }

  span.css-1u9des2-indicatorSeparator {
    display: none;
  }

  .css-1dimb5e-singleValue {
    color: rgba(247, 243, 240) !important;
  }

  /* Style to remove the indicator separator in React Select */

  span.css-1u9des2-indicatorSeparator {
    display: none;
  }

  .css-1fdsijx-ValueContainer {
    padding: 0;
  }

  .css-1dimb5e-singleValue {
    color: rgba(247, 243, 240, 0.5);
  }

  .css-t3ipsp-control {
    background: none;
    border-top: 0;
    border-left: 0;
    border-right: 0;
    box-shadow: none;
    border-radius: 0px;
    border-color: #f7f3f0;
  }

  .css-1jqq78o-placeholder {
    color: rgba(247, 243, 240, 0.5);
  }

  div#react-select-3-placeholder {
    color: rgba(247, 243, 240, 0.5);
    -webkit-text-fill-color: rgba(247, 243, 240, 0.5) !important;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover,
  textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    border: none;
    -webkit-text-fill-color: white;
    box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px 1000px inset;
    transition: background-color 5000s ease-in-out 0s;
  }

  /* small mobile :320px. */
  @media (max-width: 767px) {
    background: #000000;
    padding-top: 40px;
    padding-bottom: 40px;

    input.form-control {
      margin-bottom: 25px;
    }

    &:before {
      background: #000000;
    }

    .get-in-touch {
      padding: unset;
      background: transparent;
    }
  }
`;

export default GetInTouch;
