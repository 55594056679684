import styled from "styled-components";
import {Col, Container, Row} from "react-bootstrap";
import React, {useEffect, useRef, useState} from "react";
import {Swiper, SwiperSlide} from "swiper/react";
import {Pagination, Navigation, EffectCreative, Autoplay} from "swiper";
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import {Link} from "react-router-dom";
import {Img} from "./Img";
import reactHtmlParser from "react-html-parser";

const PageTestimonalSlider = ({data, conlist, offset, title, bgDark}) => {

    const nextsRef = useRef()
    const prevsRef = useRef()


    const getTotalSlides = () => {
        const sliderContainer = document.querySelector('.amenities__big__slider');
        if (sliderContainer) {
            const sliderChildren = sliderContainer.querySelectorAll('.swiper-slide');
            return sliderChildren.length;
        }
        return 0;
    };

    const [current, setCurrent] = useState(0);


    const handleSliderCurrent = (swiper) => {
        // Get the real current slide index starting from 1
        const realCurrentSlide = swiper.realIndex + 1;
        setCurrent(realCurrentSlide.toString());
    };

    // test start

    const [swiper1, setSwiper1] = useState(null);
    const [swiper2, setSwiper2] = useState(null);
    const [swiper3, setSwiper3] = useState(null);

    // Keep track of the active slide index in the first slider
    const [activeSlide, setActiveSlide] = useState(0);

    // Function to go to the next slide for all sliders
    const goNext = () => {
        if (swiper1 !== null && swiper2 !== null && swiper3 !== null) {
            swiper1.slideNext();
        }
    };

    // Function to go to the previous slide for all sliders
    const goPrev = () => {
        if (swiper1 !== null && swiper2 !== null && swiper3 !== null) {
            swiper1.slidePrev();
        }
    };

    // Watch for changes in the active slide index of the first slider
    useEffect(() => {
        // Apply the active slide index to the second and third sliders
        if (swiper1 !== null && swiper2 !== null && swiper3 !== null) {
            swiper1.slideTo(activeSlide);
            swiper2.slideTo(activeSlide);
            swiper3.slideTo(activeSlide);
            setCurrent(swiper1.realIndex + 1)
        }
    }, [activeSlide, swiper1, swiper2, swiper3]);
    // test end


    return (
        <StyledBlog bgDark className='amenities as-image-text-slider pb-200 pt-200' offset={offset}>
            <Container>
                <Row>
                    <Col lg={5}>
                        {
                            data &&
                            <Swiper
                                onSwiper={setSwiper1}
                                spaceBetween={20}
                                slidesPerView={1}
                                navigation={{nextEl: '.swiper-button-next-1', prevEl: '.swiper-button-prev-1'}}
                                onSlideChange={(swiper) => {
                                    // Update the active slide index when the first slider changes
                                    setActiveSlide(swiper.activeIndex);
                                }}
                                noSwiping={true} // Disable dragging
                                noSwipingClass="swiper-no-swiping" // Define a class to indicate no swiping
                                speed={900}
                            >

                                {
                                    data?.map((item, index) => {
                                        return (
                                            <SwiperSlide key={index}>
                                                <div className="slider-text">
                                                    <h3>{reactHtmlParser(item?.product_data?.title)}</h3>
                                                </div>
                                            </SwiperSlide>
                                        )
                                    })
                                }


                            </Swiper>

                        }


                        <div className="mobile-flex">
                            <div className="counter">
                                <p>{current}<span>/{getTotalSlides()}</span></p>
                            </div>
                            <div className="slider-nav">
                                <ul>
                                    <li onClick={goPrev} className="hovercenter" ref={prevsRef}>
                                        <svg id="Component_29_7" data-name="Component 29 – 7"
                                             xmlns="http://www.w3.org/2000/svg" width="48" height="48"
                                             viewBox="0 0 48 48">
                                            <defs>
                                                <clipPath id="clip-path">
                                                    <rect id="Rectangle_5655" data-name="Rectangle 5655" width="48"
                                                          height="48" rx="24" fill="none" stroke="#f7f3f0"
                                                          stroke-width="1"/>
                                                </clipPath>
                                            </defs>
                                            <g id="Mask_Group_337" data-name="Mask Group 337"
                                               clip-path="url(#clip-path)">
                                                <g id="Rectangle_4990" data-name="Rectangle 4990" fill="none"
                                                   stroke="#f7f3f0" stroke-width="1">
                                                    <rect width="48" height="48" rx="24" stroke="none"/>
                                                    {/*<rect x="0.5" y="0.5" width="47" height="47" rx="23.5" fill="none"/>*/}
                                                </g>
                                                <rect id="Rectangle_5654" data-name="Rectangle 5654" width="48"
                                                      height="48" rx="24" transform="translate(-50)" fill="#f7f3f0"/>
                                                <g id="Group_19794" data-name="Group 19794" transform="translate(7)">
                                                    <g id="Group_15984" data-name="Group 15984"
                                                       transform="translate(11.5 19)">
                                                        <line id="Line_3865" data-name="Line 3865" x1="5" y2="5"
                                                              fill="none" stroke="#f7f3f0" stroke-linecap="round"
                                                              stroke-width="2"/>
                                                        <line id="Line_3866" data-name="Line 3866" x1="5" y1="5"
                                                              transform="translate(0 5)" fill="none" stroke="#f7f3f0"
                                                              stroke-linecap="round" stroke-width="2"/>
                                                    </g>
                                                    <line id="Line_3889" data-name="Line 3889" x2="10"
                                                          transform="translate(12.5 24)" fill="none" stroke="#f7f3f0"
                                                          stroke-linecap="round" stroke-width="2"/>
                                                </g>
                                            </g>
                                        </svg>
                                    </li>
                                    <li onClick={goNext} className="hovercenter" ref={nextsRef}>
                                        <svg id="Group_19795" data-name="Group 19795" xmlns="http://www.w3.org/2000/svg"
                                             width="48" height="48" viewBox="0 0 48 48">
                                            <g id="Rectangle_4990" data-name="Rectangle 4990" fill="none"
                                               stroke="#f7f3f0" stroke-width="1">
                                                <rect width="48" height="48" rx="24" stroke="none"/>
                                                {/*<rect x="0.5" y="0.5" width="47" height="47" rx="23.5" fill="none"/>*/}
                                            </g>
                                            <g id="Group_19794" data-name="Group 19794" transform="translate(18.5 19)">
                                                <g id="Group_15984" data-name="Group 15984" transform="translate(6)">
                                                    <line id="Line_3865" data-name="Line 3865" x2="5" y2="5" fill="none"
                                                          stroke="#f7f3f0" stroke-linecap="round" stroke-width="2"/>
                                                    <line id="Line_3866" data-name="Line 3866" y1="5" x2="5"
                                                          transform="translate(0 5)" fill="none" stroke="#f7f3f0"
                                                          stroke-linecap="round" stroke-width="2"/>
                                                </g>
                                                <line id="Line_3889" data-name="Line 3889" x1="10"
                                                      transform="translate(0 5)" fill="none" stroke="#f7f3f0"
                                                      stroke-linecap="round" stroke-width="2"/>
                                            </g>
                                        </svg>


                                    </li>
                                </ul>
                            </div>
                        </div>


                    </Col>
                    <Col className={'amenities__big'} lg={7}>
                        <div className="amenities__big__slider">
                            <Swiper
                                onSwiper={setSwiper2}
                                spaceBetween={20}
                                slidesPerView={1}
                                navigation={{nextEl: '.swiper-button-next-2', prevEl: '.swiper-button-prev-2'}}
                                onSlideChange={(swiper) => {
                                    // Update the active slide index when the first slider changes
                                    setActiveSlide(swiper.activeIndex);
                                }}
                                noSwiping={true} // Disable dragging
                                noSwipingClass="swiper-no-swiping" // Define a class to indicate no swiping
                                speed={900}
                            >

                                {
                                    data && data?.length > 0 && data?.map((item, index) => {
                                        return (
                                            <SwiperSlide key={index}>
                                                <div className="single">
                                                    <div className="single__img">
                                                        <Link to={`concern/${item?.product_data?.slug}`}>
                                                            <Img src={item?.images?.list?.[0]?.full_path}/>
                                                        </Link>
                                                    </div>
                                                </div>
                                            </SwiperSlide>
                                        )
                                    })
                                }
                            </Swiper>
                        </div>

                    </Col>
                    <Col className={'amenities__right'} lg={{offset: 3, span: 4}}>
                        {
                            data &&
                            <Swiper
                                onSwiper={setSwiper3}
                                spaceBetween={20}
                                slidesPerView={1}
                                navigation={{nextEl: '.swiper-button-next-3', prevEl: '.swiper-button-prev-3'}}
                                onSlideChange={(swiper) => {
                                    // Update the active slide index when the first slider changes
                                    setActiveSlide(swiper.activeIndex);
                                }}
                                noSwiping={true} // Disable dragging
                                noSwipingClass="swiper-no-swiping" // Define a class to indicate no swiping
                                speed={900}

                            >
                                {
                                    data?.map((item, index) => {

                                        return (
                                            <SwiperSlide key={index}>
                                                <p>{reactHtmlParser(item?.product_data?.description)}</p>
                                            </SwiperSlide>
                                        )
                                    })
                                }
                            </Swiper>

                        }
                    </Col>
                </Row>
            </Container>
        </StyledBlog>
    );
}
    ;

    const StyledBlog = styled.section`
      position: relative;
      overflow: hidden;
      background: ${props => props.bgDark ? '#000000' : ''};

      &:before {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        height: 70%;
        background: #000000;
        z-index: -1;
        content: "";
      }

      .swiper-button-disabled {
        opacity: 1 !important;
      }

      .amenities__big__slider .swiper-slide.swiper-slide-active img {
        transition: transform 0.7s cubic-bezier(0.4, 0, 0, 1);
      }


      .amenities__big__slider .swiper-slide.swiper-slide-active:hover img {
        transform: scale(1.1);
        transition: transform 0.7s cubic-bezier(0.4, 0, 0, 1);
      }

      .container {
        position: relative;

        .col-lg-4 {
          padding-left: 0;
        }

        .slider-text {
          h3 {
            margin-bottom: 70px;
            color: #F7F3F0;
          }


        }

        .counter {
          position: absolute;
          top: 140px;

          p {
            font-size: 24px;
            line-height: 36px;
            color: #F7F3F0;
            font-weight: 400;
          }
        }

        .slider-nav {
          position: absolute;
          bottom: 0px;
          left: 15px;
          z-index: 3;

          .hovercenter {

            &:after {
              background-color: white;
            }
          }


          ul {
            display: flex;

            li {
              height: 48px;
              width: 48px;
              //background-color: rgb(34, 31, 31);
              display: flex;
              -webkit-box-align: center;
              align-items: center;
              -webkit-box-pack: center;
              justify-content: center;
              border-radius: 50%;
              cursor: pointer;
              border: 1px solid #FFFFFF;
              z-index: 9999999;

              &:nth-of-type(1) {
                margin-right: 15px;
              }

              svg {
                z-index: 2;
              }

              &:hover {
                svg {
                  g {
                    line {
                      stroke: #16322D;
                    }
                  }
                }
              }

            }

          }


        }
      }

      .amenities__big {
        position: relative;

        &__slider {
          position: relative;
          margin-right: -${props => props.offset || '150'}px !important;

          .shadow {
            position: absolute;
            bottom: -50px;
            right: calc(100% - 43px);
            z-index: 2;
            width: 120px;

            img {
              width: 100%;
              object-fit: cover;
            }
          }

          .shadow-right {
            position: absolute;
            bottom: -52px;
            right: -49px;
          }

          .single {
            overflow: hidden;

            &__img {
              padding-top: calc(600 / 570 * 100%);
            }
          }


        }

        .swiper-slide-next {
          height: 50%;
          width: 30%;
        }

        .swiper-slide-active {
          overflow: hidden;


          .single {


            &__img {
              transition: all 0.4s ease;
              overflow: hidden;
            }
          }

          height: auto;


          width: calc(100% - ${props => props.offset || '100'}px) !important;

          .single__image {
            width: 100%;
            height: 100%;

          }
        }

        .swiper {
          height: 600px;
        }

        .swiper-slide {
          transition: all 0.7s ease-in-out;
          overflow: hidden;
        }


      }

      .amenities__right {
        position: absolute;
        top: 65%;
        p {
          color: #FFFFFF;
          overflow: hidden;
          display: -webkit-box;
          -webkit-line-clamp: 6;
          -webkit-box-orient: vertical;
          white-space: normal;
        }

      }

      .swiper-button-prev,
      .swiper-button-next {
        display: none !important;
      }



      @media (max-width: 1500px) {
        .slider-text {
          h3 {
            font-size: 50px;
          }
        }
      }

      @media (max-width: 1200px) {
        .slider-text {
          h3 {
            font-size: 45px;
            margin-bottom: 50px !important;
          }
        }
      }
      
      /* Tablet desktop :768px. */
      @media (min-width: 768px) and (max-width: 991px) {
        .container .counter {
          top: unset;
        }

        .amenities__big {
          &__slider {
            .single {
              &__img {
                padding-top: calc(345 / 345 * 100%);
              }
            }
          }
        }
      }

      @media (max-width: 991px) {
        .swiper-slide-next {
          height: 100% !important;
          width: 100% !important;
        }

        padding-bottom: 100px;

        &:before {
          height: 40%;
        }

        .container {
          padding-left: 15px !important;

          .col-lg-4 {
            padding-left: 15px;
          }
        }

        .single__img {
          padding-top: calc(80%) !important;
        }

        .mobile-flex {
          display: flex;
          justify-content: space-between;
          flex-direction: row-reverse;
          margin-bottom: 40px;
        }


        .amenities__right {
          position: unset;
          flex: 0 0 100%;
          max-width: 100%;
          margin-top: 40px;
        }

        .swiper-slide-next {
          height: 100% !important;
          width: 100% !important;
        }

        .amenities__big__slider {
          margin-right: 0 !important;

          .swiper {
            height: 345px;
            margin-top: 40px;
          }

          .swiper-slide-active {
            height: unset !important;
            width: 100% !important;


            .single__image {
              width: 100%;
              height: 100%;

            }
          }
        }


      }

      /* small mobile :320px. */
      @media (max-width: 767px) {
        padding-top: 120px;
        padding-bottom: 120px !important;
        .container .slider-nav {
          bottom: -8px;
        }

        .amenities__big {
          &__slider {
            .single {
              overflow: hidden;

              &__img {
                padding-top: calc(345 / 345 * 100%) !important;
              }
            }
          }
        }

        .slider-text h3 {
          font-size: 36px;
          font-weight: 400;
          line-height: 40px;
          margin-bottom: 40px !important;
        }

        .container .counter {
          top: unset;
        }
      }


      @media (max-width: 514px) {
        &:before {
          height: 55%;
        }
      }

    `;
    export default PageTestimonalSlider;
