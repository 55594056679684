import React from 'react';
import styled from "styled-components";
import {Container, Row, Col} from "react-bootstrap";

import Button from "../Button"
import reactHtmlParser from "react-html-parser";
import {Img} from "../Img";

const Overview = ({data}) => {

    const missionVision = data?.posts?.list;

    return (
        <StyledComponent className='pt-120 pb-200'>
            <div className="img-wrap">
                <Img src={'images/static/Bangladesh_map.svg'}/>
            </div>
            <Container>
                <Row>
                    {
                        data?.section_data?.subtitle &&  <Col lg={4}>
                            <h3 className={'top-title'}>{reactHtmlParser(data?.section_data?.subtitle)}</h3>
                        </Col>
                    }
                    {
                        data?.section_data?.description &&    <Col lg={{span: 7, offset: 1}}>
                            <p>{reactHtmlParser(data?.section_data?.description)}</p>
                        </Col>
                    }



                    <Col lg={{span: 7, offset: 5}}>
                        {missionVision && missionVision.length > 0 &&
                            <Row>
                                {missionVision.length > 0 && missionVision?.map(item => (
                                    <Col lg={6} className={'single-item'} key={item?.data?.id}>
                                        <h5 className="split-up">{item?.data?.title}</h5>
                                        <p>{reactHtmlParser(item?.data?.description)}</p>
                                    </Col>

                                ))}
                            </Row>
                        }
                    </Col>
                </Row>
            </Container>
        </StyledComponent>
    );
};

const StyledComponent = styled.section`
  background: #F7F3F0;
  position: relative;

  .img-wrap {
    img {
      width: unset;
      height: 60%;
      object-fit: cover;
      position: absolute;
      inset: unset;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }

  p {
    margin: 0 0 60px 0;
  }

  .single-item {
    h5 {
      //border-bottom: 1px solid rgba(22, 31, 19, 0.2);
      margin: 0 0 20px 0;
      font-size: 28px;
    }

    p {
      margin: 0;
    }

    &:nth-child(1) {
      padding-right: 35px;
    }

    &:nth-child(2) {
      padding-left: 35px;
    }
  }


  /* Tablet desktop :768px. */
  @media (min-width: 768px) and (max-width: 991px) {
    padding-top: 120px !important;
    padding-bottom: 120px !important;
    p {
      margin: 0px 0px 100px
    }

    img {
      width: calc(100% - 30px);
      height: unset;
      object-fit: cover;
      position: absolute;
      right: unset;
      bottom: unset;
      left: 15px;
      top: 50%;
      transform: translate(0, 0);
    }


    .single-item {
      padding-left: 15px !important;
      padding-right: 15px !important;

      &:nth-child(1) {
        padding-bottom: 40px;
      }
    }
  }


  /* small mobile :320px. */
  @media (max-width: 767px) {
    padding-top: 80px !important;
    padding-bottom: 80px !important;
    .img-wrap img {
      top: 80%;
      height: 30%;
    }
    p {
      margin: 0px 0px 100px
    }

    .single-item:nth-child(2) {
      padding-top: 40px;
      padding-left: 15px;
    }
  }
`;

export default Overview;
