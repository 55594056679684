import React, {useEffect, useRef, useState} from 'react';
import styled from "styled-components";
import {Container, Row, Col} from "react-bootstrap";
import {Navigation, Pagination, Scrollbar, A11y, Autoplay, Mousewheel} from 'swiper';
import Img from "../Img";
import {Swiper, SwiperSlide} from 'swiper/react';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import {hover} from "../../styles/globalStyleVars";
import {LightgalleryItem, LightgalleryProvider} from "react-lightgallery";
import "lightgallery.js/dist/css/lightgallery.css";
import Button from "../Button";
import {Link} from "react-router-dom";
import reactHtmlParser from "react-html-parser";
// import ProductPopUp from "./ProductPopUp";


const GalleryHome = ({data}) => {

    const next = () => {
        document.querySelector('.mySwiper .swiper-button-next').click();
        // console.log("Clicked");
    }

    const prev = () => {
        document.querySelector('.mySwiper .swiper-button-prev').click();
    }
    const [offset, setOffset] = useState(0)
    useEffect(() => {
        setOffset(document.querySelector(' .container').offsetLeft)
    }, [])


    const [show, setShow] = useState(false);
    const [winWidth, setWinWidth] = useState(true)
    const [popupId, setPopupId] = useState()
    const handleClose = () => setShow(false);
    const handleShow = (e) => {
        setShow(true)
        setPopupId(e)
    }

    useEffect(() => {
        if (window.innerWidth > 767) {
            setWinWidth(true)
        } else {
            setWinWidth(false)
        }
        window.addEventListener("resize", () => {
            if (window.innerWidth > 767) {
                setWinWidth(true)
            } else {
                setWinWidth(false)
            }
        });
    }, [])

    return (
        <>
            <StyledGallery offset={offset} className="gallery-slider pb-200">
                <Container fluid >
                    <Row className={'gallery-slider__tablet'}>
                        <Col sm={'4'}>
                            <div className={'gallery-slider-left'}>
                                <Col sm={12} className="gallery-slider__text">

                                    <div className="gallery-slider__text__subtitle">
                                        {data?.data?.title && <h3 className={'top-title split-up'}>{data?.data?.title}</h3>}
                                        {data?.data?.description && <p className={'split-up'}>{data?.data?.description}</p>}
                                    </div>
                                </Col>
                                <Col sm={12} className={'gallery-slider-arrow'}>
                                    <div className="gallery-slider__text__title-arrow">
                                        <ul className="gallery-slider__text__title-arrow__arrow">
                                            <li onClick={prev} className="left hovercenter ">
                                                <svg id="Component_29_16" data-name="Component 29 – 16" xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48">
                                                    <g id="Mask_Group_337" data-name="Mask Group 337">
                                                        <g id="Rectangle_4990" data-name="Rectangle 4990" fill="none" stroke="#000000" stroke-width="1">
                                                            <rect width="48" height="48" rx="24" stroke="none"/>
                                                            <rect x="0.5" y="0.5" width="47" height="47" rx="23.5" fill="none"/>
                                                        </g>
                                                        <rect id="Rectangle_5654" data-name="Rectangle 5654" width="48" height="48" rx="24" transform="translate(-50)" fill="#000000"/>
                                                        <g id="Group_19794" data-name="Group 19794" transform="translate(7)">
                                                            <g id="Group_15984" data-name="Group 15984" transform="translate(11.5 19)">
                                                                <line id="Line_3865" data-name="Line 3865" x1="5" y2="5" fill="none" stroke="#000000" stroke-linecap="round" stroke-width="2"/>
                                                                <line id="Line_3866" data-name="Line 3866" x1="5" y1="5" transform="translate(0 5)" fill="none" stroke="#000000" stroke-linecap="round" stroke-width="2"/>
                                                            </g>
                                                            <line id="Line_3889" data-name="Line 3889" x2="10" transform="translate(12.5 24)" fill="none" stroke="#000000" stroke-linecap="round" stroke-width="2"/>
                                                        </g>
                                                    </g>
                                                </svg>

                                            </li>
                                            <li onClick={next} className="right hovercenter">
                                                <svg id="Component_29_16" data-name="Component 29 – 16" xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48">
                                                    <g id="Mask_Group_337" data-name="Mask Group 337">
                                                        <g id="Rectangle_4990" data-name="Rectangle 4990" fill="none" stroke="#000000" stroke-width="1">
                                                            <rect width="48" height="48" rx="24" stroke="none"/>
                                                            <rect x="0.5" y="0.5" width="47" height="47" rx="23.5" fill="none"/>
                                                        </g>
                                                        <rect id="Rectangle_5654" data-name="Rectangle 5654" width="48" height="48" rx="24" transform="translate(-50)" fill="#000000"/>
                                                        <g id="Group_19794" data-name="Group 19794" transform="translate(41 48) rotate(180)">
                                                            <g id="Group_15984" data-name="Group 15984" transform="translate(11.5 19)">
                                                                <line id="Line_3865" data-name="Line 3865" x1="5" y2="5" fill="none" stroke="#000000" stroke-linecap="round" stroke-width="2"/>
                                                                <line id="Line_3866" data-name="Line 3866" x1="5" y1="5" transform="translate(0 5)" fill="none" stroke="#000000" stroke-linecap="round" stroke-width="2"/>
                                                            </g>
                                                            <line id="Line_3889" data-name="Line 3889" x2="10" transform="translate(12.5 24)" fill="none" stroke="#000000" stroke-linecap="round" stroke-width="2"/>
                                                        </g>
                                                    </g>
                                                </svg>

                                            </li>
                                        </ul>
                                    </div>
                                </Col>
                            </div>
                        </Col>
                        <Col sm={{span: 7, offset: 1}}>
                            <LightgalleryProvider>

                                <Swiper
                                    // loop={true}
                                    slidesPerView={3}
                                    spaceBetween={15}
                                    speed='1000'
                                    slidesPerGroup={2}
                                    keyboardControl={true}
                                    initialSlide={0}
                                    modules={[Pagination, Navigation, Mousewheel]}
                                    navigation
                                    breakpoints={{
                                        200: {
                                            slidesPerView: 1,
                                            spaceBetween: 0,

                                        },
                                        778: {
                                            slidesPerView: 2,
                                            spaceBetween: 20,

                                        },
                                        1025: {
                                            slidesPerView: 3,
                                            spaceBetween: 20,
                                        },
                                    }}
                                    className="mySwiper"
                                >
                                    {data?.images && data?.images?.length > 0 &&
                                        data?.images?.map((item, index) => (
                                            <SwiperSlide key={index} onClick={() => handleShow(0)}>
                                                <div className="blog-single">
                                                    <div className="blog-single__inner reveal">
                                                        <img src={item?.full_path ? item?.full_path : '/images/dynamic/slide1.jpg'}/>
                                                    </div>
                                                    <p>{reactHtmlParser(item?.short_title)}</p>
                                                    <h5>{reactHtmlParser(item?.short_desc)}</h5>
                                                </div>
                                            </SwiperSlide>

                                        ))
                                    }

                                </Swiper>

                            </LightgalleryProvider>
                        </Col>
                    </Row>
                </Container>
            </StyledGallery>
            {/*<ProductPopUp show={show} handleClose={handleClose}/>*/}
        </>
    );
};

const StyledGallery = styled.section`
    overflow: hidden;
    padding-bottom: 200px;
    background: #FFFFFF;

    .gallery-slider__text {
        padding: 0;

        &__subtitle {
            h3 {
                font-size: 40px;
                line-height: 48px;
                padding-bottom: 30px;
                margin: 0;
                text-transform: capitalize;

                @media (max-width: 1024px) {
                    margin-bottom: 40px;
                }
            }

            p {
                margin: 0;
            }
        }

        &__title-arrow {
            display: flex;
            justify-content: space-between;
            align-items: flex-end;

            ul {
                li {
                    cursor: pointer;
                }
            }


            @media (max-width: 767px) {
                flex-direction: column;
            }

            &__title {
                margin-right: 20px;
            }

            h2 {
                font-weight: 500;
                margin: 0px;
                color: rgb(240, 237, 227);
                font-size: 36px;
                line-height: 40px;

                @media (max-width: 767px) {
                    margin-bottom: 40px;
                    font-size: 24px;
                    line-height: 28px;
                }
            }

            &__arrow {
                display: flex;
                gap: 20px;
                z-index: 2;
            }
        }
    }

    .gallery-slider-arrow {
        position: absolute;
        left: 0;
        bottom: 0;
        display: flex;
        justify-content: space-between;

        @media (max-width: 767px) {
            position: relative !important;
        }
    }

    .gallery-slider-left {
        flex-direction: column;
        padding-left: ${props => props.offset + 30 + 'px'};
        /* Tablet desktop :768px. */
        @media (min-width: 768px) and (max-width: 991px) {
            padding-left: 15px;
        }
        @media (max-width: 767px) {
            padding-left: 15px;
        }
    }
    
    .mySwiper {
        //padding-left: ${props => props.offset + 30 + 'px'};
        /* Tablet desktop :768px. */
        @media (min-width: 768px) and (max-width: 991px) {
            padding-left: 15px;
        }
        @media (max-width: 767px) {
            padding-left: 15px!important;
        }
    }

    .gallery-slider-arrow {
        padding-left: ${props => props.offset + 45 + 'px'};
        @media (min-width: 768px) and (max-width: 991px) {
            padding-left: 15px;
        }
        @media (max-width: 767px) {
            padding-left: 15px;
        }

    }


    @media (max-width: 767px) {
        .gallery-slider__text__subtitle {
            //padding-bottom: 40px;
        }

        .swiper-android .swiper-slide, .swiper-wrapper {
            display: flex;
            gap: 30px;
        }

        .gallery-slider-arrow {
            padding-bottom: 40px;
        }
    }


    .gallery-slider__text__subtitle h3 {
        font-size: 32px;
        font-weight: 400;
        line-height: 36px;
    }

    //slider

    .blog-single {
        p {
            font-size: 16px;
            line-height: 24px;
            padding: 0;
            margin: 20px 0 5px 0;
            color: rgba(28, 55, 0, 0.5);
        }

        h5 {
            font-size: 20px;
            line-height: 36px;
            margin: 0;
            font-weight: bold;
        }

        .blog-single__inner {
            position: relative;
            padding-top: calc(370 / 270 * 100%);

            img {
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                height: 100%;
                width: 100%;
            }

            a {
                position: absolute;
                height: 100%;
                width: 100%;
                left: 0;
                top: 0;
                z-index: 3;
            }

            /*&__content {
              &__top {
                h2 {
      
                  position: absolute;
                  bottom: 30px;
                  left: 40px;
                  right: 30px;
                  z-index: 2;
                  font-size: 36px;
                  font-weight: 500;
                  line-height: 40px;
                  color: black;
                  transition: color .6s ease;
                  margin: 0;
                  @media (max-width: 767px) {
                    font-size: 20px;
                    line-height: 24px;
                  }
                }
              }
      
              &__bottom {
                position: absolute;
                margin: 0;
                left: 30px;
                right: 30px;
                bottom: 24px;
                display: flex;
                justify-content: space-between;
                padding-top: 20px;
                z-index: 2;
      
                h3 {
                  color: #FFFFFF;
                  transition: color .3s ease;
      
                  p {
                    display: block;
                    color: #FFFFFF;
                  }
                }
      
                p {
                  color: #FFFFFF;
                  text-align: right;
                  transition: color .3s ease;
      
                  span {
                    display: block;
                    color: #FFFFFF;
                  }
                }
              }
            }*/

        }

    }
}

//tablet

//@media (min-width: 768px) and (max-width: 1024px) {
@media (max-width: 1024px) {
    .gallery-slider {
        &__tablet {
            display: block;

            .col-sm-4 {
                max-width: 100%;

                .gallery-slider-arrow {
                    position: relative;
                    padding: 40px 0;
                }
            }

            .col-sm-7 {
                max-width: 100%;
                margin-left: unset;

                .swiper {
                    width: 100%;
                    //margin-left: 15px;
                    //margin-right: 15px;
                }

                .blog-single__inner {
                    img {
                        height: 100%;
                        width: 100%;
                    }
                }
            }
        }
    }
}


@media (max-width: 767px) {
    .gallery-slider__right p {
        padding-bottom: 40px;
    }

    .gallery-slider__text__subtitle h3 {
        margin: unset;
        padding-bottom: 40px;
    }
}

.container-fluid {
    padding: 0;
}

.mySwiper {
    //cursor: pointer;
    //width: 110%;
    @media (max-width: 767px) {
        padding-left: 0px;
        width: 100%;
        padding-right: 90px;
    }
}

// .swiper-wrapper {
//   padding-left: ${props => props.offset ? props.offset + 15 + 'px' : '100px'};
// }

//.swiper-slide {
//  @media (max-width: 991px) {
//    padding-right: 15px !important;
//    padding-left: 15px !important;
//  }
//  @media (max-width: 767px) {
//    width: 100% !important;
//    padding-right: 15px !important;
//    padding-left: 15px !important;
//  }
//}

.swiper-slide {
    transition: 0.7s all ease;
}

.swiper-button-next, .swiper-button-prev {
    display: none;
}

/*
.image_wrapper {
  position: relative;
  padding-top: calc(312 / 370 * 100%);


  img {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

@media (max-width: 870px) {
  .gallery-slider__text__title-arrow ul {
    align-items: center !important;
  }
}

@media (min-width: 768px) {
  .mobile-flex-svg {
    display: none;
  }

  .desktop-hide {
    display: none;
  }
}

@media (max-width: 767px) {
  .mobile-hide {
    display: none;
  }

  .mobile-flex {
    display: flex;
    justify-content: space-between;

    a {
      margin-left: auto;
    }

    ul {
      display: flex;

      li {
        margin-right: 20px;
      }
    }

    .mobile-flex-svg {
      display: block;
    }
  }

  .global-image {
    img {
      object-fit: contain;
      top: 0;
      margin-top: 40%;
      @media (max-width: 767px) {
        top: unset;
      }
    }
  }*/


`;

export default GalleryHome;
