import styled from "styled-components";
import {Col, Container, Row} from "react-bootstrap";
import React, {useEffect, useRef, useState} from "react";
import {Swiper, SwiperSlide} from "swiper/react";
import {Pagination, Navigation, EffectCreative, Autoplay} from "swiper";
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import {Img} from "./Img";
import ReactHtmlParser from "react-html-parser";

const RandomSliderV2 = ({offset, data, bgDark}) => {


    const nextsRef = useRef()
    const prevsRef = useRef()

    const [current, setCurrent] = useState('');

    const handleSliderCurrent = (swiper) => {
        // Get the real current slide index starting from 1
        const realCurrentSlide = swiper.realIndex + 1;
        setCurrent(realCurrentSlide.toString());
    };

    let image;
    // image = data?.images ?  data?.images : data?.images?.list;
    image = data?.images?.list ?  data?.images?.list : data?.images;

    useEffect(() => {
        // Recalculate the Masonry layout when data changes
        if (image && image.length > 0) {
            setTimeout(() => {
                window.dispatchEvent(new Event('resize'));
            }, 100);
        }
    }, [image]);

    return (
        <StyledBlog bgDark={bgDark} className='amenities as-image-text-slider pb-200 ' offset = {offset} >
            <Container>
                <Row>
                    <Col lg={5}>
                        <div className="slider-text">
                            <h3> {data?.data?.title || data?.section_data?.title}</h3>
                        </div>

                        <div className="mobile-flex">
                            <div className="counter">
                                <p>{current}<span>/{image?.length}</span></p>
                            </div>
                            <div className="slider-nav">
                                <ul>
                                    <li className="hovercenter" ref={prevsRef}>
                                        <svg id="Component_29_7" data-name="Component 29 – 7" xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48">
                                            <defs>
                                                <clipPath id="clip-path">
                                                    <rect id="Rectangle_5655" data-name="Rectangle 5655" width="48" height="48" rx="24" fill="none" stroke="#f7f3f0" stroke-width="1"/>
                                                </clipPath>
                                            </defs>
                                            <g id="Mask_Group_337" data-name="Mask Group 337" clip-path="url(#clip-path)">
                                                <g id="Rectangle_4990" data-name="Rectangle 4990" fill="none" stroke="#f7f3f0" stroke-width="1">
                                                    <rect width="48" height="48" rx="24" stroke="none"/>
                                                    <rect x="0.5" y="0.5" width="47" height="47" rx="23.5" fill="none"/>
                                                </g>
                                                <rect id="Rectangle_5654" data-name="Rectangle 5654" width="48" height="48" rx="24" transform="translate(-50)" fill="#f7f3f0"/>
                                                <g id="Group_19794" data-name="Group 19794" transform="translate(7)">
                                                    <g id="Group_15984" data-name="Group 15984" transform="translate(11.5 19)">
                                                        <line id="Line_3865" data-name="Line 3865" x1="5" y2="5" fill="none" stroke="#f7f3f0" stroke-linecap="round" stroke-width="2"/>
                                                        <line id="Line_3866" data-name="Line 3866" x1="5" y1="5" transform="translate(0 5)" fill="none" stroke="#f7f3f0" stroke-linecap="round" stroke-width="2"/>
                                                    </g>
                                                    <line id="Line_3889" data-name="Line 3889" x2="10" transform="translate(12.5 24)" fill="none" stroke="#f7f3f0" stroke-linecap="round" stroke-width="2"/>
                                                </g>
                                            </g>
                                        </svg>
                                    </li>
                                    <li className="hovercenter" ref={nextsRef}>
                                        <svg id="Group_19795" data-name="Group 19795" xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48">
                                            <g id="Rectangle_4990" data-name="Rectangle 4990" fill="none" stroke="#f7f3f0" stroke-width="1">
                                                <rect width="48" height="48" rx="24" stroke="none"/>
                                                <rect x="0.5" y="0.5" width="47" height="47" rx="23.5" fill="none"/>
                                            </g>
                                            <g id="Group_19794" data-name="Group 19794" transform="translate(18.5 19)">
                                                <g id="Group_15984" data-name="Group 15984" transform="translate(6)">
                                                    <line id="Line_3865" data-name="Line 3865" x2="5" y2="5" fill="none" stroke="#f7f3f0" stroke-linecap="round" stroke-width="2"/>
                                                    <line id="Line_3866" data-name="Line 3866" y1="5" x2="5" transform="translate(0 5)" fill="none" stroke="#f7f3f0" stroke-linecap="round" stroke-width="2"/>
                                                </g>
                                                <line id="Line_3889" data-name="Line 3889" x1="10" transform="translate(0 5)" fill="none" stroke="#f7f3f0" stroke-linecap="round" stroke-width="2"/>
                                            </g>
                                        </svg>



                                    </li>
                                </ul>
                            </div>
                        </div>


                    </Col>
                    <Col className={'amenities__big'} lg={7}>
                        <div className="amenities__big__slider">
                            {
                                image && <Swiper
                                    Swiper
                                    modules={[Autoplay, Pagination, Navigation, EffectCreative]}
                                    loop={true}
                                    autoplay={{
                                        delay: 12000,
                                        disableOnInteraction: false,
                                    }}

                                    // autoplay={false}
                                    slidesPerView={2}
                                    spaceBetween={30}
                                    initialSlide={1}
                                    pagination={false}
                                    navigation={{
                                        prevEl: prevsRef.current,
                                        nextEl: nextsRef.current,
                                    }}
                                    onBeforeInit={(swiper) => {
                                        swiper.params.navigation.prevEl = prevsRef.current;
                                        swiper.params.navigation.nextEl = nextsRef.current;
                                    }}

                                    speed={900}
                                    onSlideChange={(swiper) => handleSliderCurrent(swiper)}
                                >

                                    {image && image?.length > 0 &&
                                        image?.map(item => (
                                            <SwiperSlide>
                                                <div className="single">
                                                    <div className="single__img">
                                                        <Img src={item?.full_path}/>
                                                    </div>
                                                </div>
                                            </SwiperSlide>

                                        ))
                                    }

                                </Swiper>
                            }

                        </div>

                    </Col>
                    <Col className={'amenities__right'} lg={{offset: 3, span: 4}}>
                        {
                            image && <Swiper
                                Swiper
                                modules={[Autoplay, Pagination, Navigation, EffectCreative]}
                                loop={true}
                                autoplay={{
                                    delay: 12000,
                                    disableOnInteraction: false,
                                }}
                                // autoplay={false}
                                initialSlide={2}
                                pagination={{
                                    el: '.swiper-pagination',
                                    type: "fraction",
                                }}

                                navigation={{
                                    prevEl: prevsRef.current,
                                    nextEl: nextsRef.current,
                                }}
                                onBeforeInit={(swiper) => {
                                    swiper.params.navigation.prevEl = prevsRef.current;
                                    swiper.params.navigation.nextEl = nextsRef.current;
                                }}

                                speed={900}
                                onSlideChange={(swiper) => handleSliderCurrent(swiper)}

                            >
                                {image && image?.length > 0 &&
                                    image?.map(item => (
                                        <SwiperSlide>
                                            {item?.short_desc && <p>{item?.short_desc}</p>}
                                        </SwiperSlide>

                                    ))
                                }

                            </Swiper>
                        }



                    </Col>


                </Row>


            </Container>

        </StyledBlog>
    );
};

const StyledBlog = styled.section`
  position: relative;
  overflow: hidden;
  background: ${props => props.bgDark ? '#000000' : ''};
  padding-top: ${props => props.bgDark ? 200 + 'px' : ''};
  &:before {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 70%;
    background: #000000;
    z-index: -1;
    content: "";
  }
  .amenities__big__slider .swiper-slide.swiper-slide-active img {
    transition: transform 0.7s cubic-bezier(0.4, 0, 0, 1);
  }


  .amenities__big__slider .swiper-slide.swiper-slide-active:hover img {
    transform: scale(1.1);
    transition: transform 0.7s cubic-bezier(0.4, 0, 0, 1);
  }
  .swiper-slide.swiper-slide-active {
    overflow: hidden;
  }
  .container {
    position: relative;

    .col-lg-4{
      padding-left: 0;
    }
    .slider-text {
      h3 {
        margin-bottom: 70px;
        color: #F7F3F0;
      }


    }

    .counter{
      position: absolute;
      top: 100px;
      p {
        font-weight: 400;
        font-size: 24px;
        line-height: 36px;
        color: #F7F3F0;


      }
    }

    .slider-nav {
      position: absolute;
      bottom: 0px;
      left: 0;
      z-index: 3;
      margin-right: auto;

      .hovercenter{

        &:after{
          background-color: white;
        }
      }


      ul {
        display: flex;

        li {
          height: 48px;
          width: 48px;
          //background-color: rgb(34, 31, 31);
          display: flex;
          -webkit-box-align: center;
          align-items: center;
          -webkit-box-pack: center;
          justify-content: center;
          border-radius: 50%;
          cursor: pointer;
          border: 1px solid #FFFFFF;
          z-index: 9999999;

          &:nth-of-type(1) {
            margin-right: 15px;
          }

          svg {
            z-index: 2;
          }

          &:hover {
            //border: 1px solid #AC8B7C;

            svg {
              g {
                line {
                  stroke: #16322D;
                }
              }
            }
          }

        }

      }


    }
  }

  .amenities__big {
    //padding-left: 170px;
    position: relative;
    //padding-right: 0;

    &__slider {
      position: relative;
      //background-color: #DDD;
      margin-right: -${props => props.offset || '150'}px !important;

      .shadow {
        position: absolute;
        bottom: -50px;
        right: calc(100% - 43px);
        z-index: 2;
        width: 120px;

        img {
          width: 100%;
          object-fit: cover;
        }
      }

      .shadow-right {
        position: absolute;
        bottom: -52px;
        right: -49px;
      }

      .single {
        overflow: hidden;

        &__img{
          padding-top: calc(600 / 570 * 100%);
          //transition: all 0.4s ease;

          //&:hover{
          //  transform: scale(1.1);    
          //  transition: all 0.4s ease;
          //}


        }
      }


    }

    .swiper-slide-next {
      height: 50%;
      width: 30%;
      //transition: all 0.7s ease-in-out;
    }

    .swiper-slide-active {
      .single {
        overflow: hidden;
        &__img{
          //padding-top: calc(570 / 600 * 100%);
          transition: all 0.4s ease;

          //&:hover{
          //  transform: scale(1.1);
          //  transition: all 0.4s ease-in-out;
          //}


        }
      }

      height: auto;

     
      width: calc(100% - ${props => props.offset || '100'}px) !important;
      //transition: all 0.7s ease-in-out;

      .single__image {
        //padding-top: calc(480 / 570 * 100%);
        width: 100%;
        height: 100%;

      }
    }
    .swiper{
      height: 600px;
    }
    .swiper-slide {
      transition: all 0.7s ease-in-out;
      //height: 100%;
    }


  }

  .amenities__right {
    //padding-top: 50px;
    position: absolute;
    top: 65%;
    //left: 3%;
    //padding: 0 15px;

    p {
      color: #FFFFFF;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 6;
      -webkit-box-orient: vertical;
      white-space: normal;
    }

  }

  .swiper-button-prev,
  .swiper-button-next {
    display: none !important;
  }


  @media(min-width: 1500px){
    .swiper{
      height: 700px !important;
    }
  }
  
  @media(max-width: 1500px){
    //.swiper{
    //  height: 500px !important;
    //}
    .slider-text{
      h3{
        font-size: 50px;
      }
    }
  }

  @media(max-width: 1200px){
    //.swiper{
    //  height: 450px !important;
    //}
    .slider-text{
      h3{
        font-size: 45px;
        margin-bottom: 50px !important;
      }
    }
  }

  //@media(max-width: 1200px){
  //  .slider-text{
  //    h3{
  //      font-size: 38px;
  //      margin-bottom: 30px !important;
  //    }
  //  }
  //}

  /* Tablet desktop :768px. */
  @media (min-width: 768px) and (max-width: 991px) {


  }
  


  @media (max-width: 991px) {
  .swiper{
      height: unset !important;
    }

    .swiper-slide-next {
      height: 100% !important;
      width: 100% !important;
      //transition: all 0.7s ease-in-out;
    }
    
    padding-bottom: 100px;

    &:before{
      height: 40%;
    }

    .container{
      padding-left: 15px !important;
      .col-lg-4{
        padding-left: 15px;
      }
    }
    //.col-sm-7{
    //  min-width: 100%;
    //  padding: 0 15px;
    //}

    .single__img{
      padding-top: calc(80%) !important;
    }

    .mobile-flex{
      display: flex;
      justify-content: space-between;
      flex-direction: row-reverse;
      margin-bottom: 40px;
    }
   


    .amenities__right {
      position: unset;
      flex: 0 0 100%;
      max-width: 100%;
      margin-top: 40px;
    }

    .swiper-slide-next {
      height: 100% !important;
      width: 100% !important;
    }

    .amenities__big__slider {
      margin-right: 0 !important;
      .swiper-slide-active {
        height: unset !important;
        width: 100% !important;

        .single__image {
          //padding-top: calc(480 / 570 * 100%);
          width: 100%;
          height: 100%;

        }
      }
    }

    .slider-nav {
      position: unset !important;
      ul{
        li{
          border-color: #000000 !important;
          svg{
            g{
              line{
                stroke: #F7F3F0;
              }
            }
          }
        }
      }

    }

  }


  /* small mobile :320px. */
  @media (max-width: 767px) {
    padding-top: 120px;
    padding-bottom: 120px;
    .slider-text {
      margin-bottom: 20px;
      h3 {
        font-size: 36px;
        font-weight: 400;
        line-height: 40px;
        margin-bottom: 0px !important;
      }
    }
    .container .counter {
      top: 70px
    } 
  }
  
  @media(max-width: 514px){
    
    &:before{
      height: 55%;
    }
  }
  //
  //@media (max-width: 767px) {
  //  .container {
  //    padding: 0;
  //  }
  //
  //  .amenities__big__thumb {
  //    height: 150px;
  //    width: 55%;
  //    bottom: -75px;
  //
  //    div {
  //      height: 100%;
  //      width: 100%;
  //    }
  //
  //    .single {
  //      padding: 0 !important;
  //    }
  //  }
  //
  //  .amenities__big__slider .shadow {
  //    left: 45%;
  //  }
  //
  //  .amenities__right {
  //    margin-top: 120px;
  //  }
  //}

  //.swiper-pagination {
  //  opacity: 0;
  //  visibility: hidden;
  //}

`;
export default RandomSliderV2;
