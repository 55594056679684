export const ApiParam = {
    type: 'type',
    value: 'value',
    image : 'image',
    post : 'post',
    file : 'file',
    get_section : 'get_section',
    gallery : 'gallery',
    category_id: 'category_id'
};

export const ApiConcernParam = {
    type: 'type',
    value: 'value',
    image : 'image',
    post : 'post',
    file : 'file',
    specification : 'specification',
    gallery : 'gallery',
    variation : 'variation'
};
