import React, {useEffect} from 'react';
import styled from "styled-components";
import {HelmetProvider, Helmet} from 'react-helmet-async'
import InnerBanner from "../../components/InnerBanner";
import BOD from "../../components/BOD/BOD";
import {useDispatch, useSelector} from "react-redux";
import {apiEndPoints} from "../../api/network/apiEndPoints";
import {ApiParam as ApiParamKey} from "../../api/network/apiParams";
import {fetchBod} from "../../api/redux/BOD";

const MyComponent = () => {
    const dispath = useDispatch()

    // api call
    useEffect(() => {
        let api_url = apiEndPoints.SECTIONS;

        let api_params = {
            [ApiParamKey.type]: 'slug',
            [ApiParamKey.value]: 'board-of-director',
            [ApiParamKey.get_section]: 'yes',
            [ApiParamKey.image]: 'yes',
            [ApiParamKey.post]: 'yes',
            [ApiParamKey.file]: 'no',
            [ApiParamKey.gallery]: 'no',
        }

        dispath(fetchBod([api_url, api_params]))
    }, [])

    //Refactor Data

    const getData = useSelector((state) => state.bod);

    const banner = getData?.posts?.data?.sections?.find(f => f?.section_data?.slug === 'bod-banner');
    const directors = getData?.posts?.data?.sections?.find(f => f?.section_data?.slug === 'directors')?.posts?.list;
    return (
        <HelmetProvider>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>{getData?.posts?.data?.page_data?.title ? getData?.posts?.data?.page_data?.title : 'Lanco Group'}</title>
                <meta name="description"
                      content={getData?.posts?.data?.page_data?.meta_desc ? getData?.posts?.data?.page_data?.meta_desc : ''}/>
                <meta property="og:title"
                      content={getData?.posts?.data?.page_data?.og_title ? `${getData?.posts?.data?.page_data?.og_title | 'Lanco Group'}` : ''}/>
                <meta property="og:subtitle"
                      content={getData?.posts?.data?.page_data?.og_title ? `${getData?.posts?.data?.page_data?.og_title | 'Lanco Group'}` : ''}/>
                <meta property="og:description"
                      content={getData?.posts?.data?.page_data?.og_desc ? getData?.posts?.data?.page_data?.og_desc : ''}/>
                <meta property="og:url" content={window?.location?.href}/>
                <meta property="og:image" content={'/images/static/logo.svg'}/>
                <meta property="og:type" content={"website"}/>
            </Helmet>

            <StyledComponent>
                <InnerBanner img={banner?.images?.list?.[0]?.full_path} title={banner?.section_data?.subtitle}
                             text={banner?.section_data?.description}/>
                <BOD data={directors}/>
            </StyledComponent>
        </HelmetProvider>
    );
};

const StyledComponent = styled.section`

`;

export default MyComponent;
