import styled from "styled-components";
import {Col, Container, Row} from "react-bootstrap";
import Title from "../Title";
import Button from "../Button";
import React, {useEffect, useRef, useState} from "react";
import VisibilitySensor from "react-visibility-sensor";
import {Swiper, SwiperSlide} from 'swiper/react';
import {Autoplay, Navigation, Pagination} from "swiper";
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';


import UpdateBox from "../UpdateBox";

const BlogSliderV1 = ({data}) => {


    let containerRef = useRef();
    let sliderRef = useRef();
    const [current, setCurrent] = useState('')
    let [offset, setOffset] = useState(90)
    let [theWidth, SetTheWidth] = useState(0)
    let [activeNumber, setActiveNumber] = useState(1)
    let [totalNumber, setTotalNumber] = useState(5)


    useEffect(() => {
        setOffset(containerRef.current?.offsetLeft)
        window.addEventListener('resize', () => {
            setOffset(containerRef.current?.offsetLeft)
            SetTheWidth(window.innerWidth)
        })

        // slider number
        const getActiveItem = document.querySelector('.swiper-pagination-current')?.innerHTML
        const getTotalItem = document.querySelector('.swiper-pagination-total')?.innerHTML
        setActiveNumber(getActiveItem)
        setTotalNumber(getTotalItem)
    }, [null])


    useEffect(() => {
        const getTotalItem = 5
        setTotalNumber(getTotalItem)
    }, [data])
    let sliderNumber = () => {
        const getActiveItem = document.querySelector('.swiper-pagination-current').innerHTML
        setActiveNumber(getActiveItem)
    }


    return (
        <StyledBlog offset={offset} className='blog-slider pb-200 pt-200'>
            <Container ref={containerRef}>
                <Row>
                    <Col md={12}>
                        <h3 className={'header-text split-up'}>Latest updates</h3>
                    </Col>

                    <Col md={12}>
                        <div className="blog-button-mobile">

                            <div className="blog-button__right">

                                <div className="slider-nav">
                                    <ul>
                                        <li className="prevhover slider_prev1">
                                            <svg id="Component_29_16" data-name="Component 29 – 16"
                                                 xmlns="http://www.w3.org/2000/svg" width="48" height="43"
                                                 viewBox="0 0 48 48">
                                                <g id="Mask_Group_337" data-name="Mask Group 337">
                                                    <g id="Rectangle_4990" data-name="Rectangle 4990" fill="none"
                                                       stroke="#000000" stroke-width="1">
                                                        <rect width="48" height="43" rx="24" stroke="none"/>
                                                        {/*<rect x="0.5" y="0.5" width="47" height="47" rx="23.5" fill="none"/>*/}
                                                    </g>
                                                    <rect id="Rectangle_5654" data-name="Rectangle 5654" width="48"
                                                          height="43" rx="24" transform="translate(-50)"
                                                          fill="#000000"/>
                                                    <g id="Group_19794" data-name="Group 19794"
                                                       transform="translate(7)">
                                                        <g id="Group_15984" data-name="Group 15984"
                                                           transform="translate(11.5 19)">
                                                            <line id="Line_3865" data-name="Line 3865" x1="5" y2="5"
                                                                  fill="none" stroke="#000000" stroke-linecap="round"
                                                                  stroke-width="1"/>
                                                            <line id="Line_3866" data-name="Line 3866" x1="5" y1="5"
                                                                  transform="translate(0 5)" fill="none"
                                                                  stroke="#000000" stroke-linecap="round"
                                                                  stroke-width="1"/>
                                                        </g>
                                                        <line id="Line_3889" data-name="Line 3889" x2="10"
                                                              transform="translate(12.5 24)" fill="none"
                                                              stroke="#000000" stroke-linecap="round" stroke-width="1"/>
                                                    </g>
                                                </g>
                                            </svg>
                                        </li>
                                        <li className="nexthover slider_next1">
                                            <svg id="Component_29_16" data-name="Component 29 – 16"
                                                 xmlns="http://www.w3.org/2000/svg" width="48" height="43"
                                                 viewBox="0 0 48 48">
                                                <g id="Mask_Group_337" data-name="Mask Group 337">
                                                    <g id="Rectangle_4990" data-name="Rectangle 4990" fill="none"
                                                       stroke="#000000" stroke-width="1">
                                                        <rect width="48" height="43" rx="24" stroke="none"/>
                                                        {/*<rect x="0.5" y="0.5" width="47" height="47" rx="23.5" fill="none"/>*/}
                                                    </g>
                                                    <rect id="Rectangle_5654" data-name="Rectangle 5654" width="48"
                                                          height="43" rx="24" transform="translate(-50)"
                                                          fill="#000000"/>
                                                    <g id="Group_19794" data-name="Group 19794"
                                                       transform="translate(41 48) rotate(180)">
                                                        <g id="Group_15984" data-name="Group 15984"
                                                           transform="translate(11.5 19)">
                                                            <line id="Line_3865" data-name="Line 3865" x1="5" y2="5"
                                                                  fill="none" stroke="#000000" stroke-linecap="round"
                                                                  stroke-width="1"/>
                                                            <line id="Line_3866" data-name="Line 3866" x1="5" y1="5"
                                                                  transform="translate(0 5)" fill="none"
                                                                  stroke="#000000" stroke-linecap="round"
                                                                  stroke-width="1"/>
                                                        </g>
                                                        <line id="Line_3889" data-name="Line 3889" x2="10"
                                                              transform="translate(12.5 24)" fill="none"
                                                              stroke="#000000" stroke-linecap="round" stroke-width="1"/>
                                                    </g>
                                                </g>
                                            </svg>
                                        </li>
                                    </ul>
                                </div>

                            </div>

                            <div className="desktop-btn">
                                <Button text={'View All'} src={'/update'}/>
                            </div>

                        </div>
                    </Col>
                </Row>

            </Container>


            <VisibilitySensor>
                {({isVisible}) =>
                    <div className={` fade-up blog-slider__slider-wrap`}>
                        <div className="blog-slider__slider-wrap__inner">

                            {
                                data &&  <Swiper loop={true}
                                                 spaceBetween={30}
                                                 slidesPerView={2}
                                                 allowSlideNext={true}
                                                 allowSlidePrev={true}
                                                 allowTouchMove={true}
                                                 speed={900}
                                                 pagination={{
                                                     type: "fraction",
                                                 }}
                                                 navigation={{
                                                     prevEl: '.slider_prev1',
                                                     nextEl: '.slider_next1',
                                                 }}
                                                 modules={[Autoplay, Pagination, Navigation]}

                                                 breakpoints={{
                                                     320: {
                                                         slidesPerView: 1,
                                                         spaceBetween: 30,
                                                     },
                                                     767: {
                                                         slidesPerView: 2,
                                                         spaceBetween: 30,
                                                     },
                                                     1024: {
                                                         slidesPerView: 2,
                                                         spaceBetween: 30,
                                                     },
                                                 }}
                                                 onSlideChange={(s) => sliderNumber()}

                                    // navigation={true} modules={[Navigation]}

                                                 ref={sliderRef}>
                                    {
                                        data?.map((item, index) => {
                                            let thumb = item?.images?.list?.[0]?.full_path;
                                            return (
                                                <SwiperSlide key={index}>
                                                    <UpdateBox url={item?.data?.slug} date={item?.data?.date} news={item?.data?.category_title}
                                                               title={item?.data?.title}
                                                               img={thumb}/>
                                                </SwiperSlide>
                                            )
                                        })
                                    }
                                </Swiper>
                            }
                        </div>
                    </div>
                }
            </VisibilitySensor>


            <Container ref={containerRef}>
                <Row>
                    <Col md={12}>
                        <div className="blog-button">

                            <div className="blog-button__right">

                                <div className="slider-nav">
                                    <ul>
                                        <li className="hoverGreen slider_prev1">
                                            <svg id="Component_29_16" data-name="Component 29 – 16"
                                                 xmlns="http://www.w3.org/2000/svg" width="48" height="43"
                                                 viewBox="0 0 48 48">
                                                <g id="Mask_Group_337" data-name="Mask Group 337">
                                                    <g id="Rectangle_4990" data-name="Rectangle 4990" fill="none"
                                                       stroke="#000000" stroke-width="1">
                                                        <rect width="48" height="43" rx="24" stroke="none"/>
                                                        {/*<rect x="0.5" y="0.5" width="47" height="47" rx="23.5" fill="none"/>*/}
                                                    </g>
                                                    <rect id="Rectangle_5654" data-name="Rectangle 5654" width="48"
                                                          height="43" rx="24" transform="translate(-50)"
                                                          fill="#000000"/>
                                                    <g id="Group_19794" data-name="Group 19794"
                                                       transform="translate(7)">
                                                        <g id="Group_15984" data-name="Group 15984"
                                                           transform="translate(11.5 19)">
                                                            <line id="Line_3865" data-name="Line 3865" x1="5" y2="5"
                                                                  fill="none" stroke="#000000" stroke-linecap="round"
                                                                  stroke-width="1"/>
                                                            <line id="Line_3866" data-name="Line 3866" x1="5" y1="5"
                                                                  transform="translate(0 5)" fill="none"
                                                                  stroke="#000000" stroke-linecap="round"
                                                                  stroke-width="1"/>
                                                        </g>
                                                        <line id="Line_3889" data-name="Line 3889" x2="10"
                                                              transform="translate(12.5 24)" fill="none"
                                                              stroke="#000000" stroke-linecap="round" stroke-width="1"/>
                                                    </g>
                                                </g>
                                            </svg>
                                        </li>
                                        <li className="hoverGreen slider_next1">
                                            <svg id="Component_29_16" data-name="Component 29 – 16"
                                                 xmlns="http://www.w3.org/2000/svg" width="48" height="43"
                                                 viewBox="0 0 48 48">
                                                <g id="Mask_Group_337" data-name="Mask Group 337">
                                                    <g id="Rectangle_4990" data-name="Rectangle 4990" fill="none"
                                                       stroke="#000000" stroke-width="1">
                                                        <rect width="48" height="43" rx="24" stroke="none"/>
                                                        {/*<rect x="0.5" y="0.5" width="47" height="47" rx="23.5" fill="none"/>*/}
                                                    </g>
                                                    <rect id="Rectangle_5654" data-name="Rectangle 5654" width="48"
                                                          height="43" rx="24" transform="translate(-50)"
                                                          fill="#000000"/>
                                                    <g id="Group_19794" data-name="Group 19794"
                                                       transform="translate(41 48) rotate(180)">
                                                        <g id="Group_15984" data-name="Group 15984"
                                                           transform="translate(11.5 19)">
                                                            <line id="Line_3865" data-name="Line 3865" x1="5" y2="5"
                                                                  fill="none" stroke="#000000" stroke-linecap="round"
                                                                  stroke-width="1"/>
                                                            <line id="Line_3866" data-name="Line 3866" x1="5" y1="5"
                                                                  transform="translate(0 5)" fill="none"
                                                                  stroke="#000000" stroke-linecap="round"
                                                                  stroke-width="1"/>
                                                        </g>
                                                        <line id="Line_3889" data-name="Line 3889" x2="10"
                                                              transform="translate(12.5 24)" fill="none"
                                                              stroke="#000000" stroke-linecap="round" stroke-width="1"/>
                                                    </g>
                                                </g>
                                            </svg>
                                        </li>
                                    </ul>
                                </div>

                            </div>


                            <Button text={'View All'} src={'/updates'}/>

                        </div>
                    </Col>
                </Row>
                <div className="mb-btn">
                    <Button text={'View All'} src={'/updates'}/>
                </div>
            </Container>


        </StyledBlog>
    );
};

const StyledBlog = styled.section`
  //padding-bottom: 200px;
  background: #FFFFFF;
  .mb-btn {
    display: none;
  }

  .blog-button {
    display: flex;

    .col-md-3 {
      padding: 0;
    }

    &__right {
      display: flex;
      width: 100%;
    }

    .slider-nav {
      ul {
        display: flex;
      }

      li {
        height: 48px;
        width: 48px;
        display: flex;
        -webkit-box-align: center;
        align-items: center;
        -webkit-box-pack: center;
        justify-content: center;
        border-radius: 50%;
        cursor: pointer;
        border: 1px solid #000000;

        &:nth-of-type(1) {
          margin-right: 20px;
        }

        svg {
          z-index: 2;
        }

     

      }
    }

    .dc-btn {
      display: flex;
      justify-content: end;
    }

  }

  .header-text {
    margin-bottom: 60px;
  }


  .swiper-button-next, .swiper-button-prev {
    position: absolute;
    height: 40px;
    width: 40px;
    top: 0;
    bottom: 0;
    margin: auto;
    z-index: 99;
    background-color: red;
  }

  .swiper-button-next, .swiper-button-prev, .swiper-pagination {
    opacity: 0;
    visibility: hidden;
  }

  .swiper-initialized {
      //margin-right: ${props => props.offset + 15}px;
    margin-left: ${props => props.offset + 15}px;
      // margin-right: ${props => props.offset + 15}px;
    padding-right: ${props => props.offset + 200}px;
  }

  .slider-nav-mobile {
    display: none;
  }

  .blog-slider {
    .header-text {
      margin-bottom: 60px;
    }

    &__slider-wrap {
      &__inner {
        .blog-single {
          &__inner {
            padding-top: 115%;
          }
        }
      }
    }
  }

  .blog-button-mobile {
    display: none
  }

  .blog-button-mobile {
    display: none;

    .col-md-3 {
      padding: 0;
    }

    &__right {
      display: flex;
      width: 100%;
    }

    .slider-nav {
      ul {
        display: flex;
      }

      li {
        height: 40px;
        width: 40px;
        display: flex;
        -webkit-box-align: center;
        align-items: center;
        -webkit-box-pack: center;
        justify-content: center;
        border-radius: 50%;
        cursor: pointer;
        border: 1px solid black;

        &:nth-of-type(1) {
          margin-right: 20px;
        }

        svg {
          z-index: 2;
        }

      

      }
    }

    .dc-btn {
      display: flex;
      justify-content: end;
    }

  }


  @media (max-width: 767px) {
    padding-top: 80px !important;
    padding-bottom: 80px !important;
    .blog-slider__slider-wrap {
      margin-right: 15px;
    }

    .desktop-btn {
      display: none;
    }

    .mb-btn {
      display: block;
    }

    .blog-button__right {
      width: 60%;
    }

    .header-text {
      margin-bottom: 40px;
    }

    .blog-button__right {
      //margin-top: 30px;
    }


    .swiper-container {
      margin-left: 0;
      padding-right: 0;
    }

    .blog-button {
      display: none;
    }

    .swiper-initialized {
      padding-right: unset;
    }

    .blog-button-mobile {
      display: flex;
      margin-bottom: 40px;

      &__slider-wrap {
        margin-left: 15px;
        margin-right: 15px;

        .slider-nav-mobile {
          margin-top: 40px;

          ul {
            display: flex;
          }

          li {
            height: 50px;
            width: 50px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            cursor: pointer;

            &:nth-of-type(1) {
              margin-right: 20px;
            }

            svg {
              color: #ffffff;
              z-index: 2;
            }
          }
        }
      }
    }

    .blog-button {
      margin-bottom: 40px;
    }


  }
`;
export default BlogSliderV1;
