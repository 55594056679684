import React, {useEffect, useRef, useState} from 'react';
import styled from "styled-components";
import {Container, Row, Col} from "react-bootstrap";
import reactHtmlParser from "react-html-parser";
import {text} from "../../styles/globalStyleVars";
import {useParams} from "react-router-dom";
import {gsap} from "gsap";
import ModalVideo from "react-modal-video";
import {Img} from "../../components/Img";


import SimpleBar from "simplebar-react";
import Modal from "react-bootstrap/Modal";
import {Helmet, HelmetProvider} from "react-helmet-async";
import {useDispatch, useSelector} from "react-redux";
import {apiEndPoints} from "../../api/network/apiEndPoints";
import {fetchUpdateDetail} from "../../api/redux/update";
import {ApiParam as ApiParamKey} from "../../api/network/apiParams";
import moment from "moment";


const HomeUpdateDetail = () => {
    const [shareUrl, setShareUrl] = useState('')
    useEffect(() => {
        setShareUrl(window.location.href)
    }, [])

    const [width, setWidth] = useState(0)
    useEffect(() => {
        setWidth(window.innerWidth)
    })

    //sticky
    // gsap.registerPlugin(ScrollToPlugin);

    const dispatch = useDispatch()
    const getData = useSelector(store => store.update)
    let {slug} = useParams();

    // api call
    useEffect(() => {
        let api_url = apiEndPoints.UPDATEDETAIL;

        let api_params = {
            [ApiParamKey.type]: 'slug',
            [ApiParamKey.value]: `${slug}`,
        }

        dispatch(fetchUpdateDetail([api_url, api_params]))
    }, [slug])

    const category_id_arr = [
        {
            category_id: 5,
            category_name: 'News'
        },
        {
            category_id: 6,
            category_name: 'Events'
        },
        {
            category_id: 7,
            category_name: 'Blog'
        },
    ]

    let category_name = category_id_arr.filter((category) => category.category_id === getData?.detail?.data?.data?.category_id);

    useEffect(() => {
        const desktopMenu = document.querySelector('.menu-desktop');

        // Add a class to the desktopMenu element (if found)
        if (desktopMenu) {
            desktopMenu.classList.add('bg-details');
        }

        // Remove the class from the desktopMenu element when the component unmounts (optional)
        return () => {
            // Remove the class from the desktopMenu element (if it was found)
            if (desktopMenu) {
                desktopMenu.classList.remove('bg-details');
            }
        };
    }, []);

    let banner;
    if (getData && getData.detail && getData.detail.data && getData.detail.data.images && getData.detail.data.images.list) {
        banner = getData.detail.data.images.list.find(f => f?.banner === "on");
    }

    useEffect(() => {
        gsap.to(".detail-page__text-content__detail", {
            scrollTrigger: {
                trigger: ".share-area",
                start: "top top",
                endTrigger: ".detail-page__text-content__detail",
                end: "bottom-=150px top",
                pin: true,
                pinSpacing: false
            }
        });
    }, [slug]);


    let [open, setOpen] = useState(false);
    let [videoId, setVideo] = useState('');


    let handleOpen = (open, id) => {
        setOpen(open);
        setVideo(id);
        setShow(true)
    };
    const modalRef = useRef(null);

    const [show, setShow] = useState(false);
    const [popupId, setPopupId] = useState()
    const [showVideo, setShowVideo] = useState(false)

    const handleClose = () => {
        setShow(false);
    }

    const handleCloseModal = () => {
        setOpen(false);
        setVideo('');
        handleClose();
    };

    const shareOnFacebook = () => {
        var urlToShare = encodeURIComponent(window.location.href);
        const shareUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(urlToShare)}`;
        window.open(shareUrl, '_blank');
    };

    const shareOnLinkedIn = () => {
        var urlToShare = encodeURIComponent(window.location.href);
        const shareUrl = `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(urlToShare)}`;
        window.open(shareUrl, '_blank');
    };

    const day = moment(getData?.detail?.data?.data?.date).format('DD');
    const month = moment(getData?.detail?.data?.data?.date).format('MMM');
    const year = moment(getData?.detail?.data?.data?.date).format('YYYY');

    return (<HelmetProvider>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>{getData?.detail?.data?.data?.title ? getData?.detail?.data?.data?.title : 'Lanco Group'}</title>
                <meta name="description"
                      content={getData?.detail?.data?.data?.meta_desc ? getData?.detail?.data?.data?.meta_desc : ''}/>
                <meta property="og:title"
                      content={getData?.detail?.data?.data?.og_title ? `${getData?.detail?.data?.data?.og_title | 'Lanco Group'}` : ''}/>
                <meta property="og:subtitle"
                      content={getData?.detail?.data?.data?.og_title ? `${getData?.detail?.data?.data?.og_title | 'Lanco Group'}` : ''}/>
                <meta property="og:description"
                      content={getData?.detail?.data?.data?.og_desc ? getData?.detail?.data?.data?.og_desc : ''}/>
                <meta property="og:url" content={window?.location?.href}/>
                <meta property="og:image" content={getData?.detail?.data?.images?.list?.[0]?.full_path}/>
                <meta property="og:type" content={"website"}/>
            </Helmet>
            <StyledComponent className={'detail-page pt-200'}>
                <Container className={'detail-page__text-content'}>
                    <Row>
                        <Col sm={3} className={'detail-page__text-content__share'}>
                            <div className={'share-area'}>
                                <p>Share:</p>
                                <ul className={'share-area__icons'}>
                                    <li>
                                        <a href="#" onClick={shareOnFacebook}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40"
                                                 viewBox="0 0 40 40">
                                                <circle id="Ellipse_17" data-name="Ellipse 17" cx="20" cy="20" r="20"
                                                        fill="#161f13"/>
                                                <circle id="Ellipse_17" data-name="Ellipse 17" cx="20" cy="20" r="0"
                                                        fill="#161f13"/>
                                                <path id="Path_2112" data-name="Path 2112"
                                                      d="M1206.8,105.1l.444-2.9h-2.778v-1.879a1.448,1.448,0,0,1,1.632-1.564h1.264V96.295a15.4,15.4,0,0,0-2.242-.2,3.535,3.535,0,0,0-3.784,3.9V102.2h-2.544v2.9h2.544v7h3.13v-7Z"
                                                      transform="translate(-1182.787 -84.099)" fill="#f7f3f0"/>
                                            </svg>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="" onClick={shareOnLinkedIn}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40"
                                                 viewBox="0 0 40 40">
                                                <circle id="Ellipse_13" data-name="Ellipse 13" cx="20" cy="20" r="20"
                                                        fill="#161f13"/>
                                                <circle id="Ellipse_13" data-name="Ellipse 13" cx="20" cy="20" r="0"
                                                        fill="#161f13"/>
                                                <g id="Group_15872" data-name="Group 15872"
                                                   transform="translate(14550 -1626)">
                                                    <path id="Path_6996" data-name="Path 6996"
                                                          d="M2.03-.668a1.87,1.87,0,1,0-.047,3.73h.023A1.871,1.871,0,1,0,2.03-.668Zm0,0"
                                                          transform="translate(-14538 1638.668)" fill="#f7f3f0"/>
                                                    <path id="Path_6997" data-name="Path 6997"
                                                          d="M8.109,198.313H11.7v10.8H8.109Zm0,0"
                                                          transform="translate(-14545.897 1444.891)" fill="#f7f3f0"/>
                                                    <path id="Path_6998" data-name="Path 6998"
                                                          d="M228.037,188.625a4.5,4.5,0,0,0-3.234,1.819v-1.566h-3.588v10.8H224.8v-6.029a2.459,2.459,0,0,1,.118-.876,1.964,1.964,0,0,1,1.841-1.312c1.3,0,1.818.99,1.818,2.441v5.775h3.588v-6.19c0-3.316-1.77-4.859-4.131-4.859Zm0,0"
                                                          transform="translate(-14753.429 1454.326)" fill="#f7f3f0"/>
                                                </g>
                                            </svg>
                                        </a>
                                    </li>

                                </ul>
                            </div>
                            <div className={'mobile-share'}>
                                <p>Share:</p>
                                <ul>
                                    <li className={'facebook'}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="8.57" height="16"
                                             viewBox="0 0 8.57 16">
                                            <path id="Path_2112" data-name="Path 2112"
                                                  d="M1206.8,105.1l.444-2.9h-2.778v-1.879a1.448,1.448,0,0,1,1.632-1.564h1.264V96.295a15.4,15.4,0,0,0-2.242-.2,3.535,3.535,0,0,0-3.784,3.9V102.2h-2.544v2.9h2.544v7h3.13v-7Z"
                                                  transform="translate(-1198.787 -96.099)" fill="#f7f3f0"/>
                                        </svg>

                                        {/*<FacebookShareButton url={shareUrl}/>*/}
                                    </li>
                                    <li className={'hover'}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16.738" height="16"
                                             viewBox="0 0 16.738 16">
                                            <g id="Group_15872" data-name="Group 15872"
                                               transform="translate(14538 -1638)">
                                                <path id="Path_6996" data-name="Path 6996"
                                                      d="M2.03-.668a1.87,1.87,0,1,0-.047,3.73h.023A1.871,1.871,0,1,0,2.03-.668Zm0,0"
                                                      transform="translate(-14538 1638.668)" fill="#f7f3f0"/>
                                                <path id="Path_6997" data-name="Path 6997"
                                                      d="M8.109,198.313H11.7v10.8H8.109Zm0,0"
                                                      transform="translate(-14545.897 1444.891)" fill="#f7f3f0"/>
                                                <path id="Path_6998" data-name="Path 6998"
                                                      d="M228.037,188.625a4.5,4.5,0,0,0-3.234,1.819v-1.566h-3.588v10.8H224.8v-6.029a2.459,2.459,0,0,1,.118-.876,1.964,1.964,0,0,1,1.841-1.312c1.3,0,1.818.99,1.818,2.441v5.775h3.588v-6.19c0-3.316-1.77-4.859-4.131-4.859Zm0,0"
                                                      transform="translate(-14753.429 1454.326)" fill="#f7f3f0"/>
                                            </g>
                                        </svg>

                                    </li>
                                </ul>
                            </div>
                        </Col>
                        <Col sm={9} className={'detail-page__text-content__detail'}>
                            <h4 className={'news-title'}>{getData?.detail?.data?.data?.title}</h4>
                            <div className={'detail-page__text-content__detail__date '}>
                                <p>{category_name?.[0]?.category_name}</p>
                                <p>/</p>
                                <p>{`${year}-${month}-${day}`}</p>
                            </div>
                            <div className={'full-detail'}>
                                {banner?.full_path ?
                                    <div className="banner-image"><Img src={banner?.full_path}/></div> : ''}
                                <br/>
                                <br/>
                                {getData?.detail?.data?.data?.body && reactHtmlParser(getData?.detail?.data?.data?.body)}
                            </div>
                        </Col>


                    </Row>
                </Container>

                <Modal
                    show={show}
                    // item={item}
                    onHide={handleCloseModal}
                    backdrop="static"
                    keyboard={false}
                    className="gph_modal modal_video_popup popup-version-one"
                >
                    <SimpleBar className="main_scroll " style={{height: '100vh'}}>
                        <Modal.Body>
                            <Container>
                                <Row className={'for-close'}>
                                    <div onClick={handleCloseModal} className="modal-close ">

                                        <svg id="Button_-_Close" data-name="Button - Close"
                                             xmlns="http://www.w3.org/2000/svg" width="44" height="44"
                                             viewBox="0 0 44 44">
                                            <g id="Ellipse_18" data-name="Ellipse 18" fill="none" stroke="#3c3c3b"
                                               stroke-width="1" opacity="0.3">
                                                <circle cx="22" cy="22" r="22" stroke="none"/>
                                                <circle cx="22" cy="22" r="21.5" fill="none"/>
                                            </g>
                                            <g id="Ellipse_19" data-name="Ellipse 19" fill="none" stroke="#3c3c3b"
                                               stroke-width="1" stroke-dasharray="0 142" opacity={'0'}>
                                                <circle cx="22" cy="22" r="22" stroke="none"/>
                                                <circle cx="22" cy="22" r="21.5" fill="none"/>
                                            </g>
                                            <g id="Group_18979" data-name="Group 18979"
                                               transform="translate(-3149 -104.5)">
                                                <line id="Line_4" data-name="Line 4" x2="8" y2="8"
                                                      transform="translate(3167 122.5)" fill="none" stroke="#3c3c3b"
                                                      strokeLinecap="round" stroke-width="1"/>
                                                <line id="Line_3877" data-name="Line 3877" x1="8" y2="8"
                                                      transform="translate(3167 122.5)" fill="none" stroke="#3c3c3b"
                                                      strokeLinecap="round" stroke-width="1"/>
                                            </g>
                                        </svg>

                                    </div>

                                </Row>
                            </Container>
                            <Container>
                                <Row>


                                    <div className="modal-data d-flex">
                                        <ModalVideo channel='youtube' isOpen={open}
                                                    videoId={videoId}
                                                    onClose={handleCloseModal}/>

                                    </div>
                                </Row>
                            </Container>
                        </Modal.Body>
                    </SimpleBar>
                </Modal>
            </StyledComponent>
        </HelmetProvider>
    );
};

const StyledComponent = styled.section`
    padding-top: 80px;

    .share-area {
        &__icons {
            display: flex;
            gap: 10px;
        }

        .hover {
            background-color: #000000 !important;
        }
    }

    //facebook icon

    svg {
        #Ellipse_17 {
            transition: all 0.7s cubic-bezier(0.4, 0, 0, 1);
            r: 0px;
            cursor: pointer;
        }

        &:hover {
            #Ellipse_17 {
                fill: #1877F2;
                transition: all 0.7s cubic-bezier(0.4, 0, 0, 1);
                r: 20px;
            }

        }
    }

    //instagram icon

    svg {
        #Ellipse_14 {
            transition: all 0.7s cubic-bezier(0.4, 0, 0, 1);
            r: 0px;
            cursor: pointer;
        }

        &:hover {
            #Ellipse_14 {
                fill: #E4405F;
                transition: all 0.7s cubic-bezier(0.4, 0, 0, 1);
                r: 20px;
            }

        }
    }

    //linkedin icon

    svg {
        #Ellipse_13 {
            transition: all 0.7s cubic-bezier(0.4, 0, 0, 1);
            r: 0px;
            cursor: pointer;
        }

        &:hover {
            #Ellipse_13 {
                fill: #0077B5;
                transition: all 0.7s cubic-bezier(0.4, 0, 0, 1);
                r: 20px;
            }
        }
    }

    //youtube icon

    svg {
        #Ellipse_15 {
            transition: all 0.7s cubic-bezier(0.4, 0, 0, 1);
            r: 0px;
            cursor: pointer;
        }


        &:hover {
            #Ellipse_15 {
                fill: #FF0000;
                transition: all 0.7s cubic-bezier(0.4, 0, 0, 1);
                r: 20px;
            }

        }
    }

    .full-detail {
        border-bottom: 1px solid ${text};
        padding-bottom: 200px;

        h5 {
            margin: 40px 0;
            font-size: 28px;
            line-height: 32px;
            color: #000000;
        }
    }

    .detail-page__img {
        position: relative;
        padding-top: calc(612 / 1366 * 100%);
        @media (max-width: 767px) {
            padding-top: calc(560 / 375 * 100%);
        }
    }


    .detail-page__heading {
        h1 {
            font-size: 32px;
            line-height: 36px;
            color: #000000;
            font-weight: 600;
        }

        &__tags {
            margin-top: 40px;
            display: flex;
            justify-content: space-between;
            margin-bottom: 20px;

            p {
                color: #000000;
                font-size: 16px;
                line-height: 24px;
            }

            ul {
                display: flex;

                li {

                    font-size: 16px;
                    line-height: 24px;
                    color: #000000;


                    &:not(:nth-last-child(1)) {
                        padding-right: 20px;
                        margin-right: 20px;
                        border-right: 0;
                        border-right: 1px solid #CED7ED;
                    }
                }
            }
        }
    }

    .detail-page__banner {
        width: 100%;

        img {
            width: 100%;
            object-fit: cover;
        }
    }

    .hover:after {
        border-radius: 50%;
    }

    .detail-page__text-content {
        padding-top: 40px;

        &__share {
            .mobile-share {
                display: none;
            }


            @media (max-width: 767px) {
                //border-bottom: 1px solid rgba(217, 217, 217, 0.5) !important;
                .mobile-share {
                    display: flex;
                    justify-content: space-between;
                    width: 100%;
                    border-bottom: 1px solid rgba(217, 217, 217, 0.5) !important;
                }


            }
            @media (max-width: 767px) {
                //border-bottom: 1px solid rgba(217, 217, 217, 0.5) !important;
                .mobile-share {
                    display: flex;
                    justify-content: space-between;
                    width: 100%;
                    border-bottom: 1px solid rgba(217, 217, 217, 0.5) !important;

                }

                .share-area {
                    display: none;
                }

                .sticky-outer-wrapper {
                    display: none;
                }
            }


            p {
                color: #000000;
                margin-bottom: 10px;
                //font-weight: 500 !important;
            }

            ul {
                li {
                    min-height: 30px;
                    height: 40px;
                    width: 40px;
                    min-width: 40px;
                    border-radius: 50%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    cursor: pointer;
                    margin-bottom: 20px;
                    position: relative;
                    color: #000000;
                    background: #000000;

                    button {
                        position: absolute;
                        height: 100%;
                        width: 100%;
                        z-index: 2;
                    }

                    svg {
                        position: relative;
                        z-index: 2;
                    }
                }
            }

            @media (max-width: 767px) {
                display: flex;
                justify-content: space-between !important;
            }
        }

        &__detail {


            .custom-list {
                padding: 0;

                li {

                    position: relative;
                    padding-left: 20px;
                    color: #000000;
                    //padding-top: 20px;

                    &:not(:last-child) {
                        padding-bottom: 0px;
                    }

                    &:before {
                        content: '';
                        position: absolute;
                        top: 12px;
                        left: 0;
                        margin-top: -5px;
                        width: 12px;
                        height: 12px;
                        border-radius: 50%;
                        box-sizing: content-box;
                        background-color: #AC835E;
                    }
                }

            }

            img {
                width: 100%;
            }

            span {
                p {
                    color: #000000;
                    font-weight: 500;
                    margin-top: 20px;
                }
            }

            &__date {
                display: flex;
                gap: 10px;


                p {
                    font-size: 16px;
                    line-height: 24px;
                    margin-bottom: 20px !important;
                    color: #000000;
                }

                @media (max-width: 767px) {
                    p {
                        &:not(last-child) {
                            margin-bottom: 10px !important;
                        }
                    }
                }
            }

            h4 {
                font-size: 40px;
                line-height: 48px;
                //font-weight: 500;
                margin-bottom: 40px;
                color: #000000;

                @media (max-width: 767px) {
                    font-size: 24px;
                    margin-top: 40px;
                }
            }

            h2 {
                font-size: 28px;
                line-height: 36px;
                font-weight: 500;
                margin-bottom: 20px;
                color: #000000;
            }

            p {
                font-weight: 400;
                //color: #FFFDFB;
            }

            .quotes {
                padding-left: 40px;
                position: relative;
                margin-bottom: 40px;
                color: #000000;
                font-size: 24px;
                line-height: 36px;
                font-weight: 400;
                font-style: oblique;

                &:after {
                    content: '';
                    position: absolute;
                    width: 5px;
                    height: 100%;
                    left: 0;
                    top: 0;
                    background-color: #000000;
                    border-radius: 5px;
                }

                h6 {
                    margin-top: 30px;
                    font-weight: 500;
                    color: #000000;
                }
            }

            table {
                min-width: 100%;
                margin-bottom: 30px;


                th {
                    color: #000000;
                    border-bottom: 1px solid rgb(22, 31, 19, .3);
                    padding: 20px 0;
                    font-weight: 400 !important;
                }

                td {
                    padding: 20px 0;
                    border-bottom: 1px solid rgba(22, 31, 19, 0.3);
                    color: #000000;

                    &:nth-of-type(1) {
                        padding-right: 30px;
                    }
                }
            }

            .custom-list {
                padding: 0;

                li {

                    position: relative;
                    padding-left: 20px;
                    padding-top: 20px;

                    &:not(:last-child) {
                        //padding-bottom: 20px;
                    }

                    &:before {
                        content: '';
                        position: absolute;
                        top: 12px;
                        left: 0;
                        margin-top: 13px;
                        width: 12px;
                        height: 12px;
                        border-radius: 50%;
                        box-sizing: content-box;
                        background-color: #000000;
                    }
                }

            }

            .video-box {
                position: relative;
                cursor: pointer;
                margin-top: 30px;
                width: 100%;
                margin-bottom: 60px;
                padding-top: calc(440 / 870 * 100%);

                .modal-video {
                    position: absolute;

                    .modal-video-inner {
                        //width: 100% !important;
                        //padding: 0 !important;
                    }
                }

                .hover-btn {
                    height: 60px;
                    width: 60px;
                    background-color: transparent;
                    border-radius: 50%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    position: absolute;
                    z-index: 2;
                    top: 0;
                    bottom: 0;
                    margin: auto;
                    left: 0;
                    right: 0;

                    &.hover:after {
                        border-radius: 50%;
                    }

                    svg {
                        position: relative;
                        z-index: 2;
                    }

                    path {
                        transition: all .3s ease;
                    }

                }

                &:hover {

                    .hover-btn {
                        &:after {
                            height: 100%;
                            width: 100%;
                        }

                        path {
                            fill: #000000;
                        }
                    }
                }
            }


        }

    }


    @media (max-width: 767px) {

        margin-bottom: 80px;
        .detail-page__heading__tags {
            flex-wrap: wrap;

            ul {
                min-width: 100%;
                margin-bottom: 20px;
            }

            p {
                min-width: 100%;
            }
        }

        .detail-page__text-content__share {
            margin-bottom: 60px;
            min-width: 100%;

            ul {
                display: flex;

                li {
                    margin-right: 10px;
                }
            }
        }

        .detail-page__text-content__detail {
            min-width: 100%;
        }
    }
    //.sticky-inner-wrapper{
    //  top:120px !important;
    //}
    @media (max-width: 767px) {
        .full-detail {
            padding-bottom: 0;
        }

        .title h2 {
            text-align: start !important;
        }

        .news-title {
            margin-top: 0 !important;
        }
    }

    .banner-image {
        position: relative;
        padding-top: calc(410 / 870 * 100%);
    }

    .media-details-image {
        position: relative;
        padding-top: calc(480 / 870 * 100%);
    }
`;

export default HomeUpdateDetail;
