import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {Accordion} from "react-bootstrap";
import {Link, useLocation} from "react-router-dom";
import {paragraph, text, title} from "../styles/globalStyleVars";
import {CSSPlugin, gsap} from "gsap";
const MainMenu = ({data}) => {
    gsap.registerPlugin(CSSPlugin);
    const location = useLocation()
    const [offset, setOffset] = useState(0);

    useEffect(() => {
        setOffset(document.querySelector(".container").offsetLeft);
    })

    // menu action
    useEffect(() => {
        const getMenu = document.querySelector('.slide-menu');
        const getClick = document.querySelector('.hamburger');
        const getCloseClick = document.querySelectorAll('.close, .slide-menu a');
        const tl = gsap.timeline({ paused: true });

        // Menu open animation
        tl.to(getMenu, {
            display: 'flex',
            duration: 0
        }).to('.menu-overlay', {
            display: 'block',
            duration: 0,
        }, '-=.3').to('.menu-overlay', {
            opacity: 1
        }).to(getMenu, {
            x: 0,
            ease: 'Power4.easeInOut'
        }, '-=.5').to('.slide-menu li,.accordion-item ', {
            y: 0,
            opacity: 1,
            ease: 'Power4.easeInOut',
            stagger: .02
        }, '-=.3');

        getClick.addEventListener('click', () => {
            document.body.classList.add('menu-open');
            tl.play();
        });

        getCloseClick.forEach(closeElement => {
            closeElement.addEventListener('click', (e) => {
                if (document.body.classList.contains('menu-open') && !e.target.matches('.hamburger')) {
                    tl.reverse().eventCallback('onReverseComplete', () => {
                        document.body.classList.remove('menu-open');
                        gsap.set('.menu-overlay', { display: 'none', opacity: 0 });
                        gsap.set(getMenu, { display: 'none' });
                    });
                }
            });
        });


        // Cleanup event listeners on component unmount
        return () => {
            getClick.removeEventListener('click', () => tl.play());
            getCloseClick.forEach(closeElement => {
                closeElement.removeEventListener('click', (e) => {
                    if (document.body.classList.contains('menu-open') && !e.target.matches('.hamburger')) {
                        tl.reverse().eventCallback('onReverseComplete', () => {
                            document.body.classList.remove('menu-open');
                            gsap.set('.menu-overlay', { display: 'none', opacity: 0 });
                            gsap.set(getMenu, { display: 'none' });
                        });
                    }
                });
            });
        };
    }, []);

    // menu fixed on scroll
    useEffect(() => {
        if (document.body.classList.contains('scroll-down')) {
            document.body.classList.remove('scroll-down');
        }
    });

    useEffect(() => {
        const body = document.body;
        const scrollUp = 'scroll-up';
        const scrollDown = 'scroll-down';
        let lastScroll = 0;
        let howMuchScroll;

        if (window.screen.width < 991) {
            howMuchScroll = 150;
        } else {
            howMuchScroll = 150;
        }

        window.addEventListener('scroll', () => {
            let currentScroll = window.pageYOffset;

            if (currentScroll <= howMuchScroll) {
                body.classList.remove(scrollUp);
                body.classList.remove(scrollDown);
                return;
            }

            if (currentScroll > lastScroll && currentScroll > howMuchScroll) {
                // down
                body.classList.remove(scrollUp);
                body.classList.add(scrollDown);
            } else if (currentScroll < lastScroll && currentScroll > howMuchScroll) {
                // up
                body.classList.remove(scrollDown);
                body.classList.add(scrollUp);
            }

            lastScroll = currentScroll;
        });
    }, []);

    return (
        <StyledComponent offset={offset} showBg className={'main-menu'}>
            <div className="menu-overlay"/>
            <div className="main-menu__logo">
                <Link to={'/'}><img src="/images/static/logo.svg" alt=""/></Link>
                <Link to={'/'}><img src="/images/static/logo-colored.svg" alt=""/></Link>
            </div>

            {/*menu bar */}
            <div className="main-menu__bar">
                <ul>
                    <li className={'hamburger slide'}>
                        <span/>
                        <span/>
                        <span/>
                    </li>
                </ul>
            </div>

            {/*menu item slide */}
            <div className="slide-menu">
                <div className="close-wrap">
                    <img src="/images/static/logo-white.svg" alt=""/>
                    <svg className="close" xmlns="http://www.w3.org/2000/svg" width="27.577" height="27.577"
                         viewBox="0 0 27.577 27.577">
                        <line id="Line_3541" data-name="Line 3541" x2="35" transform="translate(1.414 1.414) rotate(45)"
                              fill="none" stroke="#f0ede3" stroke-linecap="round" stroke-width="2"/>
                        <line id="Line_12368" data-name="Line 12368" x2="35"
                              transform="translate(1.414 26.163) rotate(-45)" fill="none" stroke="#f0ede3"
                              stroke-linecap="round" stroke-width="2"/>
                    </svg>
                </div>

                <ul>
                    <Accordion>
                        <li className={location?.pathname === '/' ? 'active' : ''}><Link to={'/'}>Home</Link></li>
                        <li className={location?.pathname === '/our-story' ? 'active' : ''}><Link to={'/our-story'}>Our
                            Story</Link></li>
                        <li className={location?.pathname === '/mission-vision' ? 'active' : ''}><Link
                            to={'/mission-vision'}>Mission
                            Vision</Link></li>
                        <li className={location?.pathname === '/board-of-directors' ? 'active' : ''}><Link
                            to={'/board-of-directors'}>Board
                            of Director</Link></li>
                        <li className={location?.pathname === '/csr' ? 'active' : ''}><Link to={'/csr'}>CSR</Link></li>
                        <li className={location?.pathname === '/career' ? 'active' : ''}><Link
                            to={'/career'}>Career</Link></li>
                        <li className={location?.pathname === '/team' ? 'active' : ''}><Link
                            to={'/team'}>Our Team</Link></li>
                        <Accordion.Item
                            className={location?.pathname.startsWith('/projects') ? 'active' : ''}
                            eventKey="1">
                            <Accordion.Header><span>Companies</span> <img src="/images/static/caret-down.svg"
                                                                          alt=""/></Accordion.Header>
                            <Accordion.Body>
                                <ul>
                                    <li><Link to='/concern/lanco-developments'>Lanco Developments</Link></li>
                                    <li><Link to='/concern/lanco-foundation'>Lanco Foundation</Link></li>
                                    <li><Link to='/concern/lanco-agro'>Lanco Agro</Link></li>
                                </ul>
                            </Accordion.Body>
                        </Accordion.Item>
                        <li className={location?.pathname === '/updates' ? 'active' : ''}><Link
                            to={'/updates'}>Updates</Link></li>
                        <li className={location?.pathname === '/contact' ? 'active' : ''}><Link
                            to={'/contact'}>Contact</Link></li>
                    </Accordion>
                </ul>

                <div className="socials">
                    <ul>
                        {
                            data?.posts?.data?.facebook &&
                            <li><a target={"_blank"} href={data?.posts?.data?.facebook}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="8.161" height="17.514"
                                     viewBox="0 0 8.161 17.514">
                                    <path id="Path_26" data-name="Path 26"
                                          d="M1014.546,3920.734v-1.509a3.281,3.281,0,0,1,.032-.527,1.025,1.025,0,0,1,.144-.366.586.586,0,0,1,.361-.25,2.612,2.612,0,0,1,.665-.069h1.508V3915h-2.412a3.923,3.923,0,0,0-3.007.994,4.183,4.183,0,0,0-.914,2.928v1.817h-1.807v3.018h1.806v8.757h3.624v-8.757h2.413l.318-3.018Z"
                                          transform="translate(-1009.116 -3914.995)" fill="#9b9b9b"/>
                                </svg>
                            </a></li>
                        }
                        {
                            data?.posts?.data?.instagram &&
                            <li><a target={"_blank"} href={data?.posts?.data?.instagram}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
                                    <path id="Path_5912" data-name="Path 5912"
                                          d="M8,1.442c2.136,0,2.389.008,3.233.046a4.425,4.425,0,0,1,1.485.276,2.479,2.479,0,0,1,.92.6,2.479,2.479,0,0,1,.6.92,4.43,4.43,0,0,1,.276,1.485c.038.844.046,1.1.046,3.233s-.008,2.389-.046,3.233a4.43,4.43,0,0,1-.276,1.485,2.649,2.649,0,0,1-1.518,1.518,4.425,4.425,0,0,1-1.485.276c-.843.038-1.1.046-3.233.046s-2.389-.008-3.233-.046a4.425,4.425,0,0,1-1.485-.276,2.479,2.479,0,0,1-.92-.6,2.479,2.479,0,0,1-.6-.92,4.425,4.425,0,0,1-.276-1.485c-.038-.844-.046-1.1-.046-3.233s.008-2.389.046-3.233a4.425,4.425,0,0,1,.276-1.485,2.479,2.479,0,0,1,.6-.92,2.479,2.479,0,0,1,.92-.6,4.425,4.425,0,0,1,1.485-.276c.844-.038,1.1-.046,3.233-.046M8,0C5.827,0,5.555.009,4.7.048A5.873,5.873,0,0,0,2.76.42a3.921,3.921,0,0,0-1.417.923A3.921,3.921,0,0,0,.42,2.76,5.873,5.873,0,0,0,.048,4.7C.009,5.555,0,5.827,0,8s.009,2.445.048,3.3A5.873,5.873,0,0,0,.42,13.24a3.921,3.921,0,0,0,.923,1.417,3.923,3.923,0,0,0,1.417.923,5.873,5.873,0,0,0,1.942.372C5.555,15.991,5.827,16,8,16s2.445-.009,3.3-.048a5.873,5.873,0,0,0,1.942-.372,4.09,4.09,0,0,0,2.34-2.34,5.873,5.873,0,0,0,.372-1.942c.039-.853.048-1.126.048-3.3s-.009-2.445-.048-3.3A5.873,5.873,0,0,0,15.58,2.76a3.923,3.923,0,0,0-.923-1.417A3.921,3.921,0,0,0,13.24.42,5.873,5.873,0,0,0,11.3.048C10.445.009,10.173,0,8,0"
                                          transform="translate(0 0)" fill="#9b9b9b"/>
                                    <path id="Path_5913" data-name="Path 5913"
                                          d="M24.784,20.676a4.108,4.108,0,1,0,4.108,4.108,4.108,4.108,0,0,0-4.108-4.108m0,6.775a2.667,2.667,0,1,1,2.667-2.667,2.667,2.667,0,0,1-2.667,2.667"
                                          transform="translate(-16.784 -16.784)" fill="#9b9b9b"/>
                                    <path id="Path_5914" data-name="Path 5914"
                                          d="M62.007,15.673a.96.96,0,1,1-.96-.96.96.96,0,0,1,.96.96"
                                          transform="translate(-48.777 -11.943)" fill="#9b9b9b"/>
                                </svg>
                            </a></li>
                        }
                        {data?.posts?.data?.linkedin && <li><a target={"_blank"} href="https://bd.linkedin.com/company/lancogroup">
                            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="13.225"
                                 viewBox="0 0 14 13.225">
                                <path id="LinkedIn"
                                      d="M1101.435,3924.414v5.117h-3v-4.775c0-1.2-.434-2.018-1.521-2.018a1.64,1.64,0,0,0-1.539,1.085,2.009,2.009,0,0,0-.1.724v4.983h-3s.04-8.086,0-8.924h3v1.266l-.02.028h.02v-.028a2.984,2.984,0,0,1,2.705-1.475C1099.954,3920.4,1101.435,3921.674,1101.435,3924.414Zm-12.3-8.108a1.548,1.548,0,1,0-.04,3.084h.02a1.548,1.548,0,1,0,.02-3.084Zm-1.52,13.225h3v-8.924h-3Z"
                                      transform="translate(-1087.435 -3916.306)" fill="#9b9b9b"/>
                            </svg>
                        </a></li>}
                        {
                            data?.posts?.data?.youtube && <li><a target={"_blank"} href={data?.posts?.data?.youtube}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="15" height="10.5" viewBox="0 0 15 10.5">
                                    <path id="Path_29" data-name="Path 29"
                                          d="M1180.9,3919.725c-.542-.64-1.542-.9-3.451-.9h-6.93c-1.953,0-2.969.278-3.509.959-.526.665-.526,1.644-.526,3v2.583c0,2.625.624,3.958,4.035,3.958h6.93c1.656,0,2.573-.23,3.167-.8.609-.579.869-1.525.869-3.163v-2.583C1181.487,3921.354,1181.446,3920.368,1180.9,3919.725Zm-4.786,4.706-3.147,1.636a.484.484,0,0,1-.707-.427v-3.262a.482.482,0,0,1,.232-.411.488.488,0,0,1,.474-.017l3.147,1.625a.481.481,0,0,1,0,.854Z"
                                          transform="translate(-1166.487 -3918.824)" fill="#9b9b9b"/>
                                </svg>
                            </a></li>
                        }
                    </ul>
                </div>
                <div className="footer">
                    <p>© {new Date().getFullYear()} Lanco Group. All Rights Reserved.</p>
                    <p><a target={"_blank"} href="https://dcastalia.com/">Designed & Developed by Dcastalia</a></p>
                </div>
            </div>
        </StyledComponent>
    );
};

const StyledComponent = styled.header`
    height: 100px;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 9999;
    display: flex;
    justify-content: space-between;
    transition: all .6s ease-in-out;
    padding-left: ${props => props.offset ? props.offset + 30 + 'px' : ''};

    .menu-overlay {
        position: fixed;
        height: 100vh;
        width: 100vw;
        background: rgba(0, 0, 0, 0.8); /* Opacity of 25% */
        z-index: 999;
        inset: 0;
        display: none;
        opacity: 1; /* Set the initial opacity to 1 */
        //backdrop-filter: blur(15px) brightness(1.15); /* Adjust the blur and brightness amounts as needed */
    }

    .close-wrap {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: fit-content;
        width: fit-content;
        margin-left: auto;
        margin-top: 40px;

        img {
            display: none;
        }

        .close {
            cursor: pointer;
        }
    }

    .footer {
        display: none;
        width: 100%;
        padding-top: 20px;

        p {
            font-size: 10px;
            line-height: 16px;
            color: rgba(249, 249, 249, 0.5);
            margin-bottom: unset;

            a {
                font-size: 10px;
                line-height: 16px;
                color: rgba(249, 249, 249, 0.5);
                margin-bottom: unset;
            }
        }
    }

    //socials
    .socials {
        margin-top: 30px;

        ul {
            display: flex;
            align-items: center;
            gap: 30px;

            li {
                margin-bottom: unset !important;
            }
        }
    }

    .main-menu__logo {
        img {
            height: 50px;
            //margin-top: 20px;
        }

        a {
            height: 100%;
            display: flex;
            align-items: center;

            &:nth-of-type(2) {
                display: none;
            }
        }
    }

    .main-menu__bar {
        height: fit-content;
        margin-right: ${props => props.offset ? props.offset + 'px' : '30px'};

        .user-thumb {
            position: relative;

            p {
                position: absolute;
                background-color: #FFF;
                top: 63px;
                padding: 10px 25px;
                left: 0;
                opacity: 0;
                transition: .5s ease;
                visibility: hidden;
            }

            &:hover {
                p {
                    opacity: 1;
                    visibility: visible;
                }
            }
        }

        ul {
            display: flex;
            height: 100px;
            align-items: center;

            .slide {
                position: relative;

                &:after {
                    content: '';
                    height: 100%;
                    left: 0;
                    background-color: #fff;
                    position: absolute;
                    width: 0;
                    transition: width .6s ease;
                }
            }

            li {
                display: flex;
                align-items: center !important;
                justify-content: center;
                position: relative;
                cursor: pointer;
                border: 1px solid rgba(255, 255, 255, 0.1);
                height: 50px;
                width: 50px;

                a {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    position: relative;
                    height: 100%;
                    width: 100%;
                }


                svg {
                    position: relative;
                    z-index: 3;

                    path {
                        transition: all .6s ease;
                    }
                }


            }

            .hamburger {
                flex-direction: column;
                align-items: flex-start;
                //padding-left: 25px;

                span {
                    height: 2.5px;
                    width: 25px;
                    background-color: #F9F9F9;
                    border-radius: 2px;
                    display: block;
                    position: relative;
                    z-index: 2;
                    transition: all .6s ease;

                    &:nth-of-type(2) {
                        margin: 5px 0;
                    }

                    &:nth-of-type(3) {

                    }
                }
            }
        }
    }

    //slide menu 

    .slide-menu {
        position: fixed;
        height: 100vh;
        width: 398px;
        right: 0;
        z-index: 9999;
        background-color: #1C1C1C;
        padding: 30px 40px 40px 70px;
        //display: flex;
        //align-items: center;
        display: none;
        transform: translateX(100%);
        flex-wrap: wrap;

        .close {
            margin-left: auto;
        }

        &__close {
            position: absolute;
            right: 30px;
            top: 30px;
            cursor: pointer;
            z-index: 2;

            #Ellipse_637, line {
                transition: all .5s ease;
            }

            &:hover {
                line {
                    stroke: ${text};
                }

                #Ellipse_637 {
                    cy: -20;
                }

                #Group_23147 {
                    cy: -10
                }
            }
        }

        .active a, .active span {
            color: #ffffff;
        }

        a {
            font-size: 20px;
            line-height: 20px;
            color: #fff;
            font-family: ${paragraph};
            transition: color 0.3s ease-in-out;

            &:hover {
                color: rgb(245, 245, 245, 0.3) !important;
                transition: color 0.3s ease-in-out;
            }
        }

        ul {
            width: 100%;

            li {
                &:not(:nth-last-child(1)) {
                    margin-bottom: 20px;
                }

                transform: translateY(20px);
                opacity: 0;

            }
        }

        .accordion-item {
            transform: translateY(20px);
            opacity: 0;

            a {
                border: none
            }
        }

        h2.accordion-header {
            font-size: unset;
            line-height: unset;


            button {
                background-color: transparent;
                padding: 0;
                box-shadow: none;
                border: none;
                font-size: 20px;
                line-height: 20px;
                color: rgba(245, 245, 245, 0.6);
                font-family: ${title};
                margin-bottom: 20px;
                transition: color .6s ease;
                width: 100%;
                text-align: left;
                position: relative;
                color: #fff;
                text-transform: capitalize;

                span {
                    font-family: ${paragraph};
                    color: #ffffff;
                    font-size: 20px;
                    line-height: 20px;
                }

                img {
                    position: absolute;
                    right: 0;
                    top: 3px;
                    transform: rotate(180deg);
                    height: 18px;
                    width: 18px;
                }


                &:hover {
                    color: rgb(245, 245, 245) !important;
                }

                &.collapsed {
                    color: rgba(245, 245, 245, 0.5);

                    img {
                        transform: rotate(0deg);
                    }
                }
            }
        }

        .accordion-body {
            margin-bottom: 20px;

            ul {
                padding-left: 20px;
            }

            a {
                font-size: 15px;
                line-height: 20px;
                color: #fff;
                text-transform: capitalize;
                transition: color 0.3s ease-in-out;

                &:hover {
                    color: rgb(245, 245, 245, 0.3) !important;
                    transition: color 0.3s ease-in-out;
                }
            }
        }
    }

    /* Normal desktop :992px. */
    @media (min-width: 992px) and (max-width: 1200px) {
        padding-left: 15px;

        .main-menu__bar {
            margin-right: 15px;
        }
    }

    @media (min-width: 768px) {
        .goto-buttons {
            display: none;
        }
    }

    @media (max-width: 767px) {
        padding-left: 15px !important;
        .close-wrap {
            width: 100%;
            img {
                display: block;
            }
        }

        .footer {
            display: block !important;
        }

        .slide-menu {
            padding: 30px 15px;
        }

        .main-menu__logo {
            img {
                width: 85%;
                height: 50px;
                //margin-top: 20px;
            }
        }

        .main-menu__bar {
            margin-right: 15px;

            ul li {
                width: 55px;

                &:nth-of-type(2), &:nth-of-type(3) {
                    display: none;
                }

                &.hamburger {
                    //padding-left: 19px;
                    //padding-right: 15px !important;
                    width: 50px !important;
                }
            }
        }

        .slide-menu {
            padding-left: 30px;
            padding-right: 30px;
            padding-bottom: 30px;
            align-items: normal;
            overflow: auto;
            padding-top: 100px;

            .goto-buttons {
                height: fit-content;

                img {
                    width: 100%;
                    display: block;

                    &:nth-of-type(1) {
                        margin-bottom: 20px;
                    }
                }
            }

            .accordion {
                margin-top: 30px;
            }
        }

    }

    @media (max-width: 500px) {
        .slide-menu {
            width: 100%;
            padding-left: 15px;
            padding-right: 15px;
            padding-bottom: 15px;
            align-items: normal;
            overflow: auto;
            padding-top: 30px;
        }
    }
    //caret

    .custom__control {
        background-color: transparent;
        border: none;
        border-bottom: 1px solid rgba(245, 245, 245, 0.5) !important;
        border-radius: 0;
        box-shadow: none;
        outline: none !important;
        cursor: pointer;
        //margin-bottom: 60px;
        margin-bottom: 20px;

        svg line {
            stroke: #fff;
        }

        .custom__single-value {
            color: #fff;
            font-size: 15px;
            line-height: 20px;
        }

        .custom__placeholder {
            color: rgba(245, 245, 245, 0.5);
            font-size: 15px;
            line-height: 20px;
        }

        .custom__value-container {
            padding-left: 0;
        }
    }

    .custom__menu {
        z-index: 9;
    }

    .css-t3ipsp-control:hover {
        border-color: #fff;
    }

    .custom__indicator-separator {
        display: none;
    }

    .custom__indicator {
        padding-right: 0;
    }
`;

export default MainMenu;
