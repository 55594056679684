import React, {useState} from 'react';
import styled from "styled-components";
import {Col, Container, Row} from "react-bootstrap";
import Select, {components} from "react-select";
import UpdateBox from "../UpdateBox";
import Button from "../Button";


const Operational = ({data, category, handleLocation, list, show}) => {


    // let findDataFromSelect = data && data.filter(f => f.page_data && f.page_data.slug === designation);

    // filter
    // let getAllPost = findDataFromSelect && findDataFromSelect.length > 0 ? findDataFromSelect.flatMap(({posts}) => posts) : data && data.flatMap(({posts}) => posts)
    // let getAllPostList = getAllPost && getAllPost.flatMap(({list}) => list)


    const customStyles = {
        option: (provided, state) => ({
            ...provided,
            borderRadius: 0,
            color: state.isSelected ? '#FFF' : 'rgba(0,0,0,0.5)',
            backgroundColor: state.isSelected ? '#000000' : '#FFF',
            margin: 0,
            cursor: 'pointer',
            paddingTop: 10,
            paddingBottom: 10,
        }),
        menu: (provided, state) => ({
            ...provided,
            color: 'rgba(0,0,0,0.5)',
            backgroundColor: state.isSelected ? 'rgba(0,0,0,0)' : 'rgba(255,255,255,0)',
            margin: 0,

        }),
        menuList: (provided, state) => ({
            ...provided,
            backgroundColor: state.isSelected ? '#000' : '#FFF',
            borderRadius: 0,
            cursor: 'pointer'
        }),

        indicatorsContainer: (provided, state) => ({
            ...provided,
            paddingRight: '30px', // Adjust the padding value as per your requirement
        }),

    };


    const DropdownIndicator = props => {
        return (
            components.DropdownIndicator && (
                <components.DropdownIndicator {...props}>
                    {props.selectProps.menuIsOpen ?
                        <svg xmlns="http://www.w3.org/2000/svg" width="10.086" height="5.751"
                             viewBox="0 0 10.086 5.751">
                            <g id="Group_4565" data-name="Group 4565" transform="translate(0.707 0.707)">
                                <path id="Path_1882" data-name="Path 1882" d="M0,0H6.132"
                                      transform="translate(8.672 0) rotate(135)" fill="none" stroke="#161f13"
                                      stroke-linecap="round" stroke-width="1"/>
                                <path id="Path_1883" data-name="Path 1883" d="M0,0H6.132"
                                      transform="translate(4.336 4.336) rotate(-135)" fill="none" stroke="#161f13"
                                      stroke-linecap="round" stroke-width="1"/>
                            </g>
                        </svg>
                        : <svg xmlns="http://www.w3.org/2000/svg" width="10.086" height="5.751"
                               viewBox="0 0 10.086 5.751">
                            <g id="Group_4565" data-name="Group 4565" transform="translate(0.707 0.707)">
                                <path id="Path_1882" data-name="Path 1882" d="M0,0H6.132"
                                      transform="translate(8.672 0) rotate(135)" fill="none" stroke="#161f13"
                                      stroke-linecap="round" stroke-width="1"/>
                                <path id="Path_1883" data-name="Path 1883" d="M0,0H6.132"
                                      transform="translate(4.336 4.336) rotate(-135)" fill="none" stroke="#161f13"
                                      stroke-linecap="round" stroke-width="1"/>
                            </g>
                        </svg>
                    }
                </components.DropdownIndicator>
            )
        );
    };


    return (
        <>
            <StyledFilter className="product-filter pt-120">
                <Container>
                    <Row>
                        <Col md={4}>
                            <Select components={{DropdownIndicator}} onChange={op => handleLocation(op.value)}
                                    styles={customStyles}
                                    className='select-here files' placeholder='Categories'
                                    isSearchable={false}
                                    options={category}/>
                        </Col>
                    </Row>
                </Container>
            </StyledFilter>


            <StyledOperational className='team-wrap pb-200'>
                <Container>
                    {
                        data && data.length > 0 ?
                            <Row>
                                {
                                    data && data?.length > 0 &&
                                    data?.map((item, index) => {
                                        return (
                                            <Col md={6}>
                                                <UpdateBox key={index} url={`${item?.data?.slug}`} date={item?.data?.date}
                                                           news={item?.data?.category_title}
                                                           img={item?.images?.list?.[0]?.full_path}
                                                           title={item?.data?.title}
                                                           src={item?.data?.title}/>
                                            </Col>
                                        )
                                    })
                                }


                            </Row> : <Row>
                                {
                                    show && list && list?.length > 0 &&
                                    list?.map((item, index) => {
                                        return (
                                            <Col md={6}>
                                                <UpdateBox url={item?.data?.slug} date={item?.data?.date}
                                                           news={item?.data?.category_title}
                                                           img={item?.images?.list?.[0]?.full_path}
                                                           title={item?.data?.title}
                                                           src={item?.data?.title}/>
                                            </Col>
                                        )
                                    })
                                }


                            </Row>

                    }

                    {/*<Row>*/}
                    {/*    <Col md={12}>*/}
                    {/*        /!*<div className="load-more d-flex justify-content-center ">*!/*/}
                    {/*        <Button text={'Load More'}/>*/}
                    {/*        /!*</div>*!/*/}
                    {/*    </Col>*/}


                    {/*</Row>*/}
                </Container>
            </StyledOperational>
        </>
    );
};


const StyledFilter = styled.section`
  background-color: #F7F3F0;
  //padding-top: 100px;
  //padding: 42px 0;

  .css-1fdsijx-ValueContainer {
    padding-left: 0;
    padding-right: 0;
  }

  .css-13cymwt-control, .css-t3ipsp-control {
    border-color: #000000 !important;
    //border: none;
    background-color: transparent;
    border-bottom: 1px solid;
    padding-left: 30px !important;
    height: 43px !important;
    border-radius: 30px !important;
    box-shadow: unset !important;
    letter-spacing: 3.4px;
    text-transform: uppercase;

  }

  .css-a3tvvj-menu {
    z-index: 9;
    text-transform: uppercase;
    //z-index: 9;
  }

  .css-1jqq78o-placeholder {
    color: #000000;
  }

  .css-yk16xz-control .css-1wa3eu0-placeholder, .css-1pahdxg-control .css-1wa3eu0-placeholder {
    color: #313033 !important;
    font-weight: 400 !important;
    font-size: 16px;
    //text-transform: capitalize;
    height: 20px !important;
    text-transform: uppercase !important;
  }

  .css-1dimb5e-singleValue {
    color: #000000 !important;
    font-weight: 400;
    font-size: 16px !important;
    line-height: 24px;
    cursor: pointer !important;
    font-family: Helvetica;
    text-transform: uppercase;
  }

  .css-yk16xz-control {
    cursor: pointer !important;
  }

  //.css-1hb7zxy-IndicatorsContainer {
  //  padding-right: 30px !important;
  //  //margin: 0 !important;
  //}

  .css-1u9des2-indicatorSeparator {
    display: none;
  }


  @media (max-width: 767px) {
    //padding: 20px 0 10px 0;
    overflow: visible;
    .container {
      overflow: visible;
    }


    .select-here {
      margin-bottom: 30px;
    }

  }


`;

const StyledOperational = styled.section`
  background-color: #F7F3F0;
  padding-top: 60px;


  .team-single {
    margin-bottom: 40px;

    &__img {
      padding-top: calc(470 / 370 * 100%);
      position: relative;
      overflow: hidden;

      img {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        height: 100%;
        width: 100%;
        object-fit: cover;
        transition: all 0.4s ease;
      }

      &:hover {
        img {
          transform: scale(1.1);
          transition: all 0.4s ease;
        }
      }
    }

    &__content {
      h4 {
        //text-transform: capitalize;
        color: #000000;
        margin: 20px 0 10px 0;
      }

      p {
        color: #000000;
      }
    }
  }

  .dc-btn {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-top: 140px;
  }

  @media (max-width: 767px) {
    padding-top: 40px;

    .team-single {
      flex: 0 0 100%;
      max-width: 100%;
      margin-bottom: 60px;

      &:nth-of-type(odd) {
        padding-right: 7.5px;
      }

      &:nth-of-type(even) {
        padding-left: 7.5px;
      }

      &:last-child {
        margin-bottom: 0;
      }

      &__content {
        h4 {
          //margin: 22px 0 19px 0;
        }

        p {
          font-size: 12px;
          //line-height: 10px;
        }
      }
    }

    .dc-btn {
      margin-top: 0;
    }

  }


`;

export default Operational;
