import { createGlobalStyle, css } from "styled-components";
import { hover, paragraph, text, title } from "./globalStyleVars";

function createCSS() {
  let styles = "";

  for (let i = 2; i < 20; i += 1) {
    styles += `
        .anim-active.fade-up:nth-child(${i}) {
          transition-delay: ${i * 0.12}s;
        }
     `;
  }

  for (let a = 2; a < 100; a += 1) {
    styles += `
        .anim-active.fade-right span:nth-child(${a}) {
          transition-delay: ${a * 0.03}s;
        }
     `;
  }

  return css`
    ${styles}
  `;
}

export default createGlobalStyle`

    ${createCSS()}
    #root {
        min-height: 100vh;
        overflow-x: hidden;
    }

    @font-face {
        font-family: 'Helvetica';
        src: url('fonts/Helvetica-Light.woff2') format('woff2'),
        url('fonts/Helvetica-Light.woff') format('woff');
        font-weight: 300;
        font-style: normal;
        font-display: swap;
    }

    @font-face {
        font-family: 'Helvetica';
        src: url('fonts/Helvetica-Compressed.woff2') format('woff2'),
        url('fonts/Helvetica-Compressed.woff') format('woff');
        font-weight: normal;
        font-style: normal;
        font-display: swap;
    }

    @font-face {
        font-family: 'Helvetica';
        src: url('fonts/Helvetica-BoldOblique.woff2') format('woff2'),
        url('fonts/Helvetica-BoldOblique.woff') format('woff');
        font-weight: bold;
        font-style: italic;
        font-display: swap;
    }

    @font-face {
        font-family: 'Helvetica';
        src: url('fonts/Helvetica-Bold.woff2') format('woff2'),
        url('fonts/Helvetica-Bold.woff') format('woff');
        font-weight: bold;
        font-style: normal;
        font-display: swap;
    }

    @font-face {
        font-family: 'Helvetica';
        src: url('fonts/Helvetica.woff2') format('woff2'),
        url('fonts/Helvetica.woff') format('woff');
        font-weight: normal;
        font-style: normal;
        font-display: swap;
    }

    @font-face {
        font-family: 'Helvetica';
        src: url('fonts/Helvetica-Oblique.woff2') format('woff2'),
        url('fonts/Helvetica-Oblique.woff') format('woff');
        font-weight: normal;
        font-style: italic;
        font-display: swap;
    }


    /* @font-face {
      font-family: 'Graphik';
      src: url('fonts/Graphik-Bold.woff2') format('font-woff2'),
      url('fonts/Graphik-Bold.woff') format('font-woff');
      font-weight: bold;
      font-style: normal;
      font-display: swap;
    } */


    body {
        //font-family: 'Euclid Square', Arial, Helvetica, freesans, sans-serif !important;
        font-family: ${paragraph};
        font-style: normal;
        margin: 0;
        color: ${text};
        padding: 0;
        overflow-x: hidden;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        min-height: 100vh;
        font-size: 16px;
        line-height: 24px;
        background-color: #F7F3F0;
    }

    body::-webkit-scrollbar {
        display: none;
    }

    a {
        transition: color .3s ease;
        text-decoration: none;

        &:hover {
            color: rgba(255, 255, 255, 0.6) !important;
            text-decoration: none;
            outline: none;
            box-shadow: none;
        }

        &:focus {
            text-decoration: none;
            outline: none;
            box-shadow: none;
            color: ${text};
        }
    }

    ::selection {
        background: ${hover};
        color: #FFF;
    }


    h1, h2, h3, h4 {
        font-family: ${title};
        margin: 0;
    }

    h5, h6 {
        font-family: ${paragraph};
    }

    ul {
        margin: 0;
        padding: 0
    }

    li {
        list-style: none;
    }

    img {
        max-width: 100%;
        object-fit: contain;
    }

    h1 {
        font-size: 100px;
        line-height: 100px;
        font-weight: 400;

        @media (max-width: 991px) {
            font-size: 60px;
            line-height: 60px;
        }

    }


    h2 {
        font-size: 80px;
        line-height: 100px;
        font-weight: 400;

        @media (max-width: 991px) {
            font-size: 40px;
            line-height: 48px;
        }

    }

    h3 {
        font-size: 52px;
        line-height: 60px;
        font-weight: 400;

        @media (max-width: 991px) {
            font-size: 36px;
            line-height: 40px;
        }

    }

    h4 {
        font-size: 40px;
        line-height: 48px;
        font-weight: 400;
        @media (max-width: 991px) {
            font-size: 32px;
            line-height: 36px;
        }
    }

    h5 {
        font-size: 28px;
        line-height: 32px;
        font-weight: 400;
        @media (max-width: 991px) {
            font-size: 24px;
            line-height: 28px;
        }
    }

    h6 {
        font-size: 20px;
        line-height: 28px;
        font-weight: 400;
    }

    p {
        font-size: 16px;
        line-height: 24px;
        font-weight: 400;
    }


    .btn:focus, button:focus, button:active:focus, .btn.active.focus, .btn.active:focus, .btn.focus, .btn:active.focus, .btn:active:focus, .btn:focus {
        outline: none;
        box-shadow: none;
    }

    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    textarea:-webkit-autofill,
    textarea:-webkit-autofill:hover,
    textarea:-webkit-autofill:focus,
    select:-webkit-autofill,
    select:-webkit-autofill:hover,
    select:-webkit-autofill:focus {
        border: 1px solid rgba(0, 0, 0, 0);
        -webkit-text-fill-color: #000;
        -webkit-box-shadow: 0 0 0px 1000px rgba(0, 0, 0, 0) inset;
        transition: background-color 5000s ease-in-out 0s;
    }


    table {
        width: 100%;
    }

    form div {
        position: relative;
    }

    .form-control {
        box-shadow: none;
        outline: 0;
        border-radius: 0;

        &:focus {
            box-shadow: none;
        }
    }

    .p-0 {
        padding: 0 !important;
    }

    .pl-0 {
        padding-left: 0;
    }

    .pr-0 {
        padding-right: 0;
    }

    .pt-120 {
        padding-top: 120px;

        @media (max-width: 767px) {
            padding-top: 120px;
        }
    }

    .pt-200 {
        padding-top: 200px;
        @media (max-width: 767px) {
            padding-top: 120px;
        }
    }

    .pb-120 {
        padding-bottom: 120px;

        @media (max-width: 767px) {
            padding-bottom: 100px;
        }
    }

    .pb-200 {
        padding-bottom: 200px;
        @media (max-width: 767px) {
            padding-bottom: 100px;
        }
    }

    .pt-160 {
        padding-top: 160px;
        @media (max-width: 767px) {
            padding-top: 100px;
        }
    }

    .pb-160 {
        padding-bottom: 160px;
        @media (max-width: 767px) {
            padding-bottom: 100px;
        }
    }

    .pb-130 {
        padding-bottom: 130px;
        @media (max-width: 767px) {
            padding-bottom: 100px;
        }
    }

    .pt-100 {
        padding-top: 100px;
        @media (max-width: 767px) {
            padding-top: 60px;
        }
    }

    .pb-100 {
        padding-bottom: 100px;
        @media (max-width: 767px) {
            padding-bottom: 60px;
        }
    }

    .pt-80 {
        padding-top: 80px;
        @media (max-width: 767px) {
            padding-top: 40px;
        }
    }

    .pb-80 {
        padding-bottom: 80px;
        @media (max-width: 767px) {
            padding-bottom: 40px;
        }
    }

    .pt-60 {
        padding-top: 60px;
    }

    .pb-40 {
        padding-bottom: 40px;
    }


    .MuiDrawer-paper {
        width: 500px !important;
        padding: 20px;
        @media (max-width: 767px) {
            width: 100% !important;
        }
    }

    .swiper-button-disabled {
        opacity: 0 !important;
    }

    @media (min-width: 1500px) {
        .container {
            //min-width: 1366px;
            min-width: 85%;
            margin: auto;
        }
    }

    @media (max-width: 1199px) and (min-width: 768px) {
        .container, .container-lg, .container-md, .container-sm {
            max-width: 90%;
            margin: auto;
        }
    }


    @media (max-width: 767px) {
        padding-bottom: 120px;
        .container, .container-sm {
            max-width: 100%;
        }
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    /* Firefox */
    input[type=number] {
        -moz-appearance: textfield;
    }


    //react select
    .css-yk16xz-control, .css-1pahdxg-control {
        height: 50px;
        border-radius: 0 !important;
        padding-left: 5px;
        font-size: 16px;
        outline: none !important;
        border-color: #D9D9D9 !important;
        box-shadow: none !important;

        .css-1wa3eu0-placeholder {
            font-weight: 300;
            line-height: 21px;
            color: rgba(0, 0, 0, 0.5);
            outline: none;
        }

        .css-1okebmr-indicatorSeparator {
            display: none;
        }

        .css-tlfecz-indicatorContainer, .css-1gtu0rj-indicatorContainer {
            margin-right: 10px;
        }
    }

    .css-2613qy-menu {
        border-radius: 0 !important;
        margin-top: 0 !important;
    }

    //animation class


    .info-window {
        max-width: 200px;
    }

    .gm-style-iw {
        border-radius: 0 !important;
    }

    .swiper-pagination-bullet {
        outline: none;
    }

    .css-nmuc1a-menu {
        z-index: 5 !important;
    }


    .map-info__img {
        img {
            height: 100px;
            margin-bottom: 12px;
            object-fit: cover;
        }
    }

    .map-info__content {
        h4 {
            font-size: 20px;
        }

        p {
            margin-bottom: 5px;
        }
    }

    .overlay {
        position: fixed;
        height: 100vh;
        width: 100%;
        background-color: rgba(0, 0, 0, 0.5);
        top: 0;
        bottom: 0;
        left: 0;
        z-index: 9;
        display: block;

        &.show {
            display: block;
        }
    }


    .form-control.has-error {
        border-color: #dc004e !important;
    }

    .has-error .find-retainer-filter__control {
        border-color: #dc004e !important;
    }

    //preloader
    .content-loader {
        position: absolute;
        height: 70%;
        width: 70%;
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        justify-content: center;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        margin: auto;
    }

    .loading-before-submit {
        position: fixed;
        height: 100vh;
        width: 100%;
        bottom: 0;
        top: 0;
        left: 0;
        background: rgba(0, 0, 0, 0.65);
        z-index: 9;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
            height: 40px;
        }
    }


    .swiper-slide {
        height: auto;
    }

    .slick-slide {
        div {
            outline: none !important
        }
    }

    button, button:active, button:focus, button:focus-visible {
        outline: none !important;
        box-shadow: none !important;
    }


    .hover {
        position: relative;
        overflow: hidden;

        span {
            z-index: 2;
        }

        &:after {
            content: '';
            position: absolute;
            height: 0;
            width: 0;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            margin: auto;
            background-color: #F7F3F0;
            transition: all .5s ease;
            border-radius: 19px;
        }

        &:hover {
            svg {
                z-index: 9;

                line {
                    stroke: #000000;
                    z-index: 9;
                }

                path {
                    fill: #000000;
                }

            }

            &:after {
                height: 100%;
                width: 100%;
            }
        }
    }

    .hoverGreen {
        position: relative;
        overflow: hidden;

        span {
            z-index: 2;
        }

        &:after {
            content: '';
            position: absolute;
            height: 0;
            width: 0;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            margin: auto;
            background-color: rgb(0, 0, 0);
            transition: all .5s ease;
            border-radius: 19px;
        }

        &:hover {
            svg {
                z-index: 9;

                line {
                    stroke: #ffffff;
                    z-index: 9;
                }

                path {
                    fill: rgb(0, 0, 0);
                }

            }

            &:after {
                height: 100%;
                width: 100%;
            }
        }
    }

    .modal-backdrop {
        background-color: rgb(34 31 31 / 90%);
        min-width: 100%;
        //z-index: 9999;

        &.show {
            opacity: 1;
        }
    }


    .valid {
        color: ${hover};
        position: absolute;
        font-size: 12px;
        top: 44px;
    }

    input[type="number"]::-webkit-outer-spin-button,
    input[type="number"]::-webkit-inner-spin-button {
        -webkit-appearance: none;
    }

    .menu-bg-color {
        background-color: #000000;
    }

    //menu fixed
    .scroll-down .main-menu {
        transform: translate3d(0, -100%, 0);
    }

    .scroll-down .main-menu__logo img {
        margin-top: unset !important;
    }

    .scroll-up .main-menu {
        background-color: #000000;
        height: 100px;

        .menu-desktop__hamburger__lines {
            &__inner .line {
                background-color: #FFF;
            }

            p {
                color: #FFF;
            }
        }

        .menu-desktop__hamburger .controller svg {
            path {
                fill: #191818;
            }

            line {
                stroke: #191818;
            }
        }

        .dc-btn a {
            color: #191818;

            &:hover {
                color: #191818 !important;
            }

            &:after, &:before {
                box-shadow: 0 0 0 1px #191818;
            }
        }
    }

    .bg-details {
        background-color: #000000;
    }

    .form-control:disabled {
        background-color: transparent;
    }

    @media (max-width: 600px) {
        .prevent-overflow {
            overflow: hidden;
            height: 100vh;
        }
    }

    .Toastify__toast-container {
        z-index: 99999999;
    }

    .mobile-menu {
        #fb-root, .fb_reset {
            display: none !important;
            opacity: 0 !important;
            visibility: hidden !important;
        }
    }

    .slick-slide {
        -webkit-transform: translate3d(0, 0, 0);
    }

    .reveal, .revealFast {
        overflow: hidden;
        height: 100%;
        width: 100%;
        display: block;
        position: absolute;
        top: 0;
        bottom: 0;

        img {
            transform-origin: left;
        }

        .global-image {
            background: transparent;
        }
    }

    //calender
    ._3efP_GeH5kyBAzqnLzL._kN_bCa3VNYpqFLH311L {
        border-radius: 0 !important;
    }

    //video modal 
    .modal-video-close-btn:before, .modal-video-close-btn:after {
        background-color: white;
    }

    .page-loader {
        position: fixed;
        background-color: #000000;
        width: 100vw;
        z-index: 999999999999999999;
        overflow: hidden;
        //opacity: 0;
        //height: 100vh;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        display: flex;
        align-items: center;
        justify-content: center;

        .log-col {
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .logo-wrapper {
            display: inline-block;
            position: relative;
            width: 220px;
            height: 40px;

            .cover {
                width: 220px;
                height: 40px;
                background: #000000;
                top: 0;
                left: 0;
                right: 0;
                content: "";
                position: absolute;
                display: flex;
                align-items: center;
                transition: 0.3s all ease;
                text-align: center;
                justify-content: flex-start;
                padding-left: 25px;

                .p1-font {
                    color: white;
                    text-align: left;
                    margin: 0;
                }
            }

            svg {
                height: 40px;
                opacity: 0;
            }
        }

        .container {
            margin: 0;
        }

        &:after {
            content: '';
            background-image: url('/images/static/background-noise.png');
            background-blend-mode: multiply;
            width: 100%;
            height: 100%;
            position: absolute;
            inset: 0;
            opacity: 0.2;
        }

        h3 {
            color: white;
            letter-spacing: 0;
        }

        .pre-loader__img {
            //position: fixed;
            height: 100px;
            width: 100px;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            background-color: rgb(36, 24, 67);
            z-index: 99999999;

            //flex: 1;
            margin: auto;
            opacity: 1;

            img {
                display: block;
            }

            .top {
                height: 8px;
            }

            .bottom {
                height: 30px;
                margin-top: 5px;
                animation: flip 1s linear infinite;
            }

            @keyframes flip {
                0% {
                    transform: rotateY(0deg)
                }
                100% {
                    transform: rotateY(180deg)
                }
            }
        }

    }

    //------------------------animation
    .split-parent {
        overflow: hidden;
    }

    .split-child {
        overflow: hidden;
    }

    .reveal {
        overflow: hidden;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;

        img {
            transform-origin: left;
        }

        .global-image {
            background: transparent;
        }
    }


    //page transition
    .page-transition {
        position: fixed;
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
        background-color: #000000;
        z-index: 999999999;
        display: none;
        opacity: 0;
        //display: flex;
        align-items: center;
        justify-content: center;

        .logo {
            height: 80px;
            opacity: 0;
        }
    }


    //  hover

    .prevhover {
        position: relative;
        overflow: hidden;

        span {
            z-index: 2;
        }

        &:after {
            content: "";
            position: absolute;
            height: 100%;
            width: 100%;
            inset: 0px;
            margin: auto;
            background-color: #FFFFFF;
            transition: all 0.5s ease 0s;
            border-radius: 50%;
            transform: translateX(110%);
        }

        &:hover {
            svg {
                g {
                    line {
                        stroke: #000000;
                    }
                }
            }

            &:after {
                transform: translateY(0);
            }
        }
    }

    .nexthover {
        position: relative;
        overflow: hidden;

        span {
            z-index: 2;
        }

        &:after {
            content: "";
            position: absolute;
            height: 100%;
            width: 100%;
            inset: 0px;
            margin: auto;
            background-color: #FFFFFF;
            transition: all 0.5s ease 0s;
            border-radius: 50%;
            transform: translateX(-110%);
        }

        &:hover {
            svg {
                g {
                    line {
                        stroke: #000000;
                    }
                }
            }

            &:after {
                transform: translateY(0);
            }
        }
    }

    .hovercenter {
        position: relative;
        overflow: hidden;

        span {
            z-index: 2;
        }

        &:after {
            content: '';
            position: absolute;
            height: 0;
            width: 0;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            margin: auto;
            background-color: #000000;
            transition: all .5s ease;
            border-radius: 50px;
            z-index: -1;
        }

        &:hover {
            svg {
                g {
                    line {
                        stroke: white;
                    }
                }
            }

            &:after {
                height: 100%;
                width: 100%;
                z-index: -1;
            }
        }
    }

    //  apply modal

    .apply-modal {
        padding-left: 0 !important;
        padding-right: 0 !important;
        z-index: 999999;


        @media (min-width: 320px) {
            .modal-dialog {
                max-width: unset;
                margin: 0;
                height: 100vh;
            }
        }

        .modal-content {
            background: #F7F3F0;
            color: #000000;
            padding-bottom: 150px;
            height: 100vh;
        }

        .modal-data__content {
            position: relative;

            h3 {
                color: #000000;
                font-size: 24px;
                line-height: 36px;
                font-weight: 400;
                //border-bottom: 1px solid rgb(22 , 31 , 19 , .2);
                margin-bottom: 60px;
                padding-bottom: 10px;
                @media (max-width: 767px) {
                    font-size: 24px;
                    margin-bottom: 50px;
                }
            }

            .form-control {
                background-color: transparent;
                border: none;
                border-bottom: 1px solid rgb(22, 31, 19, .2);
                margin-bottom: 40px;
                color: #000000;
                padding-left: 0;

                &::placeholder {
                    color: rgb(28, 55, 0, 0.5);
                    font-size: 16px;
                }
            }

            input[type="textarea"] {
                padding-bottom: 90px;
            }

            .button-group {
                display: flex;
                gap: 40px;
                margin-bottom: 10px;

                .attach-cv {
                    width: 209px;
                    cursor: pointer;
                    border: 1px solid #000000;
                    border-radius: 28px;
                    height: 43px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    gap: 10px;

                    .attach-icon {
                        background: #000000;
                        height: 43px;
                        width: 43px;
                        align-items: center;
                        justify-content: center;
                        display: flex;
                        border-radius: 50px;
                        position: absolute;
                        left: 0;
                    }

                    label {
                        margin-bottom: 0;
                        cursor: pointer;
                    }
                }

                @media (max-width: 767px) {
                    flex-direction: column;
                }
            }

            .file-name {
                font-size: 14px;
                opacity: 0.8;
                color: #000000;
                padding-left: 10px;
            }

            p {
                color: #FFFDFB;
                margin-bottom: 60px;
                @media (max-width: 767px) {
                    margin-bottom: 40px;
                }
            }
        }

        .modal-close {
            display: flex;
            justify-content: space-between;
            margin-bottom: 50px;
            margin-top: 40px;
            margin-right: 40px;

            svg {
                border-radius: 50%;
                height: 45px;
                width: 45px;
                position: relative;
                cursor: pointer;
                @media (max-width: 767px) {
                    height: 50px;
                    width: 50px;
                }

                #hover-color {
                    fill: transparent;
                    transition: fill 0.8s;
                }

                #circle-border {
                    transition: all 1s;
                }

                &:hover {
                    #hover-color {
                        fill: #000000;
                        animation: fillAnimation 0.8s forwards;

                    }

                    #Line_4, #Line_3 {
                        stroke: white;
                    }

                    #circle-border {
                        stroke-width: 0;
                    }
                }

                &:not(:hover) {
                    #hover-color {
                        fill: transparent;
                        animation: reverseFillAnimation 0.8s forwards;
                    }
                }
            }

            .title {
                color: #000000;
            }

            @keyframes fillAnimation {
                0% {
                    r: 0;
                }
                100% {
                    r: 34.5;
                }
            }
            @keyframes reverseFillAnimation {
                0% {
                    r: 34.5;
                }
                100% {
                    r: 0;
                }
            }
        }

        .modal-data {
            justify-content: unset;
        }

        input:-webkit-autofill {
            -webkit-text-fill-color: white;
        }
    }

    //video modal
    .modal_video_popup {
        .modal-content, .modal-video, .modal-dialog {
            background: transparent !important;
            border: none !important;
            box-shadow: none !important;
            outline: none !important;

        }

        .modal-dialog {
            border: 0 !important;
            margin: 0 !important;
            max-width: unset !important;
            width: 100% !important;
            height: 100% !important;
        }

        .modal-body {
            border: none !important;
            box-shadow: none !important;
        }

        .modal-close {
            opacity: 0;
        }
    }

    .menu-open {
        overflow: hidden;
    }

    .socialhover {
        position: relative;
        overflow: hidden;

        span {
            z-index: 2;
        }

        &:after {
            content: '';
            position: absolute;
            height: 0;
            width: 0;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            margin: auto;
            background-color: black;
            transition: all .5s ease;
            border-radius: 19px;
            z-index: -1;
        }

        &:hover {
            &:after {
                height: 100%;
                width: 100%;
            }
        }
    }

    .menu-toggle {
        .bg {
            background-color: rgb(22, 31, 19);
        }

        .menu-desktop {
            display: none;
        }

        .menu-open {
            display: block;
        }

        .menu-desktop {
            width: calc(100% - 120px);
        }

        .menu-items__social {
            background-color: rgb(22, 31, 19);
            transition-delay: .8s;
        }

        @media (min-width: 992px) {
            .menu-desktop {
                display: none;
            }

        }
    }

    #smooth-content {
        will-change: transform;
    }

    .modal-open{
        overflow: hidden !important;
    }
`;
