import React from 'react';
import styled from 'styled-components';
import {Container, Row, Col} from "react-bootstrap";
import reactHtmlParser from "react-html-parser";
import Button from "./Button";
import {Link} from "react-router-dom";
import {Img} from "./Img";
import moment from "moment/moment";
import formatDate from "../utiliti";

const Overview = ({
                      img,
                      news,
                      date,
                      title,
                      src,
                      url
                  }) => {


    return (
        <StyledOverview>
            <div className={'single_image_image'}>
                <div className="content_wrap">
                    {url && <Link to={`/updates/${url}`} className={"link"}> </Link>}

                    <div className="single_image_image_wrp reveal">
                        <Img src={img ? img : '/images/dynamic/slide1.jpg'}/>
                    </div>
                    <div className="single_content_wrp">
                        <div className="date">
                            <p className="cat">{news ? news : 'News'}</p>
                            <p className="date_text">{date ? date : moment(12 / 10 / 2023).format("Do MMM  YY")}</p>
                        </div>
                        <div className="title">
                            <h6 className={'split-up'}>{title ? title : 'Lanco Group in agriculture'}</h6>
                        </div>
                    </div>
                </div>
            </div>
        </StyledOverview>

    )
};

const StyledOverview = styled.div`
  .single_image_image {
    margin-bottom: 60px;

    .content_wrap {
      .link {
        position: absolute;
        height: 100%;
        width: 100%;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        z-index: 2;
        cursor: pointer;
      }

      .single_image_image_wrp {
        overflow: hidden;

        .global-image {
          transition: 0.7s all ease;
          transform: scale(1.01);
        }
      }

      &:hover {
        .single_image_image_wrp {
          .global-image {
            transform: scale(1.04);
          }
        }
      }
    }


    .single_image_image_wrp {
      position: relative;
      height: 100%;
      width: 100%;
      padding-top: calc(300 / 470 * 100%)
    }

    .single_content_wrp {
      display: flex;
      justify-content: space-between;
      margin-top: 20px;
      flex-wrap: wrap;

      .date {
        p {
          margin-bottom: 5px;
        }
        p:last-child {
          margin-bottom: unset;
        }
      }
      p.cat {
        color: rgba(22, 31, 19, 0.5);
      }

      //.date {
      //  flex: 0 0 30%;
      //  @media (max-width: 767px) {
      //    flex: 0 0 100%;
      //    justify-content: space-between;
      //    display: flex;
      //    p {
      //      display: inline-block;
      //    }
      //  }
      //
      //  .cat {
      //    margin: 0;
      //    color: rgba(22, 31, 19, 0.5);
      //  }
      //
      //  .date_text {
      //    color: #000000;
      //  }
      //}

      .title {
        flex: 0 0 70%;

        h3 {
          line-height: 35px;
          letter-spacing: 0px;
          font-size: 25px;
          margin: 0;
          @media (max-width: 767px) {
            font-size: 24px;
            line-height: 30px;
          }
        }
      }
    }
  }

  @media (max-width: 767px) {
    .single_image_image {
      margin-bottom: 40px;
    }
    .single_image_image_wrp {
      padding-top: calc(230 / 345 * 100%)
    }
    .date p {
      font-size: 14px;
      font-weight: 400;
      line-height: 24px;
    }
  }


`;


export default Overview;
