import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { gsap } from 'gsap';

export const ImageParallax = ({
                                   src,
                                   position,
                                   objectFit,
                                   height,
                                   width,
                                   banner,
                                   alt,
                                   left,
                                   margin,
                                   right,
                                   top,
                                   bottom,
                               }) => {

    return (
        <StyledImg
            className={`global-image`}
            objectFit={objectFit}
            margin={margin}
            position={position}
            left={left}
            right={right}
            top={top}
            bottom={bottom}
            height={height}
            width={width}
        >
            <img
                data-speed={0.8}
                src={src}
                alt={alt || ''}
                // onLoad={onImageLoad}
            />
        </StyledImg>
    );
};

const StyledImg = styled.div`
    position: ${(props) => props.position || 'absolute'};
    height: ${(props) => props.height || '100%'};
    width: ${(props) => props.width || '100%'};
    top: ${(props) => props.top || 0};
    left: ${(props) => props.left || 0};
    bottom: ${(props) => props.bottom || 0};
    right: ${(props) => props.right || 0};
    margin: ${(props) => props.margin || 0};
    overflow: hidden;

    .transition-img {
        opacity: 1;
        transition: opacity 1s ease-in-out;
    }

    .transition-img-hidden {
        opacity: 0;
    }

    img {
        width: 104%;
        height: 104%;
        object-fit: ${(props) => props.objectFit || 'cover'};
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        top: -20px; /* Ensure the image is positioned correctly */
        transition: opacity 1s ease-in-out; /* Smooth transition */

        @media (min-width: 821px) and (max-width: 1024px){
            top: calc(-32 / 2 * 7px);
        }
        @media (min-width: 790px) and (max-width: 820px){
            top: calc(-30 / 2 * 7px);
        }
        @media (min-width: 768px) and (max-width: 789px){
            top: calc(-26 / 2 * 7px);
        }
    }
`;
