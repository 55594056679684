export const apiEndPoints = {
  POSTS: "get-req-data/posts",
  PHOTOS: "get-req-data/photos",
  PAGE_DATA: "get-req-data/page-data",
  SECTIONS: "get-req-data/sections",
  CHILD_PAGE: "get-req-data/child-pages",
  PAGE_POST_DATA: "get-req-data/page-post-data",
  SEARCH: "get-req-data/search",
  ALL_CATEGORY: "get-req-data/all-category",
  CHILD_CATEGORY: "get-req-data/child-category",
  PRODUCT_DATA: "get-req-data/product-data",
  FEATURE_PROJECT: "get-req-data/all-featured-products",
  ALL_PRODUCTS: "get-req-data/all-products",
  SETTINGS: "get-req-data/settings-data",
  PROJECTS_OPTION: "get-req-data/product-search-params",
  GLOBAL_SETTING: 'get-req-data/settings-data',
  // ADDSUBSCRIP: 'post-req-data/add-subscriber',


  ALLUPDATES: "get-req-data/blog-list",
  UPDATEDETAIL: "get-req-data/blog-data",
  JOBLIST: "get-req-data/job-list",
  SUBMITFORM: "post-req-data/form-submit",
  CAREERFORM: "post-req-data/form-submit",
  ADDSUBSCRIP: 'post-req-data/form-submit'
};
