import React, {useEffect, useRef, useState} from 'react';
import styled from 'styled-components';

import * as Website from './animations/website.json'
import Lottie from 'react-lottie';

// import {gsap} from 'gsap';
// import ScrollTrigger from 'gsap/ScrollTrigger';
const Message = () => {

    const [stickyIcon, setStickyIcon] = useState(false);

    const handleStickyIcon = (e) => {
        e.preventDefault(); // Prevent the default behavior of the click event
        setStickyIcon(!stickyIcon)
    }

    const website = {
        loop: true,
        autoplay: true,
        animationData: Website,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        }
    };

    useEffect(() => {
        const closeStickyIconOnBodyClick = (event) => {
            // Check if the click target is not a descendant of the sticky icon
            if (!document.getElementById('click_message').contains(event.target)) {
                setStickyIcon(false);
            }
        };

        // Add a click event listener to the body
        document.body.addEventListener('click', closeStickyIconOnBodyClick);

        // Cleanup the event listener when the component unmounts
        return () => {
            document.body.removeEventListener('click', closeStickyIconOnBodyClick);
        };
    }, []);

    return (
        <StyledMessage stickyIcon={stickyIcon} data-scroll-section className={`title `}
        >
            <div className={'message_wrapper'}>
                <div id={'click_message'}
                    // ref={searchClickRef}
                     className=" social_bar">
                    {/*<div className="overlay_message"></div>*/}
                    <div className="chat-wrap">
                        <div className="chat social_bar__single facebook">
                            <a href={'https://www.facebook.com/messages/t/1963805780549669'} target={'_blank'}>
                                <svg id="Component_43_1" data-name="Component 43 – 1" xmlns="http://www.w3.org/2000/svg"
                                     width="50" height="50" viewBox="0 0 50 50">
                                    <defs>
                                        <clipPath id="clip-path3">
                                            <circle id="Ellipse_612" data-name="Ellipse 612" cx="25" cy="25" r="25"
                                                    transform="translate(1272 23)" fill="#f9f8f5"/>
                                        </clipPath>
                                    </defs>
                                    <g id="Mask_Group_343" data-name="Mask Group 343" transform="translate(-1272 -23)"
                                       clip-path="url(#clip-path3)">
                                        <circle id="Ellipse_12" data-name="Ellipse 11" cx="25" cy="25" r="25"
                                                transform="translate(1272 23)" fill="#f9f8f5"/>
                                        <circle className={'circ'} id="Ellipse_13" data-name="Ellipse 11" cx="25"
                                                cy="25" r="0" transform="translate(1272 23)" fill="#0084FF"/>

                                        <g id="Group_19872" data-name="Group 19872" transform="translate(-0.168 1.022)">
                                            <g id="Icon" transform="translate(1908.125 -7.663)">
                                                <path id="Path_9527" data-name="Path 9527"
                                                      d="M-505.252,192.455a.227.227,0,0,1-.292-.011.228.228,0,0,1-.038-.291l2.272-3.536a.76.76,0,0,1,.483-.332.756.756,0,0,1,.575.111l1.247.832a.758.758,0,0,0,.788.032l2.185-1.214a.228.228,0,0,1,.278.045.229.229,0,0,1,.022.28l-2.118,3.176a.757.757,0,0,1-1.006.238l-1.6-.913a.757.757,0,0,0-.841.06l-1.958,1.523Z"
                                                      transform="translate(-109.507 -135.355)" fill="#2d2d2d"
                                                      fill-rule="evenodd"/>
                                            </g>
                                            <g id="Group_19871" data-name="Group 19871" transform="translate(1286 37)">
                                                <g id="Group_198666" data-name="Group 198666">
                                                    <path id="Path_9530" data-name="Path 9528"
                                                          d="M11.168,0C5.01,0,0,4.476,0,9.978a9.171,9.171,0,0,0,1.691,5.281C1.25,16.64.453,19.252.445,19.279a.356.356,0,0,0,.116.376.469.469,0,0,0,.425.091L5.412,18.53a12.161,12.161,0,0,0,5.756,1.426c6.158,0,11.168-4.476,11.168-9.978S17.327,0,11.168,0Zm0,19.188a11.207,11.207,0,0,1-5.469-1.4.468.468,0,0,0-.228-.058.485.485,0,0,0-.126.017L1.48,18.809c.284-.926.794-2.582,1.09-3.5a.351.351,0,0,0-.052-.315A8.483,8.483,0,0,1,.859,9.978C.859,4.9,5.484.768,11.168.768S21.477,4.9,21.477,9.978,16.852,19.188,11.168,19.188Z"
                                                          fill="#2d2d2d"/>
                                                </g>
                                            </g>
                                        </g>
                                    </g>
                                </svg>
                            </a>
                        </div>
                        <div  className="chat social_bar__single whatsapp">
                            <a href={'https://api.whatsapp.com/send?phone=8801818181839&text=Hello%2C'} target={'_blank'}>
                                <svg id="Mask_Group_10" data-name="Mask Group 10" xmlns="http://www.w3.org/2000/svg"
                                     width="50" height="50" viewBox="0 0 50 50">
                                    <defs>
                                        <clipPath id="clip-path2">
                                            <circle id="Ellipse_610" data-name="Ellipse 610" cx="25" cy="25" r="25"
                                                    fill="#f9f8f5"/>
                                        </clipPath>
                                    </defs>
                                    <g id="Mask_Group_10-2" data-name="Mask Group 10" clip-path="url(#clip-path2)">
                                        <circle id="Ellipse_11" data-name="Ellipse 17" cx="25" cy="25" r="25"
                                                fill="#f9f8f5"/>
                                        <circle className={'circ'} id="Ellipse_17" data-name="Ellipse 11" cx="25"
                                                cy="25" r="0" fill="#25D366"/>


                                        <g id="Group_19867" data-name="Group 19867"
                                           transform="translate(13.832 13.832)">
                                            <g id="Group_19866" data-name="Group 19866">
                                                <path id="Path_9528" data-name="Path 9528"
                                                      d="M11.168,0A11.17,11.17,0,0,0,1.691,17.08C1.25,18.625.453,21.549.445,21.579a.43.43,0,0,0,.541.523l4.427-1.362A11.169,11.169,0,1,0,11.168,0Zm0,21.477A10.283,10.283,0,0,1,5.7,19.909a.43.43,0,0,0-.228-.065.437.437,0,0,0-.126.019L1.48,21.053c.284-1.036.794-2.89,1.09-3.922a.431.431,0,0,0-.052-.353,10.31,10.31,0,1,1,8.651,4.7Z"
                                                      fill="#2d2d2d"/>
                                                <path id="Path_9529" data-name="Path 9529"
                                                      d="M20.33,17.077c-.6-.335-1.117-.671-1.491-.916-.286-.187-.493-.322-.645-.4a.742.742,0,0,0-.867.061.327.327,0,0,0-.041.05A4.592,4.592,0,0,1,16.1,17.2,7.866,7.866,0,0,1,14.1,15.9a4.349,4.349,0,0,1-1.589-2.008c.592-.61.806-.993.806-1.429a9.244,9.244,0,0,0-1.239-2.518c-.19-.19-.619-.22-1.275-.089a.33.33,0,0,0-.167.089,4.261,4.261,0,0,0-1.054,4.268c.969,2.519,3.456,5.447,6.626,5.922a6.871,6.871,0,0,0,1.014.081,3.007,3.007,0,0,0,3.276-2.8A.325.325,0,0,0,20.33,17.077ZM16.3,19.489c-3.353-.5-5.465-3.825-6.114-5.511a3.6,3.6,0,0,1,.841-3.5,2.464,2.464,0,0,1,.6-.042,11.732,11.732,0,0,1,1.027,2.027c0,.171-.056.41-.721,1.076a.325.325,0,0,0-.1.231c0,1.71,3.608,4.083,4.247,4.083.556,0,1.28-.934,1.693-1.543a.31.31,0,0,1,.121.038c.118.059.321.192.579.361.34.222.792.518,1.326.824C19.566,18.7,18.888,19.878,16.3,19.489Z"
                                                      transform="translate(-3.751 -3.828)" fill="#2d2d2d"/>
                                            </g>
                                        </g>
                                    </g>
                                </svg>

                            </a>
                        </div>
                    </div>
                    <div onClick={handleStickyIcon} className="svg-button social_bar__single">
                        <Lottie options={website} height={30} width={30}/>
                    </div>

                </div>
            </div>
        </StyledMessage>

    )
};


const StyledMessage = styled.div`
  position: fixed;
  right: 18px;
  bottom: 60px;
  z-index: 990;
  
  div#click_message {
    display: flex;
    gap: 20px;
    flex-direction: column;
    overflow: hidden;
  }
  .chat-wrap {
    transition: all .3s cubic-bezier(0.5, 1, 0.89, 1);
    margin-bottom: ${props => props.stickyIcon ? '0' : '-140'}px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
    
    .facebook.chat-wrap {
        margin-bottom: ${props => props.stickyIcon ? '0' : '-140'}px;
    }
    
  .social_bar {
    transition: all .3s cubic-bezier(0.5, 1, 0.89, 1);

    &__single {
      transition: all .3s cubic-bezier(0.5, 1, 0.89, 1);
      //margin-top: 10px;
      &:nth-of-type(2) {
        border-radius: 50%;
        overflow: hidden;
      }
      img {
        cursor: pointer;
      }

      svg {
        g {
          .circ {
            transition: all 0.7s cubic-bezier(0.4, 0, 0, 1);
            //r: 25px
          }
        }
      }

      &:hover {
        svg {
          g {
            .circ {
              r: 25px;
              transition: all 0.7s cubic-bezier(0.4, 0, 0, 1);
            }
          }

          #Group_19866 {
            path {
              fill: white;
            }
          }

          #Group_19872 {
            path {
              fill: white;
            }
          }

        }
      }

    }
  }

  .svg-button {
    height: 50px;
    width: 50px;
    background: #F9F8F5;
    border-radius: 50%;
    padding: 10px;
  }


  @media (max-width: 767px) {
    transform: translate(15px, -60px);
    right: unset;
    bottom: 40px;
    .social_bar__single:hover {
      /* Set the hover styles to be the same as the normal styles for mobile */

      svg {
        g {
          .circ {
            r: 0; /* Set the radius to 0 for no hover effect on mobile */
          }
        }
      }

      #Group_19866,
      #Group_19872 {
        /* Set the fill color back to normal for mobile */

        path {
          fill: #2d2d2d !important;
        }
      }
    }


  }

`;

export default Message;














