import React, {useState} from 'react';
import styled from "styled-components";
import {Col, Container, Row} from "react-bootstrap";
import {Img} from "../Img";
import SimpleBar from 'simplebar-react';
import ModalVideo from "react-modal-video";
import Modal from "react-bootstrap/Modal";
import reactHtmlParser from "react-html-parser";

const HomeVideo = ({offset, data}) => {
    console.log(data)
    let [open, setOpen] = useState(false);

    let handelOpen = (open) => {
        setOpen(open);
        setShow(true)
    };

    const [show, setShow] = useState(false);


    const handleClose = () => {
        setShow(false);
    }

    const handleCloseModal = () => {
        setOpen(false);
        handleClose();
    };

    const videoThumb = data?.images?.find(f => f.thumb === 'on');

    return (
        <StyledHomeVideo className={'pb-200'}>
            <Container fluid style={{paddingRight: offset + 15 + 'px'}}>
                <Row>
                    <Col lg={7} onClick={() => handelOpen(true, data?.data?.description)} key={1}
                         className='home-video'>
                        <div className='home-video__wrapper'>
                            <Img
                                src={videoThumb?.full_path ? videoThumb?.full_path : 'images/static/blur.jpg'}/>
                            <div className="home-video__wrapper__click">
                                <svg xmlns="http://www.w3.org/2000/svg" width="150" height="150" viewBox="0 0 150 150">
                                    <g id="Group_20004" data-name="Group 20004" transform="translate(-384 -2613)">
                                        <g id="Ellipse_588" data-name="Ellipse 588" transform="translate(384 2613)"
                                           fill="none" stroke="#fff" stroke-width="2">
                                            <circle cx="75" cy="75" r="75" stroke="#ffffff"/>
                                            <circle className="video-icon-hover" cx="75" cy="75" r="0" stroke="#000000"
                                                    fill="#000000"/>
                                        </g>
                                        <path id="Polygon_1" data-name="Polygon 1" d="M11,0,22,19H0Z"
                                              transform="translate(469.309 2677) rotate(90)" fill="#fff"/>
                                    </g>
                                </svg>
                            </div>
                        </div>
                    </Col>

                    <Col className={'right-title'} lg={{span: 4, offset: 1}} data-speed={1.2}>
                        {data?.data?.title &&
                            <h4 className={'title split-up'}>{reactHtmlParser(data?.data?.title)}</h4>}
                    </Col>
                </Row>
            </Container>

            <Modal
                show={show}
                // item={item}
                onHide={handleCloseModal}
                backdrop="static"
                keyboard={false}
                className="gph_modal modal_video_popup popup-version-one"
                dialogClassName="custom-modal-dialog"
            >
                <SimpleBar className="main_scroll " style={{height: '100vh'}}>
                    <Modal.Body>
                        <Container>
                            <Row className={'for-close'}>
                                <div onClick={handleCloseModal} className="modal-close ">

                                    <svg id="Button_-_Close" data-name="Button - Close"
                                         xmlns="http://www.w3.org/2000/svg" width="44" height="44" viewBox="0 0 44 44">
                                        <g id="Ellipse_18" data-name="Ellipse 18" fill="none" stroke="#3c3c3b"
                                           stroke-width="1" opacity="0.3">
                                            <circle cx="22" cy="22" r="22" stroke="none"/>
                                            <circle cx="22" cy="22" r="21.5" fill="none"/>
                                        </g>
                                        <g id="Ellipse_19" data-name="Ellipse 19" fill="none" stroke="#3c3c3b"
                                           stroke-width="1" stroke-dasharray="0 142" opacity={'0'}>
                                            <circle cx="22" cy="22" r="22" stroke="none"/>
                                            <circle cx="22" cy="22" r="21.5" fill="none"/>
                                        </g>
                                        <g id="Group_18979" data-name="Group 18979" transform="translate(-3149 -104.5)">
                                            <line id="Line_4" data-name="Line 4" x2="8" y2="8"
                                                  transform="translate(3167 122.5)" fill="none" stroke="#3c3c3b"
                                                  strokeLinecap="round" stroke-width="1"/>
                                            <line id="Line_3877" data-name="Line 3877" x1="8" y2="8"
                                                  transform="translate(3167 122.5)" fill="none" stroke="#3c3c3b"
                                                  strokeLinecap="round" stroke-width="1"/>
                                        </g>
                                    </svg>

                                </div>

                            </Row>
                        </Container>
                        <Container>
                            <Row>


                                <div className="modal-data d-flex">
                                    <ModalVideo channel='youtube' isOpen={open}
                                                videoId={videoThumb?.short_title}
                                                onClose={handleCloseModal}/>

                                </div>
                            </Row>
                        </Container>
                    </Modal.Body>
                </SimpleBar>
            </Modal>


        </StyledHomeVideo>
    );
};
const StyledHomeVideo = styled.section`
    background: #FFFFFF;

    .container-fluid {
        padding-left: 0;
    }

    .title {
        font-size: 40px;
        line-height: 48px;
        padding: 100px 0;
    }

    .right-title {
        display: flex;
        align-items: center;
    }

    .home-video {
        &__wrapper {
            position: relative;
            padding-top: calc(600 / 868 * 100%);
            overflow: hidden;
            cursor: pointer;

            a {
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                height: 100%;
                width: 100%;
                object-fit: cover;
                z-index: 2;
            }

            img {
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                height: 100%;
                width: 100%;
                object-fit: cover;
                z-index: 1;
                transform: scale(1.01);
                transition: transform 0.6s ease;
            }

            &__click {
                svg {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    z-index: 1;
                    transform: translate(-50%, -50%);
                    overflow: hidden;
                    border-radius: 50%;

                    circle {
                        transition: all 0.4s ease;
                    }

                    .video-icon-hover {
                        //cy: 30px;
                        opacity: 0;
                        transition: all 0.4s ease;
                    }
                }
            }

            &:hover {
                img {
                    transform: scale(1.1);
                }

                svg {
                    .video-icon-hover {
                        //cx: 75px;
                        opacity: 1;
                        r: 74px;
                        transition: all 0.4s ease;
                    }

                    circle {
                        stroke: #000000;
                        transition: all 0.4s ease;
                    }

                    path {
                        fill: #ffffff;
                        transition: all 0.4s ease;
                    }
                }
            }
        }
    }

    /* Tablet desktop :768px. */
    @media (min-width: 768px) and (max-width: 991px) {
        padding-bottom: 80px !important;
    }


    @media (max-width: 991px) {
        .container-fluid {
            padding-right: 0 !important;
        }

        .title {
            font-size: 32px;
            font-weight: 400;
            line-height: 36px;
            padding: 20px 15px;
        }

        .home-video {
            &__wrapper {
                padding-top: calc(260 / 375 * 100%);

                &__click {
                    svg {
                        height: 70px;
                        width: 70px;
                    }
                }
            }
        }
    }


    /* small mobile :320px. */
    @media (max-width: 767px) {
        padding-bottom: 80px !important;

        .row {
            flex-direction: column-reverse;
        }

        .gallery-slider__text__subtitle h3 {
            font-size: 32px !important;
            font-weight: 400 !important;
            line-height: 36px !important;
        }

        .title {
            font-size: 36px;
            font-weight: 400;
            line-height: 40px;
            padding: 80px 15px 60px 15px;
        }
    }
`;

export default HomeVideo;
