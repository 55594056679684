import React, {useEffect} from 'react';
import styled from "styled-components";
import {Link} from "react-router-dom";
import {HelmetProvider, Helmet} from 'react-helmet-async'
import InnerBanner from "../../components/InnerBanner";
import Overview from "../../components/about/OverviewCsr";
import LancoFoundation from "../../components/about/LancoFoundation";
import TestimonalSlider from "../../components/TestimonalSlider";
import {useDispatch, useSelector} from "react-redux";
import {apiEndPoints} from "../../api/network/apiEndPoints";
import {ApiParam as ApiParamKey} from "../../api/network/apiParams";
import {fetchTeam} from "../../api/redux/team";
import {fetchCsr} from "../../api/redux/csr";
import PageTestimonalSlider from "../../components/PageTestimonalSlider";
import GetInTouch from "../../components/GetInTouch";
import AboutComponent from "../../components/AboutComponent";
import Slide from "../../components/Slide";

const MyComponent = ({offset}) => {
    const dispath = useDispatch()

    // api call
    useEffect(() => {
        let api_url = apiEndPoints.SECTIONS;

        let api_params = {
            [ApiParamKey.type]: 'slug',
            [ApiParamKey.value]: 'csr',
            [ApiParamKey.get_section]: 'yes',
            [ApiParamKey.image]: 'yes',
            [ApiParamKey.post]: 'yes',
            [ApiParamKey.file]: 'no',
            [ApiParamKey.gallery]: 'no',
        }

        let params = {
            [ApiParamKey.image]: 'yes',
        }

        dispath(fetchCsr([api_url, api_params]))
    }, [])


    //Refactor Data

    const getData = useSelector((state) => state.csr);

    const csrBanner = getData?.posts?.data?.sections?.find(f => f?.section_data?.slug === 'csr-banner');
    const csrOverview = getData?.posts?.data?.sections?.find(f => f?.section_data?.slug === 'csr-overview');
    const csrActivity = getData?.posts?.data?.sections?.find(f => f?.section_data?.slug === 'our-activity');
    const csrFoundation = getData?.posts?.data?.sections?.find(f => f?.section_data?.slug === 'lanco-foundation');

    return (
        <HelmetProvider>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>{getData?.posts?.data?.page_data?.title ? getData?.posts?.data?.page_data?.title : 'Lanco Group'}</title>
                <meta name="description"
                      content={getData?.posts?.data?.page_data?.meta_desc ? getData?.posts?.data?.page_data?.meta_desc : ''}/>
                <meta property="og:title"
                      content={getData?.posts?.data?.page_data?.og_title ? `${getData?.posts?.data?.page_data?.og_title | 'Lanco Group'}` : ''}/>
                <meta property="og:subtitle"
                      content={getData?.posts?.data?.page_data?.og_title ? `${getData?.posts?.data?.page_data?.og_title | 'Lanco Group'}` : ''}/>
                <meta property="og:description"
                      content={getData?.posts?.data?.page_data?.og_desc ? getData?.posts?.data?.page_data?.og_desc : ''}/>
                <meta property="og:url" content={window?.location?.href}/>
                <meta property="og:image" content={'/images/static/logo.svg'}/>
                <meta property="og:type" content={"website"}/>
            </Helmet>

            <StyledComponent>
                <InnerBanner title={csrBanner?.section_data?.subtitle}
                             img={csrBanner?.images?.list?.[0]?.full_path}
                             text={csrBanner?.section_data?.description}/>
                <AboutComponent title={csrOverview?.section_data?.subtitle}
                                description={csrOverview?.section_data?.description}
                                img={csrOverview?.images?.list?.[0]?.full_path} offset={offset}/>
                <Slide hideBtn data={csrActivity?.images?.list} offset={offset}/>
                <LancoFoundation offset={offset} data={csrFoundation}/>
            </StyledComponent>
        </HelmetProvider>
    );
};

const StyledComponent = styled.section`

`;

export default MyComponent;
