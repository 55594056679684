import {createSlice, createAsyncThunk} from "@reduxjs/toolkit";
import {get} from "../../network/axiosServices";
import {fetchConcern} from "../concern";
// import {loaded} from "../global";


const initialState = {
    loading: false,
    posts: [],
    error: '',
}

export const fetchHome = createAsyncThunk("fetchHome", (params, {dispatch}) => {
    return get(params);
});

export const fetchConcernList = createAsyncThunk("fetchConcernList", (params) => {
    return get(params);
});

const postSlice = createSlice({
    name: 'home',
    initialState,
    extraReducers: builder => {
        builder.addCase(fetchHome.pending, (state) => {
            state.loading = true
            state.posts = []
            state.error = ''
        })
        builder.addCase(fetchHome.fulfilled, (state, action) => {
            state.loading = false
            state.posts = action.payload
            state.error = ''

        })
        builder.addCase(fetchHome.rejected, (state, action) => {
            state.loading = false
            state.posts = []
            state.error = action.error
        })

        builder.addCase(fetchConcernList.pending, (state) => {
            state.loading = true
            state.posts = []
            state.error = ''
        })
        builder.addCase(fetchConcernList.fulfilled, (state, action) => {
            state.loading = false
            state.posts = action.payload
            state.error = ''
        })
        builder.addCase(fetchConcernList.rejected, (state, action) => {
            state.loading = false
            state.posts = []
            state.error = action.error
        })
    }
})


export default postSlice.reducer
