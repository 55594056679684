import React, {useContext, useEffect, useState} from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import { TransitionProvider } from "../context/TransitionContext";
import Error from "../pages/404";
import TransitionComponent from "../components/TransitionComponent";
import Home from "../pages/home";
import OurStory from "../pages/ourStory";
import Mission from "../pages/mission";
import About from "../pages/about";
import Team from "../pages/about/team";
import Csr from "../pages/about/csr";
import BOD from "../pages/BOD";
import Career from "../pages/career";
import Contact from "../pages/contact";
import Concern from "../pages/concern";
import Updates from "../pages/updates";
import HomeUpdateDetail from "../pages/updates/single";

const Router = () => {
    const [offset, setOffset] = useState('90');
    useEffect(() => {
        const updateOffset = () => {
            if (window.innerWidth > 767) {
                const container = document.querySelector('.container');
                if (container) {
                    setOffset(container.offsetLeft + 15);
                }
            }
        };

        // Add an event listener for the DOMContentLoaded event
        document.addEventListener('DOMContentLoaded', updateOffset);

        // Add event listener to update offset on window resize
        window.addEventListener('resize', updateOffset);
        window.addEventListener('load', updateOffset);

        return () => {
            document.removeEventListener('DOMContentLoaded', updateOffset);
            window.removeEventListener('resize', updateOffset);
            window.removeEventListener('load', updateOffset);
        };
    }, []);

    return (
        <TransitionProvider>
            <Routes>
                <Route
                    exact
                    index
                    element={
                        <TransitionComponent>
                            <div>
                                <Home offset={offset} />
                            </div>
                        </TransitionComponent>
                    }
                />
                <Route
                    exact
                    path="/our-story"
                    element={
                        <TransitionComponent>
                            <OurStory offset={offset} />
                        </TransitionComponent>
                    }
                />
                <Route
                    exact
                    path="/mission-vision"
                    element={
                        <TransitionComponent>
                            <Mission offset={offset} />
                        </TransitionComponent>
                    }
                />
                <Route
                    exact
                    path="/about"
                    element={
                        <TransitionComponent>
                            <About offset={offset} />
                        </TransitionComponent>
                    }
                />
                <Route
                    exact
                    path="/team"
                    element={
                        <TransitionComponent>
                            <Team offset={offset} />
                        </TransitionComponent>
                    }
                />
                <Route
                    exact
                    path="/csr"
                    element={
                        <TransitionComponent>
                            <Csr  offset={offset} />
                        </TransitionComponent>
                    }
                />
                <Route
                    exact
                    path="/board-of-directors"
                    element={
                        <TransitionComponent>
                            <BOD offset={offset} />
                        </TransitionComponent>
                    }
                />
                <Route
                    exact
                    path="/career"
                    element={
                        <TransitionComponent>
                            <Career offset={offset} />
                        </TransitionComponent>
                    }
                />
                <Route
                    exact
                    path="/contact"
                    element={
                        <TransitionComponent>
                            <Contact offset={offset} />
                        </TransitionComponent>
                    }
                />
                <Route
                    exact
                    path="/concern/:slug"
                    element={
                        <TransitionComponent>
                            <Concern offset={offset} />
                        </TransitionComponent>
                    }
                />
                <Route
                    exact
                    path="/updates"
                    element={
                        <TransitionComponent>
                            <Updates offset={offset} />
                        </TransitionComponent>
                    }
                />
                <Route
                    exact
                    path="/updates/:slug"
                    element={
                        <TransitionComponent>
                            <HomeUpdateDetail offset={offset} />
                        </TransitionComponent>
                    }
                />
                {/* Other routes */}
                <Route path="*" element={<Error />} />
            </Routes>
        </TransitionProvider>
    );
};

export default Router;
