import React, {useState,useRef} from 'react';
import styled from "styled-components";
import {Col, Container, Row} from "react-bootstrap";
import {Img} from "../Img";
import SimpleBar from 'simplebar-react';
import ModalVideo from "react-modal-video";
import Modal from "react-bootstrap/Modal";
import Button from "../Button";
import ReactHtmlParser from "react-html-parser";
import {ImageParallax} from "../ImageParallax";
const HomeVideo = ({offset, data}) => {

    return (
        <StyledHomeVideo className={'pb-200'}>
            <Container fluid style={{paddingRight:offset+15+'px'}}>
                <Row>
                    <Col lg={7}  className='home-video'>
                        <h4 className={'title show-mobile split-up'}>
                            {ReactHtmlParser(data?.section_data?.subtitle)}
                        </h4>
                        <div className='home-video__wrapper'>
                            <ImageParallax src={data?.images?.list?.[0]?.full_path} />
                        </div>
                    </Col>

                    <Col className={'d-flex flex-column justify-content-center'} lg={{offset:1, span:4}} data-speed={"clamp(1.1)"}>
                        <h4 className={'title show-desktop split-up'}>
                            {ReactHtmlParser(data?.section_data?.subtitle)}
                        </h4>

                        <Button text={'Career'} src={'/career'} />
                    </Col>
                </Row>
            </Container>
        </StyledHomeVideo>
    );
};
const StyledHomeVideo = styled.section`
  background: #FFFFFF;
  padding-top: 160px;
  .show-mobile {
    display: none;
  }
  @media(max-width: 991px){
    padding-top: 120px;
  }
  
  .container-fluid {
    padding-left: 0;
  }
  
  .title {
    margin-bottom: 60px;
  }
  
  .home-video {
    &__wrapper {
      position: relative;
      padding-top: calc(380 / 375 * 100%);
      overflow: hidden;
      cursor: pointer;

      a {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        height: 100%;
        width: 100%;
        object-fit: cover;
        z-index: 2;
      }

      img {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        height: 100%;
        width: 100%;
        object-fit: cover;
        z-index: 1;
        transform: scale(1.01);
        transition: transform 0.6s ease;
      }
      
      //&:hover {
      //  img {
      //    transform: scale(1.1);
      //  }
      //}
    }
  }
  
  @media (max-width: 991px) {
    .container-fluid {
      padding-right: 0 !important;
    }

    .title {
      font-size: 24px;
      line-height: 36px;
      padding: 40px 15px;
      margin-bottom: 40px;
    }
    .dc-btn{
      padding: 0 15px;
    }
    
    .home-video {
      &__wrapper {
        padding-top: calc(260 / 375 * 100%);

        &__click {
          svg {
            height: 70px;
            width: 70px;
          }
        }
      }
    }
  }

  /* small mobile :320px. */
  @media (max-width: 767px) {
    padding: 80px 0;
    .home-video {
      &__wrapper {
        padding-top: calc(380 / 375 * 100%);
      }
    }
    .show-mobile {
      display: block!important;
    }
    .show-desktop {
      display: none;
    }
    .title {
      padding: 0px 15px;
      font-size: 36px!important;
      font-weight: 400;
      line-height: 40px!important;
    }
    .dc-btn {
      margin-top: 60px;
    }
  }
`;

export default HomeVideo;
