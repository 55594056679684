import React, {lazy, useEffect, useState} from 'react';
import styled from "styled-components";
import {Container, Row, Col} from "react-bootstrap";
import {Img} from "../Img";
import {hover, paragraph, text} from "../../styles/globalStyleVars";
// import {gsap, TimelineLite} from "gsap";
import 'simplebar-react/dist/simplebar.min.css';
import ReactHtmlParser from "react-html-parser";
import {Link} from "react-router-dom";
import ResponsiveMasonry from "react-responsive-masonry/lib/ResponsiveMasonry";
import Masonry from "react-responsive-masonry/lib/Masonry";
import {gsap} from "gsap";
import {ScrollTrigger} from "gsap/ScrollTrigger";


const CoreValues = ({offset, data, id}) => {
    const valuesMasonry = data?.posts?.list;

    const handleParallax = () => {
        if (window.innerWidth > 767) {
            valuesMasonry?.forEach((item, index) => {
                const itemElement = document.querySelector(`.single:nth-child(${index + 1})`);

                if (itemElement) {
                    gsap.to(itemElement, {
                        scrollTrigger: {
                            trigger: itemElement,
                            start: 'top bottom', // Adjust these values as needed
                            end: 'bottom top', // Adjust these values as needed
                            scrub: true, // Enables smooth scrolling effect
                        },
                        y: -100, // Adjust the parallax effect amount

                        // Use CSS transform to optimize performance
                        ease: 'none',
                        force3D: true, // Force GPU acceleration
                    });
                }
            });
        }
    };


    useEffect(() => {
        // Recalculate the Masonry layout when data changes
        if (valuesMasonry && valuesMasonry.length > 0) {
            setTimeout(() => {
                window.dispatchEvent(new Event('resize'));
            }, 100);
        }

        // Initialize GSAP animation
        gsap.registerPlugin(ScrollTrigger);

        handleParallax();

        // Add an event listener for window resize
        window.addEventListener("resize", handleParallax);

        // Clean up the event listener when the component unmounts
        return () => {
            window.removeEventListener("resize", handleParallax);
        };
    }, [valuesMasonry]);

    return (

        <StyledComponent offset={offset} id={id} className={'reel-values pt-200 pb-200'}>
            <Container>
                <Row>
                    <Col lg={3} className={'reel-values__title'}>
                        <h1 className={'core parallax'}>{ReactHtmlParser('CORE')}</h1>
                        <h1 className={'values parallax'}>{ReactHtmlParser('VALUES')}</h1>
                    </Col>

                    <Col lg={{span: 8, offset: 1}} className={'reel-values__boxes'}>
                        <ResponsiveMasonry
                            columnsCountBreakPoints={{200: 1, 992: 2}}
                        >

                            {
                                valuesMasonry && valuesMasonry.length > 0 &&
                                <Masonry>
                                    {valuesMasonry.length > 0 && valuesMasonry?.map((item, index) => (
                                        <div className={'single'} key={item?.data?.id}>
                                            <div className={'link'}>
                                                <div className="reel-values__boxes__img">
                                                    <div className="">
                                                        <Img src={item?.images?.[0]?.full_path}/>
                                                    </div>
                                                </div>

                                                <h3>{index + 1}</h3>
                                                <h5>{item?.data?.title}</h5>
                                                <p>{ReactHtmlParser(item?.data?.description)}</p>
                                            </div>
                                        </div>
                                    ))}
                                </Masonry>
                            }
                        </ResponsiveMasonry>
                    </Col>
                </Row>
            </Container>
        </StyledComponent>

    )
};

const StyledComponent = styled.section`
  background-color: #000000;
  position: relative;

  .single {
    transform: translateZ(0);
    will-change: transform;
    -webkit-backface-visibility: hidden;

  }

  .reel-values__title {
    position: relative;

    h1.core {
      position: absolute;
      content: '';
      left: -100px;
      top: 335px;
      transform: rotate(-90deg);
      font-size: 120px;
      font-weight: 400;
      line-height: 100px;
      color: #F7F3F0;
    }

    h1.values {
      position: absolute;
      content: '';
      left: -40px;
      transform: rotate(-90deg);
      top: 200px;
      font-size: 120px;
      font-weight: 400;
      line-height: 100px;
      color: #F7F3F0;
    }
  }

  .reel-values__title_mb {

  }

  .reel-values__boxes {
    div {
      div {
        div {
          // &:nth-child(odd):not(.single) {
            //   padding-right: ${props => props.offset / 2 || '35'}px;
          // }

          &:nth-child(even):not(.single) {
            padding-left: ${props => props.offset / 2 || '35'}px;
            padding-top: 245px;
          }

        }
      }
    }

    .single {
      padding-top: 60px;
      margin-bottom: 80px;

      &:nth-last-child(1) {
        margin-bottom: 0;
      }

      .link {
        position: relative;
      }
    }

    h3 {
      font-size: 160px;
      line-height: 160px;
      color: #FFFFFF;
      position: absolute;
      top: 0;
      left: 40px;
      z-index: 2;
      transform: translateY(-50%);
      transition: color 0.3s ease;
      font-family: ${paragraph};
    }

    h5 {
      color: #F7F3F0;
      margin: 20px 0 24px;
      transition: color 0.3s ease;
    }

    p {
      color: #F7F3F0;
      margin: 0;
    }


    &__img {
      padding-top: calc(370 / 370 * 100%) !important;
      position: relative;
      overflow: hidden;

      .global-image {
        overflow: hidden;

        img {
          transform: scale(1.01);
          transition: 0.8s ease;
        }
      }

      &:hover {
        .global-image img {
          transform: scale(1.06) !important;
        }
      }

      img {
        transition: filter .5s ease;
        opacity: 1;
      }

      &:hover {
        img {
          //filter: brightness(60%);
        }
      }

      svg {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        margin: auto;
        z-index: 3;
        height: 65px;
      }

    }

  }

  /* Tablet desktop :768px. */
  @media (min-width: 768px) and (max-width: 991px) {
    padding-top: 80px !important;
    padding-bottom: 120px !important;
    .reel-values__boxes {
      margin-top: 60px;
    }

    .reel-values__boxes .single {
      margin-bottom: 40px;
    }

    .reel-values__boxes h5 {
      margin: 20px 0px 20px;
    }
  }

  @media (max-width: 991px) {
    .reel-values__title {
      z-index: 1;

      h1.core {
        position: unset;
        content: '';
        left: 0px;
        top: 0px;
        transform: unset;
        font-size: 60px;
        font-weight: 400;
        line-height: 60px;
        color: #F7F3F0;
      }

      h1.values {
        position: unset;
        content: '';
        left: 0px;
        top: 0px;
        transform: unset;
        font-size: 60px;
        font-weight: 400;
        line-height: 60px;
        color: #F7F3F0;
      }
    }

    .reel-values__boxes {
      div {
        div {
          div {
            &:nth-child(odd):not(.single) {
              padding-right: 0;
            }

            &:nth-child(even):not(.single) {
              padding-left: 0;
              padding-top: 0;
            }
          }
        }
      }

      p {
        padding: 0;
      }

    }
  }

  /* small mobile :320px. */
  @media (max-width: 767px) {
    padding-top: 80px !important;
    padding-bottom: 120px !important;
    .reel-values__boxes {
      margin-top: 60px;
    }

    .reel-values__boxes .single {
      margin-bottom: 40px;
    }

    .reel-values__boxes h5 {
      margin: 20px 0px 20px;
    }
  }


`;

export default CoreValues;
