import React, {useEffect} from "react";
import styled from "styled-components";
import {Helmet, HelmetProvider} from "react-helmet-async";
import InnerBanner from "../../components/InnerBanner";
import MissionVision from "../../components/MissionVision";
import AboutComponent from "../../components/AboutComponent";
import {useDispatch, useSelector} from "react-redux";
import {apiEndPoints} from "../../api/network/apiEndPoints";
import {ApiParam as ApiParamKey} from "../../api/network/apiParams";
import {fetchMission} from "../../api/redux/mission";

const Home = ({offset}) => {
    const dispath = useDispatch()

    // api call
    useEffect(() => {
        let api_url = apiEndPoints.SECTIONS;

        let api_params = {
            [ApiParamKey.type]: 'slug',
            [ApiParamKey.value]: 'mission-vision',
            [ApiParamKey.get_section]: 'yes',
            [ApiParamKey.image]: 'yes',
            [ApiParamKey.post]: 'yes',
            [ApiParamKey.file]: 'no',
            [ApiParamKey.gallery]: 'no',
        }

        dispath(fetchMission([api_url, api_params]))
    }, [])

    //Refactor Data

    const getData = useSelector((state) => state.mission);

    const banner = getData?.posts?.data?.sections?.find(f => f?.section_data?.slug === 'mission-banner');
    const mission = getData?.posts?.data?.sections?.find(f => f?.section_data?.slug === 'mission');
    const vision = getData?.posts?.data?.sections?.find(f => f?.section_data?.slug === 'vision');
    const values = getData?.posts?.data?.sections?.find(f => f?.section_data?.slug === 'values');
    return (
        <HelmetProvider>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>{getData?.posts?.data?.page_data?.title ? getData?.posts?.data?.page_data?.title : 'Lanco Group'}</title>
                <meta name="description"
                      content={getData?.posts?.data?.page_data?.meta_desc ? getData?.posts?.data?.page_data?.meta_desc : ''}/>
                <meta property="og:title"
                      content={getData?.posts?.data?.page_data?.og_title ? `${getData?.posts?.data?.page_data?.og_title | 'Lanco Group'}` : ''}/>
                <meta property="og:subtitle"
                      content={getData?.posts?.data?.page_data?.og_title ? `${getData?.posts?.data?.page_data?.og_title | 'Lanco Group'}` : ''}/>
                <meta property="og:description"
                      content={getData?.posts?.data?.page_data?.og_desc ? getData?.posts?.data?.page_data?.og_desc : ''}/>
                <meta property="og:url" content={window?.location?.href}/>
                <meta property="og:image" content={'/images/static/logo.svg'}/>
                <meta property="og:type" content={"website"}/>
            </Helmet>
            <StyledSection>
                <InnerBanner title={banner?.section_data?.subtitle}
                             img={banner?.images?.list?.[0]?.full_path}
                             text={banner?.section_data?.description}/>
                <MissionVision mission={mission} vision={vision}/>
                <AboutComponent title={values?.section_data?.subtitle} description={values?.section_data?.description}
                                img={values?.images?.list?.[0]?.full_path} showBtn black offset={offset}/>
            </StyledSection>
        </HelmetProvider>
    );
};

const StyledSection = styled.section``;
export default Home;
