import React from 'react';
import styled from "styled-components";
import {Container, Row, Col} from "react-bootstrap";
import {Img} from "./Img";
import ReactHtmlParser from "react-html-parser";
import {ImageParallax} from "./ImageParallax";

const MyComponent = ({img, title, leftText, rightText}) => {
    return (
        <StyledComponent className={'pt-120 pb-120'}>
            <ImageParallax src={img} alt={title}/>
            <Container>
                <Row>
                    <Col md={12}>
                        <h3>{title}</h3>
                    </Col>
                    <Col md={6}>
                        <p>{ReactHtmlParser(leftText)}</p>
                    </Col>
                    <Col md={6}>
                        <p>{ReactHtmlParser(rightText)}</p>
                    </Col>
                </Row>
            </Container>
        </StyledComponent>
    );
};

const StyledComponent = styled.section`
    position: relative;
    overflow: hidden;

    h3 {
        margin-bottom: 40px;
    }
    h3, p {
        color: #fff;
    }
`;

export default MyComponent;
