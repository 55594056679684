import React, {useEffect} from 'react';
import styled from "styled-components";
import {Container, Row, Col, Form} from "react-bootstrap";
import Button from "./Button";
import {useForm} from "react-hook-form";
import {useDispatch, useSelector} from "react-redux";
import {toast} from "react-toastify";
import {apiEndPoints} from "../api/network/apiEndPoints";
import {postForm} from "../api/redux/contact";


const MyComponent = () => {
    const dispatch = useDispatch();
    const responseData = useSelector(state => state.contact);
    const {register, handleSubmit, formState, reset} = useForm({mode: 'all'});

    //--- form submit
    const success = (msg) => toast.success(msg, {
        position: "top-right",
        autoClose: 4000,
        closeOnClick: true,
        progress: undefined,

    });

    const error = (msg) => toast.error(msg, {
        position: "top-right",
        autoClose: 4000,
        closeOnClick: true,
        progress: undefined,

    });

    const onSubmit = (e) => {
        let api_services = apiEndPoints.ADDSUBSCRIP;

        var formData = new FormData();
        formData.append('email', e?.email);
        formData.append('name', e?.phone);
        formData.append("form_id", "subscription-form");

        if (e.email !== '') {
            dispatch(postForm([api_services, formData]));
            success("Thank you for Subscribing!");
            reset();

        }
    };

    let count = 0;
    const onError = (errors) => {
        Object.values(errors).forEach((error) => {
            count++;
        });
        if (count > 0) {
            toast.error('please fill out the correct inputs');
        }
        count = 0;
    };


    return (
        <StyledComponent>
            <Container>
                <Form  onSubmit={handleSubmit(onSubmit, onError)}>
                    <Row>
                        <Col sm={2}>
                            <h4>Subscribe</h4>
                        </Col>
                        <Col sm={{span: 3, offset: 2}}>
                            <Form.Group className="mb-3">
                                <Form.Control
                                    className={
                                        formState?.errors?.name?.message
                                            ? "has-error form-control-lg"
                                            : "form-control-lg"
                                    }
                                    {...register("name", {
                                        required: "Name is required",
                                    })}
                                    type="text"
                                    placeholder="Enter your name*"/>
                            </Form.Group>
                        </Col>
                        <Col sm={{span: 3}}>
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Control
                                    className={formState?.errors?.email?.message ? 'has-error form-control-lg' : 'form-control-lg'}
                                    {...register("email", {
                                        required: {
                                            value: true,
                                            message: 'please enter your email'
                                        },
                                        pattern: {
                                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                            message: 'please enter a valid email address'
                                        }
                                    })}
                                    type="email" placeholder="Email*"/>
                            </Form.Group>
                        </Col>
                        <Col sm={2}>
                            <div className="dc-btn-submit" onClick={handleSubmit(onSubmit, onError)}>
                                <Button text={'SUBMIT'} borderColor={'#000000'} color={'#000000'}
                                        hoverBackground={'#000000'}/>
                            </div>
                        </Col>
                    </Row>
                </Form>
            </Container>
        </StyledComponent>
    );
};

const StyledComponent = styled.section`
    background: #F5F5F5;
    padding: 60px 0;
    display: flex;
    align-items: center;

    h4 {
        color: #000000;
    }

    input {
        border-top: 0px;
        border-right: 0;
        border-left: 0px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.5) !important;
        background: transparent;
        color: #000000;
        padding-left: unset;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;

        &:focus {
            border-bottom-color: #000000; /* Set the border-bottom color when focused */
            background: transparent;
            color: #000000;
        }

        &::placeholder {
            color: rgba(0, 0, 0, 0.5);
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
        }
    }

    @media (max-width: 767px) {
        padding-top: 40px;
        padding-bottom: 60px;
        h4 {
            padding-bottom: 40px;
            font-size: 40px;
            font-weight: 400;
            line-height: 40px;
        }

        input {
            margin-bottom: 30px;

            &:last-child {
                margin-bottom: 40px;
            }
        }
    }
`;

export default MyComponent;
