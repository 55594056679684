import {createSlice, createAsyncThunk} from "@reduxjs/toolkit";
import axios from "axios";
import {get} from "../../network/axiosServices";


const initialState = {
    loading: false,
    posts: [],
    error: '',
    detail: [],
    detailLoading: false,
    detailError: ''
}

export const fetchCareer = createAsyncThunk("fetchCareer", (params) => {
    return get(params);
});

export const fetchCareerDetails = createAsyncThunk("fetchCareerDetails", (params) => {
    return get(params);
});


const postSlice = createSlice({
    name: 'career',
    initialState,
    extraReducers: builder => {
        builder.addCase(fetchCareer.pending, (state) => {
            state.loading = true
            state.posts = []
            state.error = ''
        })
        builder.addCase(fetchCareer.fulfilled, (state, action) => {
            state.loading = false
            state.posts = action.payload
            state.error = ''
        })
        builder.addCase(fetchCareer.rejected, (state, action) => {
            state.loading = false
            state.posts = []
            state.error = action.error
        })
        builder.addCase(fetchCareerDetails.pending, (state, action) => {
            state.detailLoading = true
            state.detail = []
            state.detailError = ''
        })
        builder.addCase(fetchCareerDetails.fulfilled, (state, action) => {
            state.detailLoading = false
            state.detail = action.payload
            state.detailError = ''
        })
        builder.addCase(fetchCareerDetails.rejected, (state, action) => {
            state.detailLoading = false
            state.detail = []
            state.detailError = action.error
        })
    }
})


export default postSlice.reducer
