import React, {useEffect} from "react";
import styled from "styled-components";
import {Helmet, HelmetProvider} from "react-helmet-async";
import InnerBanner from "../../components/InnerBanner";
import AboutComponent from "../../components/AboutComponent";
import WhoWeAre from "../../components/WhoWeAre";
import Aproch from "../../components/Aproch";
import {useDispatch, useSelector} from "react-redux";
import {apiEndPoints} from "../../api/network/apiEndPoints";
import {ApiParam as ApiParamKey} from "../../api/network/apiParams";
import {fetchOurStory} from "../../api/redux/ourStory";
import Approach from "../../components/Approach";

const Home = ({offset}) => {
    const dispath = useDispatch()

    // api call
    useEffect(() => {
        let api_url = apiEndPoints.SECTIONS;

        let api_params = {
            [ApiParamKey.type]: 'slug',
            [ApiParamKey.value]: 'our-story',
            [ApiParamKey.get_section]: 'yes',
            [ApiParamKey.image]: 'yes',
            [ApiParamKey.post]: 'yes',
            [ApiParamKey.file]: 'no',
            [ApiParamKey.gallery]: 'no',
        }

        dispath(fetchOurStory([api_url, api_params]))
    }, [])

    //Refactor Data

    const getData = useSelector((state) => state.ourStory);
    const banner = getData?.posts?.data?.sections?.find(f => f?.section_data?.slug === 'banner');
    const about = getData?.posts?.data?.sections?.find(f => f?.section_data?.slug === 'about');
    const whoWeAre = getData?.posts?.data?.sections?.find(f => f?.section_data?.slug === 'who-we-are');
    const aproach = getData?.posts?.data?.sections?.find(f => f?.section_data?.slug === 'our-approach');

    return (
        <HelmetProvider>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>{getData?.posts?.data?.page_data?.title ? getData?.posts?.data?.page_data?.title : 'Lanco Group'}</title>
                <meta name="description"
                      content={getData?.posts?.data?.page_data?.meta_desc ? getData?.posts?.data?.page_data?.meta_desc : ''}/>
                <meta property="og:title"
                      content={getData?.posts?.data?.page_data?.og_title ? `${getData?.posts?.data?.page_data?.og_title | 'Lanco Group'}` : ''}/>
                <meta property="og:subtitle"
                      content={getData?.posts?.data?.page_data?.og_title ? `${getData?.posts?.data?.page_data?.og_title | 'Lanco Group'}` : ''}/>
                <meta property="og:description"
                      content={getData?.posts?.data?.page_data?.og_desc ? getData?.posts?.data?.page_data?.og_desc : ''}/>
                <meta property="og:url" content={window?.location?.href}/>
                <meta property="og:image" content={'/images/static/logo.svg'}/>
                <meta property="og:type" content={"website"}/>
            </Helmet>
            <StyledSection>
                {
                    banner &&
                    <InnerBanner img={banner?.images?.list?.[0]?.full_path} title={banner?.section_data?.subtitle}
                                 text={banner?.section_data?.short_desc}/>
                }
                {
                    about && <AboutComponent showBtn title={about?.section_data?.subtitle}
                                             description={about?.section_data?.description}
                                             img={about?.images?.list?.[0]?.full_path} offset={offset}/>
                }

                <WhoWeAre title={whoWeAre?.section_data?.subtitle} desc={whoWeAre?.section_data?.description}
                          img={whoWeAre?.images?.list?.[0]?.full_path}/>
                <Approach img={aproach?.images?.list?.[0]?.full_path} title={aproach?.section_data?.subtitle}
                          leftText={aproach?.section_data?.short_desc}
                          rightText={aproach?.section_data?.description}/>
            </StyledSection>
        </HelmetProvider>
    );
};

const StyledSection = styled.section``;
export default Home;
