// reducers/index.js
import { combineReducers } from "redux";
import loaderReducer from "./../loader/index";
import pageReducer1 from "./../home/index";
import pageReducer2 from './../about/index';
import pageReducer3 from './../contact/index';
import pageReducer4 from './../team/index';
import pageReducer5 from './../csr/index';
import pageReducer6 from './../concern/index';
import pageReducer7 from './../update/index';
import pageReducer8 from './../career/index';
import pageReducer9 from './../ourStory/index';
import pageReducer10 from './../mission/index';
import pageReducer11 from './../BOD/index';
import pageReducer12 from './../footer/index';

const rootReducer = combineReducers({
  loader: loaderReducer,
  page1: pageReducer1,
  page2: pageReducer2,
  page3: pageReducer3,
  page4: pageReducer4,
  page5: pageReducer5,
  page6: pageReducer6,
  page7: pageReducer7,
  page8: pageReducer8,
  page9: pageReducer9,
  page10: pageReducer10,
  page11: pageReducer11,
  page12: pageReducer12,
  // Add other page reducers here
});

export default rootReducer;
