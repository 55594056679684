import React from 'react';
import styled from "styled-components";
import {Container, Row, Col} from "react-bootstrap";
import {Img} from "./Img";
import ReactHtmlParser from "react-html-parser";

const MyComponent = ({img, title, leftText, rightText}) => {
    return (
        <StyledComponent>
            <div className="image">
                <Img src={img}/>
            </div>
            <Container className={'text'}>
                <Row>
                    <Col sm={12}>
                        <h3>{title}</h3>
                    </Col>
                    <Col sm={6}>
                        <p>{ReactHtmlParser(leftText)}</p>
                    </Col>
                    <Col sm={6}>
                        <p>{ReactHtmlParser(rightText)}</p>
                    </Col>
                </Row>
            </Container>
        </StyledComponent>
    );
};

const StyledComponent = styled.section`
  position: relative;
  background: #ffffff;
    overflow: hidden;

  .image {
    position: relative;
    padding-top: calc(500 / 1366 * 100%);
  }

  h3 {
    margin-bottom: 40px;
  }
    .container {
        position: absolute;
        margin: auto;
        top: 80px;
        left: 0;
        right: 0;
        bottom: 80px;
        z-index: 2;
        h3, p {
            color: #fff;
        }
    }

  /* Tablet desktop :768px. */
  @media (min-width: 768px) and (max-width: 991px) {


  }


  /* small mobile :320px. */
  @media (max-width: 767px) {

    .image {
      position: relative;
      padding-top: calc(100%);
    }

    .text {
      padding: 0px 15px;
      display: flex;
      align-items: center;
    }
  }
`;

export default MyComponent;
