import React from 'react';
import styled from "styled-components";
import {Container, Row, Col, Accordion} from "react-bootstrap";
import {BsChevronDown} from "react-icons/bs";

import reactHtmlParser from "react-html-parser";
import {paragraph, title} from "../../styles/globalStyleVars";

const Address = ({contact_info, office_locations, data, corporateData, salesOfficeData}) => {
    const title = data?.section_data?.title;
    const corporateLocation = corporateData?.posts?.list
    const salesOfficeDataLocation = salesOfficeData?.posts?.list

    console.log(salesOfficeDataLocation)

    return (
        <StyledComponent className='pt-120 pb-120'>
            <Container>
                <Row>
                    <Col md={12}>
                        <h3>{reactHtmlParser(title)}</h3>
                    </Col>
                </Row>


                <Row className="accordion-row">
                    <Col md={4}>
                        <h5 className={'address-title'}>{reactHtmlParser('Corporate office')}</h5>
                    </Col>

                    <Col md={{span: 7, offset: 1}}>
                        <Accordion defaultActiveKey="0">
                            {
                                corporateLocation?.map((item, index) => {
                                    console.log(item)
                                    return (
                                        <Accordion.Item key={index} eventKey={`${index}`}>
                                            <Accordion.Header>
                                                {reactHtmlParser(item?.data?.title)}<span><BsChevronDown/></span>
                                            </Accordion.Header>
                                            <Accordion.Body>
                                                {item?.data?.description && reactHtmlParser(item?.data?.description)}
                                                {
                                                    item?.images?.[0]?.full_path && <div className="map">
                                                        <a href={item?.images?.[0]?.short_title} target='_blank'></a>
                                                        <img src={item?.images?.[0]?.full_path} alt=""/>
                                                    </div>
                                                }

                                            </Accordion.Body>
                                        </Accordion.Item>
                                    )
                                })
                            }
                        </Accordion>
                    </Col>
                </Row>


                <Row className="accordion-row">
                    <Col md={4}>
                        <h5 className={'address-title'}>{reactHtmlParser('Sales office')}</h5>
                    </Col>
                    <Col md={{span: 7, offset: 1}}>
                        <Accordion>
                            {
                                salesOfficeDataLocation?.map((item, index) => {
                                    console.log(item)
                                    return (
                                        <Accordion.Item key={index} eventKey={`${index}`}>
                                            <Accordion.Header>
                                                {reactHtmlParser(item?.data?.title)}<span><BsChevronDown/></span>
                                            </Accordion.Header>
                                            <Accordion.Body>
                                                {item?.data?.description && reactHtmlParser(item?.data?.description)}
                                                {
                                                    item?.images?.[0]?.full_path && <div className="map">
                                                        <a href={item?.images?.[0]?.short_title} target='_blank'></a>
                                                        <img src={item?.images?.[0]?.full_path} alt=""/>
                                                    </div>
                                                }
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    )
                                })
                            }
                        </Accordion>
                    </Col>
                </Row>

            </Container>
        </StyledComponent>
    );
};

const StyledComponent = styled.section`
  //background: #F5F5F5;
 

  .accordion {
    opacity: 1 !important;
    transform: translate(0px, 0px) !important;
    transition: height 0.7s cubic-bezier(0.4, 0, 0, 1);
  }

  h3 {
    margin: 0 0 80px 0;
  }

  a:hover {
    color: unset!important;
  }

  .address-title {
    font-weight: 400;
  }

  .info {
    margin: 0 0 40px 0;

    a {
      color: #000000;
    }
  }

  .info-title {
    font-size: 20px;
    font-weight: 400;
    margin: 0 0 10px 0;
    color: rgba(22, 31, 19, 0.5);

  }

  .info-desc {
    margin: 0;
    color: #000000;
  }

  h4 {
    font-family: ${paragraph};
  }

  .accordion-header {
    position: relative;
    font-size: 20px;
    line-height: 28px;
    font-weight: 400;
    color: #000000;
    margin: 0 0 40px 0;
    font-family: ${paragraph};
    border-bottom: 1px solid #000000;
    transition: height 0.7s cubic-bezier(0.4, 0, 0, 1);

    button {
      border: none;
      background-color: transparent;
      padding-left: 0;
      padding-bottom: 20px;
      padding-right: 35px;
      color: #000000;
      font-size: 20px;
      line-height: 28px;
      font-weight: 400;
      border-bottom: 1px solid #000000;
      width: 100%;
      text-align: left;
      font-family: ${paragraph};
      transition: height 0.7s cubic-bezier(0.4, 0, 0, 1), font-weight 0.7s cubic-bezier(0.4, 0, 0, 1);



      &.collapsed {
        color: #000000;
        border-color: rgba(22, 31, 19, 0.2);
        transition: height 0.7s cubic-bezier(0.4, 0, 0, 1);

        span {
          background-color: transparent;
          border: 1px solid #000000;

          svg {
            transform: rotate(0deg);
            fill: #000000;
          }

          &:after {
            background-color: #000000 !important;
          }
        }
      }
    }

    span {
      position: absolute;
      height: 28px;
      width: 28px;
      border-radius: 50%;
      color: #ffffff;
      background-color: #000000;
      right: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      bottom: 20px;

      &:after {
        height: 0;
        width: 0;
        background-color: #000000;
        border-radius: 50%;
        opacity: 0;
        transition: height 0.7s cubic-bezier(0.4, 0, 0, 1);
        content: '';
        position: absolute;
      }

      svg {
        font-size: 13px;
        z-index: 2;
        transform: rotate(180deg);
      }

    }

    &:hover {
      span {
        &:after {
          height: 100%;
          width: 100%;
          opacity: 1;
        }
      }

      button {
        color: #000000;
        border-color: #000000;

        span {
          svg {
            fill: #FFFFFF;
          }
        }
      }
    }
  }
  
  

  .accordion-header button.collapsed {
    border-bottom: 1px solid #000000;
  }

  .accordion-header button {
    border-bottom: 1px solid #000000;
  }

  p.info-desc:hover a {
    color: rgba(22, 31, 19, 0.5)!important;
  }

  .accordion-body {
    .map {
      margin-bottom: 40px;
      position: relative;
      padding-top: calc(420 / 670 * 100%);

      a {
        position: absolute;
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
        object-fit: cover;
        z-index: 2;
        cursor: pointer;
      }

      img {
        position: absolute;
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
        object-fit: cover;
        z-index: 1;
      }
    }
  }


  /* Tablet desktop :768px. */
  @media (min-width: 768px) and (max-width: 991px) {
    padding-top: 80px;
    h3 {
      margin: 0px 0px 40px;
    }

    .accordion-header {
      margin-bottom: 40px;
    }
  }


  @media (max-width: 767px) {
    padding-top: 80px;
    padding-bottom: 120px;
    h3 {
      margin: 0px 0px 40px;
    }

    .address-title {
      font-weight: 400;
      margin-bottom: 40px;
    }

    .accordion-header {
      border-bottom: 1px solid rgba(22, 31, 19);
      margin-bottom: 60px;
    }

    .accordion-header button {
      border-bottom: 1px solid #000000;
    }

    .accordion-header {
      margin-bottom: 40px;
    }

    .accordion-header:last-child {
      margin: 0;
    }

    .accordion-row {

      &:nth-last-child(1) {
        .accordion-item {
          &:nth-last-child(1) {
            .accordion-body {
              .map {
                margin: 0;
              }
            }
          }
        }
      }

      .col-md-2, .col-md-9 {
        padding: 0;
      }
    }

    .accordion {
      .accordion-item {
        &:nth-last-child(1) {
          .accordion-header {
            //margin: 0;
          }
        }
      }
    }

    .accordion-body {
    }
  }



`;

export default Address;
