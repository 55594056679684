import {configureStore} from "@reduxjs/toolkit";
import postReducer from '../redux/post'
import globalReducer from '../redux/global'
import homeReducer from '../redux/home'
import aboutReducer from '../redux/about'
import contactReducer from '../redux/contact'
import teamReducer from '../redux/team'
import csrReducer from '../redux/csr'
import concernReducer from "../redux/concern";
import updateReducer from "../redux/update";
import careerReducer from "../redux/career";
import ourStoryReducer from "../redux/ourStory";
import missionReducer from "../redux/mission";
import bodReducer from "../redux/BOD";
import footerReducer from "../redux/footer";
import loaderReducer from "../redux/loader";
import rootReducer from "../redux/root";


const store = configureStore({
    reducer: {
        postReducer,
        globalReducer,
        home : homeReducer,
        about : aboutReducer,
        team : teamReducer,
        csr : csrReducer,
        contact: contactReducer,
        concern: concernReducer,
        update: updateReducer,
        career: careerReducer,
        ourStory: ourStoryReducer,
        mission: missionReducer,
        bod: bodReducer,
        footer: footerReducer,
        loaderReducer,
        rootReducer,
    },
    // middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(logger)
})

export default store

