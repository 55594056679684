import React, {useEffect} from 'react';
import styled from "styled-components";
import {Link} from "react-router-dom";
import {HelmetProvider, Helmet} from 'react-helmet-async'
import CoreValues from "../../components/about/CoreValues";
import Overview from "../../components/about/Overview";
import ChairPerson from "../../components/about/ChairPerson";
import InnerBanner from "../../components/InnerBanner";
import {useDispatch, useSelector} from "react-redux";
import {apiEndPoints} from "../../api/network/apiEndPoints";
import {fetchAbout} from "../../api/redux/about";
import {ApiParam as ApiParamKey} from "../../api/network/apiParams";




const MyComponent = ({offset}) => {

    const dispath = useDispatch()

    // api call
    useEffect(() => {
        let api_url = apiEndPoints.SECTIONS;

        let api_params = {
            [ApiParamKey.type]: 'slug',
            [ApiParamKey.value]: 'about-us',
            [ApiParamKey.get_section]: 'yes',
            [ApiParamKey.image]: 'yes',
            [ApiParamKey.post]: 'yes',
            [ApiParamKey.file]: 'no',
            [ApiParamKey.gallery]: 'no',
        }

        let params = {
            [ApiParamKey.image]: 'yes',
        }

        dispath(fetchAbout([api_url, api_params]))
    }, [])




    //Refactor Data

    const getData = useSelector((state) => state.about);

    const aboutBanner = getData?.posts?.data?.sections?.find(f => f?.section_data?.slug === 'about-us-banner');
    const aboutOverview = getData?.posts?.data?.sections?.find(f => f?.section_data?.slug === 'about-overview');
    const coreValues = getData?.posts?.data?.sections?.find(f => f?.section_data?.slug === 'core-values');
    const aboutManagement = getData?.posts?.data?.sections?.find(f => f?.section_data?.slug === 'management-message');


    // console.log(aboutBanner?.section_data?.description)


    return (
        <HelmetProvider>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>{getData?.posts?.data?.page_data?.title ? getData?.posts?.data?.page_data?.title : 'Lanco Group'}</title>
                <meta name="description"
                      content={getData?.posts?.data?.page_data?.meta_desc ? getData?.posts?.data?.page_data?.meta_desc : ''}/>
                <meta property="og:title"
                      content={getData?.posts?.data?.page_data?.og_title ? `${getData?.posts?.data?.page_data?.og_title | 'Lanco Group'}` : ''}/>
                <meta property="og:subtitle"
                      content={getData?.posts?.data?.page_data?.og_title ? `${getData?.posts?.data?.page_data?.og_title | 'Lanco Group'}` : ''}/>
                <meta property="og:description"
                      content={getData?.posts?.data?.page_data?.og_desc ? getData?.posts?.data?.page_data?.og_desc : ''}/>
                <meta property="og:url" content={window?.location?.href}/>
                <meta property="og:image" content={'/images/static/logo.svg'}/>
                <meta property="og:type" content={"website"}/>
            </Helmet>

            <StyledComponent>
                <InnerBanner title={aboutBanner?.section_data?.subtitle}
                             img={aboutBanner?.images?.list?.[0]?.full_path}
                             text={aboutBanner?.section_data?.description}/>
                <Overview offset={offset} data={aboutOverview}/>
                <CoreValues offset={offset} data={coreValues}/>
                <ChairPerson data={aboutManagement}/>
            </StyledComponent>
        </HelmetProvider>
    );
};

const StyledComponent = styled.section`

`;

export default MyComponent;
