import React from 'react';
import styled from "styled-components";
import {Container, Row, Col} from "react-bootstrap";
import Button from "./Button";
import {Img} from "./Img";
import reactHtmlParser from "react-html-parser";
import {ImageParallax} from "./ImageParallax";

const AboutComponent = ({offset, title, description, img, black, showBtn}) => {
    return (
        <StyledComponent black={black} offset={offset}>
            <Row>
                <Col lg={5} className={'text-wrap'} md={12}>
                    <div className="text">
                        {title && <h3 className={'split-up'}>{title}</h3>}
                        {description && <p>{reactHtmlParser(description)}</p>}
                    </div>
                </Col>
                <Col lg={{offset: 1, span: 6}} md={12}>
                    <div className="image">
                        <ImageParallax src={img}/>
                    </div>
                </Col>
            </Row>
        </StyledComponent>
    );
};

const StyledComponent = styled.section`
  background: ${props => props.black ? '#1C1C1C' : '#fff'};

  .text-wrap {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;

    p {
      padding-bottom: 60px;
    }
  }
  
  ul {
    display: flex;
    flex-direction: column;
    gap: 20px;
    li {
      color: rgb(245, 245, 245);
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      position: relative;
      padding-left: 20px;
    }

    li::before {
      content: "•";
      color: rgb(245, 245, 245);
      font-size: 40px;
      position: absolute;
      left: 0px;
    }
  }

  .col-md-6 {
    padding-left: unset;
  }

  .offset-lg-1 {
    padding-left: unset;
  }

  .text {
    padding-top: 80px;
    padding-left: ${props => props.offset ? props.offset + 'px' : ''};

    h3 {
      padding-bottom: 40px;
      color: ${props => props.black ? '#FFFFFF' : ''};
    }

    p {
      padding-bottom: 60px;
      color: ${props => props.black ? '#FFFFFF' : ''};
    }
  }

  .image {
    position: relative;
      overflow: hidden;
    padding-top: calc(700 / 683 * 100%);
  }

  /* Normal desktop :992px. */
  @media (min-width: 992px) and (max-width: 1200px) {
    .text {
      padding-top: unset;
    }
  }

  /* Tablet desktop :768px. */
  @media (min-width: 768px) and (max-width: 991px) {
    .text {
      padding-bottom: 60px;
        padding-left: 30px;
        padding-right: 30px;
    }
      .image{
          padding-top: calc(450 / 680 * 100%);
      }
  }


  /* small mobile :320px. */
  @media (max-width: 767px) {
    .dc-btn {
      margin-bottom: 60px;
    }
   
    .text {
      padding-left: 15px;
      padding-right: 15px;
      //padding-bottom: 60px;
      p {
        margin-bottom: 30px!important;
        padding-bottom: unset;
      }
    }

    .image {
      position: relative;
      padding-top: calc(380 / 375 * 100%);
    }
  }
`;


export default AboutComponent;
